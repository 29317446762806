import { createEntityAdapter } from '@reduxjs/toolkit';
import { schema } from 'normalizr';

import { ContentItemEntity } from 'fwi-fe-types';

export const adapter = createEntityAdapter<ContentItemEntity>({
  selectId: (item) => item.contentId,
});

/**
 * @see {@link NormalizedChannels} for more info around the
 * `channelContentItems` identifier
 */
export const CHANNEL_CONTENT_ITEM_ENTITY = new schema.Entity<ContentItemEntity>(
  'channelContentItems',
  {},
  {
    idAttribute: 'contentId',
  }
);
export const CHANNEL_CONTENT_ITEMS_SCHEMA = new schema.Array(
  CHANNEL_CONTENT_ITEM_ENTITY
);
