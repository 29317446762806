import { createSlice } from '@reduxjs/toolkit';

const { actions, reducer } = createSlice({
  name: 'properties',
  initialState: false,
  reducers: {
    toggleProperties: (state) => !state,
  },
});

export const { toggleProperties } = actions;
export default reducer;
