import { AppState, ChannelContentItemsState } from 'appTypes';

import { adapter } from './schema';

const getChannelContentItemsState = (
  state: AppState
): ChannelContentItemsState => state.channelContentItems;

export const {
  selectById: getChannelContentItemById,
  selectAll: getChannelContentItems,
  selectEntities: getChannelContentItemEntities,
  selectIds: getChannelContentItemIds,
  selectTotal: getTotalChannelContentItems,
} = adapter.getSelectors(getChannelContentItemsState);
