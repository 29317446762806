import {
  FormEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { FWI_AUTH_FROM, getCookie, setCookie } from 'fwi-fe-utils';

import {
  NO_IDENTITY_PROVIDER,
  fetchIdp,
  getIdpId,
  isLoadingIdp,
  signInWithIdentityProvider,
  useAppSelector,
} from 'appState';
import { FWI_EMAIL_ADDRESS } from 'constants/cookies';
import { OKTA_LOGIN, getKnownQueryParams } from 'utils/routes';

import { UnifiedLogin } from './UnifiedLogin';

/**
 * Expires in 30 days from the time of submit.
 */
const getExpires = (): Date => new Date(Date.now() + 30 * 24 * 60 * 60 * 1000);

export interface IdentityProvider {
  idpId: string;
  emailDomain: string;
}

export default function EmailLogin(): ReactElement {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useAppSelector(isLoadingIdp);
  const idpId = useAppSelector(getIdpId);
  const [email, setEmail] = useState(() => getCookie(FWI_EMAIL_ADDRESS) || '');
  const [error, setError] = useState(
    () => getKnownQueryParams(history.location.search).error
  );

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!email || loading) {
        return;
      }

      setError('');
      setCookie(FWI_EMAIL_ADDRESS, email, { expires: getExpires() });
      dispatch(fetchIdp(email));
    },
    [dispatch, email, loading]
  );

  useEffect(() => {
    if (!idpId) {
      return;
    }

    const from = getKnownQueryParams(history.location.search).from;
    if (idpId === NO_IDENTITY_PROVIDER) {
      const query = from ? `?from=${encodeURIComponent(from)}` : '';
      history.replace(`${OKTA_LOGIN}${query}`);
      return;
    }

    setCookie(FWI_AUTH_FROM, from);
    signInWithIdentityProvider(idpId, email);
  }, [email, history, idpId]);

  return (
    <UnifiedLogin
      email={email}
      setEmail={setEmail}
      error={error}
      onSubmit={handleSubmit}
      loading={loading || !!idpId}
    />
  );
}
