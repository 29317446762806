import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { authTokenController } from 'fwi-fe-utils';

export const baseQuery = fetchBaseQuery({
  async prepareHeaders(headers) {
    const accessToken = await authTokenController.getAccessToken();
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }

    return headers;
  },
});

export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery,
  endpoints: () => ({}),

  /**
   * This is required for the `baseApi.injectEndpoints` to work correctly. Check out
   * the "Kitchen Sink" example on RTK Query
   *
   * @see {@link https://codesandbox.io/s/github/reduxjs/redux-toolkit/tree/master/examples/query/react/kitchen-sink}
   * @see {@link https://redux-toolkit.js.org/rtk-query/usage/examples}
   */
  tagTypes: ['Groups', 'Users', 'Labels'],
});
