import cn from 'classnames';
import { HTMLAttributes, ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Button } from 'fwi-fe-components';

import { ErrorCode } from 'appTypes';
import FormattedMessage from 'components/FormattedMessage';
import { BUILD_NODE_ENV } from 'constants/env';
import { useDashboardUrl } from 'hooks/useDashboardUrl';
import { logout } from 'utils/logout';

import ErrorCodeHeader from './ErrorCodeHeader';
import styles from './ErrorPage.module.scss';
import ErrorPageConditionalModal from './ErrorPageConditionalModal';

export interface ErrorPageProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Boolean if the ErrorPage should be wrapped in a full page dialog. This
   * should normally be enabled if a 404 occurs when trying to view a specific
   * entity.
   */
  modal?: boolean;
  code?: ErrorCode;
  error?: Error;
  errorMessage?: string;

  /**
   * @defaultValue `REACT_APP_NODE_ENV`
   */
  displayError?: boolean;
}

export default function ErrorPage({
  id = 'error-page',
  className,
  error,
  errorMessage,
  code,
  modal = false,
  // never display real stack traces in prod environments... but allow this for testing purposes
  displayError = BUILD_NODE_ENV === 'development',
  ...props
}: ErrorPageProps): ReactElement {
  const intl = useIntl();
  const forbidden = code === 403;
  const history = useHistory();
  const href = useDashboardUrl();

  return (
    <ErrorPageConditionalModal id={`${id}-modal`} modal={modal}>
      <div {...props} id={id} className={cn(styles.container, className)}>
        {code && (
          <ErrorCodeHeader code={code} modal={modal} forbidden={forbidden} />
        )}
        <FormattedMessage
          id={`${id}-heading`}
          messageId={`ErrorPage.Heading${code ?? 'Error'}`}
          className={styles.heading}
        />
        <FormattedMessage
          id={`${id}-description`}
          messageId={`ErrorPage.Description${
            code ?? errorMessage ?? error?.message
          }`}
          defaultMessage={intl.formatMessage({ id: 'ErrorPage.UnknownError' })}
          className={styles.description}
        />
        {displayError && error && (
          <details
            id={`${id}-error-details`}
            style={{ margin: '24px 0', width: '50vw', padding: '1rem' }}
            className="md-paper md-paper--2"
          >
            <summary>Error Details</summary>
            <pre
              style={{
                fontWeight: 'normal',
                marginTop: 16,
                overflow: 'auto',
                textTransform: 'none',
              }}
            >
              <code>{error.stack ?? error.message}</code>
            </pre>
          </details>
        )}
        <Button
          id="back-to-dashboard"
          onClick={() => {
            if (error) {
              window.location.href = href;
            } else {
              history.push(href);
            }
          }}
          className={styles.button}
        >
          <FormattedMessage messageId="ErrorPage.BackToDashboard" />
        </Button>
        {forbidden && (
          <Button
            id="error-code-page-logout"
            onClick={logout}
            className={styles.button}
          >
            <FormattedMessage messageId="LogOut" />
          </Button>
        )}
      </div>
    </ErrorPageConditionalModal>
  );
}
