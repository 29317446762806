import { ReactElement } from 'react';

import { MenuItem, UploadIcon } from 'fwi-fe-components';

import { showBulkUploadModal, useAppDispatch } from 'appState';
import FormattedMessage from 'components/FormattedMessage';

export default function BulkUpload(): ReactElement {
  const dispatch = useAppDispatch();

  return (
    <MenuItem
      id="admin-options-bulk-upload"
      icon={<UploadIcon theme="inherit" />}
      onClick={() => {
        dispatch(showBulkUploadModal());
      }}
    >
      <FormattedMessage messageId="BulkUploadByCSV" />
    </MenuItem>
  );
}
