import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SortOrder } from 'fwi-fe-types';

import { LabelSortKey, LabelsState, NormalizedLabels } from 'appTypes';

import { deleteLabels, fetchLabel, fetchLabels, upsertLabel } from './api';
import { adapter } from './schema';

const PAGE_SIZE = 50;

export const INITIAL_LABELS_STATE: LabelsState = adapter.getInitialState({
  loading: false,
  loadingIds: [],
  markedForDeletion: [],
  sort: 'name',
  sortOrder: SortOrder.ASCENDING,
  offset: 0,
  search: '',
  size: PAGE_SIZE,
  isSorted: true,
});

const { actions, reducer } = createSlice({
  name: 'labels',
  initialState: INITIAL_LABELS_STATE,
  reducers: {
    updateLabelSort: (
      state,
      action: PayloadAction<{ sort: LabelSortKey; sortOrder: SortOrder }>
    ) => {
      state.sort = action.payload.sort;
      state.sortOrder = action.payload.sortOrder;
      state.offset = 0;
      state.isSorted = true;
    },
    setNextLabelPage: (state) => {
      state.offset += PAGE_SIZE;
    },
    mergeLabels: (state, action: PayloadAction<NormalizedLabels>) => {
      adapter.addMany(state, action.payload.labels);
    },
    resetLabelsPaginationState: (state) => {
      state.sort = 'name';
      state.sortOrder = SortOrder.ASCENDING;
      state.offset = 0;
      state.search = '';
      state.size = PAGE_SIZE;
      state.isSorted = true;
    },
    updateLabelSearch: (state, action: PayloadAction<string>) => {
      state.offset = 0;
      state.search = action.payload;
      state.isSorted = true;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchLabels.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLabels.fulfilled, (state, action) => {
        state.loading = false;
        adapter.addMany(state, action.payload.labels);
      })
      .addCase(fetchLabels.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchLabel.pending, (state, action) => {
        state.loadingIds.push(action.meta.arg);
      })
      .addCase(fetchLabel.fulfilled, (state, action) => {
        const labelId = action.meta.arg;

        if (!state.markedForDeletion.includes(labelId)) {
          adapter.upsertOne(state, action.payload.labels[labelId]);
        }

        state.markedForDeletion = state.markedForDeletion.filter(
          (id) => id !== labelId
        );
        state.loadingIds = state.loadingIds.filter((id) => id !== labelId);
      })
      .addCase(fetchLabel.rejected, (state, action) => {
        const labelId = action.meta.arg;
        state.loadingIds = state.loadingIds.filter((id) => id !== labelId);
      })
      .addCase(upsertLabel.fulfilled, (state, action) => {
        // if there is no `id`, this is a CREATE action and we don't need to do anything else
        if (!action.meta.arg.id) {
          adapter.upsertMany(state, action.payload.labels);
          state.isSorted = false;
          return;
        }

        // the PUT response for updates is a bit weird and will only return
        // `values` that were sent in the request instead of the fully updated
        // label so we have to manually apply the patch ourselves
        const { id, name, description, inputType, valueIdsToDelete } =
          action.meta.arg;
        const returnedLabel = action.payload.labels[id];
        const existingLabel = state.entities[id];
        if (!existingLabel || !returnedLabel) {
          return;
        }

        if (state.sort === 'name' && existingLabel.name !== name) {
          state.isSorted = false;
        }

        existingLabel.name = name;
        existingLabel.description = description;
        existingLabel.inputType = inputType;

        // need to ensure duplicates aren't added
        const values = new Set([
          ...existingLabel.values,
          ...returnedLabel.values,
        ]);
        if (valueIdsToDelete.length) {
          valueIdsToDelete.forEach((valueId) => {
            values.delete(valueId);
          });
        }

        if (
          state.sort === 'valueCount' &&
          existingLabel.values.length !== values.size
        ) {
          state.isSorted = false;
        }

        existingLabel.values = [...values];
      })
      .addCase(deleteLabels.pending, (state, action) => {
        state.markedForDeletion = [...action.meta.arg];
      })
      .addCase(deleteLabels.fulfilled, (state, action) => {
        adapter.removeMany(state, action.payload.removedLabelIds);
      })
      .addCase(deleteLabels.rejected, (state, action) => {
        const labels = action.meta.arg;
        let markedForDeletion = [...state.markedForDeletion];

        for (const label of labels) {
          markedForDeletion = markedForDeletion.filter((id) => id !== label);
        }

        state.markedForDeletion = markedForDeletion;
      }),
});

export const {
  mergeLabels,
  updateLabelSort,
  resetLabelsPaginationState,
  setNextLabelPage,
  updateLabelSearch,
} = actions;

export default reducer;
