import { EntityId } from 'fwi-fe-types';

import { AppState, DriveState } from 'appTypes';

export const getDriveState = (state: AppState): DriveState => state.drive;

/**
 *
 * @param state - The top-level store state
 * @returns the drive id for the current company.
 */
export const getDriveId = (state: AppState): EntityId =>
  getDriveState(state).driveId;

/**
 *
 * @param state - The top-level store state
 * @returns true if the driveId is loading
 */
export const isLoadingDriveId = (state: AppState): boolean =>
  getDriveState(state).loading;
