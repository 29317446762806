import { createAsyncThunk } from '@reduxjs/toolkit';
import { stringify } from 'qs';

import { MODULES } from 'fwi-constants';
import { EntityId, SortOrder } from 'fwi-fe-types';
import { StatusCodes } from 'fwi-fe-utils';

import {
  APIMessageRejectionThunkConfig,
  APIRejectionThunkConfig,
  APIStatusRejectionThunkConfig,
  CreateCustomerData,
  CustomerEntitlements,
  CustomerEntity,
  CustomerFlag,
  CustomerStatus,
  CustomerUsage,
  EntityAction,
  LegacySearchResults,
  MessageJsonResponse,
  PaginatedJSONResponse,
} from 'appTypes';
import {
  CUSTOMERS_ACTIVATE_ENDPOINT,
  CUSTOMERS_COUNT_ENDPOINT,
  CUSTOMERS_DEACTIVATE_ENDPOINT,
  CUSTOMERS_ENDPOINT,
  CUSTOMERS_ID_ENDPOINT,
  CUSTOMERS_SUSPEND_ENDPOINT,
  CUSTOMERS_USAGE_ENDPOINT,
  SEARCH_ENDPOINT,
} from 'constants/endpoints';
import { api } from 'utils/api';

import { isLoadingCustomer, isLoadingCustomersCount } from './selectors';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function formatCustomerJson(json: any): CustomerEntity {
  const {
    _links,
    description,
    entitlements = [],
    customLoginPrefix,
    ...remaining
  } = json;
  return {
    ...remaining,
    // description and customLoginPrefix can be `null` for internal companies for some reason
    description: description || '',
    customLoginPrefix: customLoginPrefix || '',
    // convert response from array to customer entitlements object structure
    entitlements: entitlements.reduce(
      (currentEntitlements: CustomerEntitlements, entityId: EntityId) => ({
        ...currentEntitlements,
        [entityId]: CustomerFlag.Enabled,
      }),
      {}
    ),
  };
}

interface FetchCustomersOptions {
  status: CustomerStatus;
  offset?: number;
  sortBy: string;
  sortOrder: SortOrder;
}

interface CustomersResponseJSON {
  itemListElement: (CustomerEntity & { _links: Record<string, unknown> })[];
  itemListFilters: Record<string, string>;
  itemListSorting: Record<string, string>;
  numberOfItems: number;
  totalNumberOfItems: number;
}

/**
 * Fetches customers based on specific filters and options.
 *
 * @param options - The {@link FetchCustomersOptions} options
 * @returns A {@link PaginatedJSONResponse} containing {@link CustomerEntity}
 */
export const fetchCustomers = createAsyncThunk<
  PaginatedJSONResponse<CustomerEntity>,
  FetchCustomersOptions,
  APIStatusRejectionThunkConfig
>('customers/fetch', async ({ status, offset = 0, sortBy, sortOrder }) => {
  let trial: CustomerFlag | null = null;
  let internal: CustomerFlag | null = null;
  let queryStatus = status;
  if (status === CustomerStatus.ACTIVE) {
    trial = CustomerFlag.Disabled;
    internal = CustomerFlag.Disabled;
  } else if (status === CustomerStatus.TRIAL) {
    trial = CustomerFlag.Enabled;
    queryStatus = CustomerStatus.ACTIVE;
  } else if (status === CustomerStatus.INTERNAL) {
    internal = CustomerFlag.Enabled;
    queryStatus = CustomerStatus.ACTIVE;
  }

  const query = stringify(
    {
      status: queryStatus,
      trial,
      internal,
      sortBy,
      sortOrder,
      rowsOffset: offset,
    },
    {
      skipNulls: true,
      addQueryPrefix: true,
    }
  );

  const response = await api(`${CUSTOMERS_ENDPOINT}${query}`);
  const json: CustomersResponseJSON = await response.json();

  return {
    items: json.itemListElement.map(formatCustomerJson),
    size: json.numberOfItems,
    total: json.totalNumberOfItems,
  };
});

/**
 * Fetches a specific customer by id
 *
 * @param customerId - The customer id to fetch
 * @returns The {@link CustomerEntity}
 */
export const fetchCustomer = createAsyncThunk<
  CustomerEntity,
  EntityId,
  APIRejectionThunkConfig
>(
  'customers/fetchById',
  async (customerId, { rejectWithValue }) => {
    const response = await api(CUSTOMERS_ID_ENDPOINT, {
      params: { customerId },
    });

    if (!response.ok) {
      const { status } = response;
      return rejectWithValue({
        status,
        unmodified: status === StatusCodes.NOT_MODIFIED,
      });
    }

    const json = await response.json();
    return formatCustomerJson(json);
  },
  {
    condition: (customerId, { getState }) => {
      return (
        customerId !== EntityAction.New &&
        !isLoadingCustomer(getState(), customerId)
      );
    },
  }
);

interface CustomerCountResponseJSON {
  customersCount: {
    total: number;
    byStatus: {
      active?: number;
      failed?: number;
      pending?: number;
      suspended?: number;
    };
  };
}

export interface CustomerCountResponse {
  total: number;
  active: number;
  failed: number;
  pending: number;
  suspended: number;
}

/**
 * Fetches the specific customer counts by status.
 *
 * @returns {@link CustomerCountResponse}
 */
export const fetchCustomerCount = createAsyncThunk<
  CustomerCountResponse,
  void,
  APIRejectionThunkConfig
>(
  'customers/count',
  async (_, { rejectWithValue }) => {
    const response = await api(CUSTOMERS_COUNT_ENDPOINT);

    if (!response.ok) {
      const { status } = response;
      return rejectWithValue({
        status,
        unmodified: status === StatusCodes.NOT_MODIFIED,
      });
    }

    const json: CustomerCountResponseJSON = await response.json();
    const {
      total,
      byStatus: { failed = 0, active = 0, pending = 0, suspended = 0 },
    } = json.customersCount;

    return {
      total,
      active,
      failed,
      pending,
      suspended,
    };
  },
  {
    condition: (_arg, { getState }) => {
      return !isLoadingCustomersCount(getState());
    },
  }
);

export const fetchCustomerUsage = createAsyncThunk<
  CustomerUsage,
  EntityId,
  APIRejectionThunkConfig
>('customers/usage', async (customerId, { rejectWithValue }) => {
  const response = await api(CUSTOMERS_USAGE_ENDPOINT, {
    params: { customerId },
  });

  if (!response.ok) {
    const { status } = response;
    return rejectWithValue({
      status,
      unmodified: status === StatusCodes.NOT_MODIFIED,
    });
  }

  const json: { customerUsage: CustomerUsage } = await response.json();
  return json.customerUsage;
});

export const createCustomer = createAsyncThunk<
  CustomerEntity,
  CreateCustomerData,
  APIMessageRejectionThunkConfig
>('customers/create', async (data, { rejectWithValue }) => {
  const response = await api(CUSTOMERS_ENDPOINT, {
    method: 'POST',
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    const json: MessageJsonResponse = await response.json();
    const { status, statusText } = response;
    return rejectWithValue({ status, statusText, message: json.message });
  }

  const json = await response.json();
  return formatCustomerJson(json);
});

export const updateCustomer = createAsyncThunk<
  CustomerEntity,
  Partial<CustomerEntity> & { customerId: string },
  APIMessageRejectionThunkConfig
>('customers/update', async ({ customerId, ...patch }, { rejectWithValue }) => {
  const response = await api(CUSTOMERS_ID_ENDPOINT, {
    method: 'PUT',
    body: JSON.stringify(patch),
    params: { customerId },
  });

  if (!response.ok) {
    const json: MessageJsonResponse = await response.json();
    const { status, statusText } = response;
    return rejectWithValue({ status, statusText, message: json.message });
  }

  const json = await response.json();
  return formatCustomerJson(json);
});

export const suspendCustomer = createAsyncThunk<
  CustomerEntity,
  EntityId,
  APIStatusRejectionThunkConfig
>('customers/suspend', async (customerId, { rejectWithValue }) => {
  const response = await api(CUSTOMERS_SUSPEND_ENDPOINT, {
    method: 'POST',
    params: { customerId },
  });

  if (!response.ok) {
    const { status, statusText } = response;
    return rejectWithValue({ status, statusText });
  }

  const json = await response.json();
  return formatCustomerJson(json);
});

export const activateCustomer = createAsyncThunk<
  CustomerEntity,
  EntityId,
  APIStatusRejectionThunkConfig
>('customers/activate', async (customerId, { rejectWithValue }) => {
  const response = await api(CUSTOMERS_ACTIVATE_ENDPOINT, {
    method: 'POST',
    params: { customerId },
  });

  if (!response.ok) {
    const { status, statusText } = response;
    return rejectWithValue({ status, statusText });
  }

  const json = await response.json();
  return formatCustomerJson(json);
});

export const deactivateCustomer = createAsyncThunk<
  CustomerEntity,
  EntityId,
  APIStatusRejectionThunkConfig
>('customers/deactivate', async (customerId, { rejectWithValue }) => {
  const response = await api(CUSTOMERS_DEACTIVATE_ENDPOINT, {
    method: 'POST',
    params: { customerId },
  });

  if (!response.ok) {
    const { status, statusText } = response;
    return rejectWithValue({ status, statusText });
  }

  const json = await response.json();
  return formatCustomerJson(json);
});

export const deleteCustomer = createAsyncThunk<
  void,
  EntityId,
  APIStatusRejectionThunkConfig
>('customers/delete', async (customerId, { rejectWithValue }) => {
  const response = await api(CUSTOMERS_ID_ENDPOINT, {
    method: 'DELETE',
    params: { customerId },
  });

  if (!response.ok) {
    const { status, statusText } = response;
    return rejectWithValue({ status, statusText });
  }
});

export const searchCustomers = createAsyncThunk<
  LegacySearchResults,
  string,
  APIStatusRejectionThunkConfig
>('customers/search', async (keywords, { rejectWithValue }) => {
  const params = stringify({
    groupBy: 'contentType',
    appId: MODULES.CUSTOMER_MANAGEMENT.sysname,
    keywords,
  });

  const response = await api(`${SEARCH_ENDPOINT}?${params}`);

  if (!response.ok) {
    const { status, statusText } = response;
    return rejectWithValue({ status, statusText });
  }

  return await response.json();
});
