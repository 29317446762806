import { createEntityAdapter } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';

import { CHANNEL_CONTENT_ITEMS_SCHEMA } from 'appState/channelContentItems/schema';
import { CHANNEL_INTERRUPTS_SCHEMA } from 'appState/channelInterrupts/schema';
import { CHANNEL_SCHEDULES_SCHEMA } from 'appState/channelSchedules/schema';
import { NormalizedChannelEntity, NormalizedChannels } from 'appTypes';

export const adapter = createEntityAdapter<NormalizedChannelEntity>({
  selectId: (channel) => channel.channelId,
});

/**
 * @see {@link NormalizedChannels} for more info around the `labels` identifier
 */
export const CHANNEL_ENTITY = new schema.Entity<NormalizedChannelEntity>(
  'channels',
  {
    contentList: CHANNEL_CONTENT_ITEMS_SCHEMA,
    schedule: CHANNEL_SCHEDULES_SCHEMA,
    interrupts: CHANNEL_INTERRUPTS_SCHEMA,
  },
  {
    idAttribute: 'channelId',
    processStrategy(json) {
      const {
        // these are not returned from the fetchChannels api
        versions = [],
        schedule = [],
        interrupts = [],
        ...channel
      } = json;
      return {
        versions,
        schedule,
        interrupts,
        ...channel,
      };
    },
  }
);
export const CHANNELS_SCHEMA = new schema.Array(CHANNEL_ENTITY);

/**
 * Transforms and normalizes channels from a list of json objects.
 *
 * @param json - A list of json objects (normally from the BE) that should be
 * normalized
 * @returns the {@link NormalizedChannels} record
 */
export function normalizeChannels(json: unknown[]): NormalizedChannels {
  const {
    channels = {},
    channelSchedules = {},
    channelInterrupts = {},
    channelContentItems = {},
  } = normalize<unknown, Partial<NormalizedChannels>>(
    json,
    CHANNELS_SCHEMA
  ).entities;

  return {
    channels,
    channelSchedules,
    channelInterrupts,
    channelContentItems,
  };
}

/**
 * Transforms and normalizes a channel from a json object.
 *
 * @param json - A json object (normally from the BE) that should be normalized
 * @returns the {@link NormalizedChannels} record
 */
export function normalizeChannel(json: unknown): NormalizedChannels {
  return normalizeChannels([json]);
}
