/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  CombinedState,
  PreloadedState,
  Reducer,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import {
  RouterState,
  connectRouter,
  routerMiddleware,
} from 'connected-react-router';
import { History, LocationState, createBrowserHistory } from 'history';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import { addQASagaMonitor } from 'fwi-fe-utils';

import { AppState } from 'appTypes';

import alerts from './alerts';
import { analyticsMiddleware } from './analytics/middleware';
import appSize, { getInitialAppSizeState } from './appSize';
import auth, { getInitialAuthState } from './auth';
import { baseApi } from './baseApi';
import bulkUserOps from './bulkUserOps';
import channelContentItems from './channelContentItems';
import channelInterrupts from './channelInterrupts';
import channelSchedules from './channelSchedules';
import channelShareQuota from './channelShareQuota';
import channels from './channels';
import companySettings from './companySettings';
import customers from './customers';
import drive from './drive';
import errorCode from './errorCode';
import eula from './eula';
import folders from './folders';
import groups from './groups';
import infoModal from './infoModal';
import labelValues from './labelValues';
import labels from './labels';
import licenseQuotas from './licenseQuotas';
import marketSolutions from './marketSolutions';
import pagination from './pagination';
import properties from './properties';
import { allSagas } from './sagas';
import searchFilters from './searchFilters';
import selections from './selections';
import toasts from './toasts';
import users from './users';

/**
 * The is the main history object used throughout the app while in the browser.
 */
export const history = createBrowserHistory();

/**
 * This is just used to help get the `AppState` structure for the `createStore`.
 */
export const createRootReducer = (history: History) => {
  const router = connectRouter(history);

  return combineReducers<AppState>({
    [baseApi.reducerPath]: baseApi.reducer,
    alerts,
    appSize,
    auth,
    bulkUserOps,
    channelContentItems,
    channelInterrupts,
    channelSchedules,
    channels,
    channelShareQuota,
    companySettings,
    customers,
    drive,
    errorCode,
    eula,
    labels,
    labelValues,
    folders,
    groups,
    infoModal,
    licenseQuotas,
    pagination,
    properties,
    searchFilters,
    marketSolutions,
    selections,
    toasts,
    users,
    // have to typecast since connected-react-router type definitions are wrong.
    router: router as unknown as Reducer<RouterState<LocationState>>,
  });
};

/**
 * Creates the store for the app. This shouldn't really be used outside of this
 * file in most cases. This is exported only to work with `fwi-fe-test-utils`'s
 * `renderWithStore` renderer.
 */
export const createStore = (
  history: History,
  preloadedState?: PreloadedState<CombinedState<AppState>>
) => {
  const sagaMiddleware = createSagaMiddleware(addQASagaMonitor());
  const reducer = createRootReducer(history);

  const store = configureStore({
    preloadedState: {
      appSize: getInitialAppSizeState(),
      auth: getInitialAuthState(history.location.pathname),
      ...preloadedState,
    },
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
        thunk: {
          extraArgument: {},
        },
      }).concat([
        routerMiddleware(history),
        sagaMiddleware,
        analyticsMiddleware,
        baseApi.middleware,
      ]),
  });
  sagaMiddleware.run(allSagas);

  return store;
};

/**
 * This is the store that is used for the app.
 */
export const store = createStore(history);

export type AppDispatch = typeof store.dispatch;
export type GetAppState = typeof store.getState;

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
export const useAppDispatch = (): AppDispatch => useDispatch();
