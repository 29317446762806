export const TITLE_CHAR_LIMIT = 100;

export const USER_FIRST_NAME_LIMIT = 50;
export const USER_LAST_NAME_LIMIT = 50;
export const USER_EMAIL_LIMIT = 100;
export const USER_DESC_LIMIT = 100;

export const GROUP_NAME_LIMIT = 50;
export const GROUP_DESC_LIMIT = 100;

export const LABEL_NAME_LIMIT = 50;
export const LABEL_VALUE_LIMIT = 50;
export const LABEL_DESC_LIMIT = 50;

/** @see {@link https://fourwindsinteractive.atlassian.net/browse/CLD-6425?focusedCommentId=123700} */
export const ELASTIC_SEARCH_MAX_WINDOW_SIZE = 10000;
