import { ReactElement } from 'react';

import { LayoutSortViewToggle } from 'fwi-fe-components';
import { SortOrder } from 'fwi-fe-types';

import { isMobile, useAppSelector } from 'appState';
import { getCurrentColumns, getCurrentSort } from 'selectors/pagination';

export interface SortViewToggleProps {
  onSortChange(sort: string, sortOrder: SortOrder): void;
}

export default function SortViewToggle({
  onSortChange,
}: SortViewToggleProps): ReactElement | null {
  const mobile = useAppSelector(isMobile);
  const { sort, sortOrder } = useAppSelector(getCurrentSort);
  const columns = useAppSelector(getCurrentColumns);

  if (!columns.length) {
    return null;
  }

  return (
    <LayoutSortViewToggle
      id="main-content-sort-view-toggle"
      mobile={mobile}
      columns={columns}
      sort={sort}
      sortOrder={sortOrder}
      onSortChange={onSortChange}
    />
  );
}
