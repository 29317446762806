import { AnyAction } from '@reduxjs/toolkit';
import { getLocation, getSearch, push, replace } from 'connected-react-router';
import { get } from 'lodash';
import { parse } from 'qs';
import { fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  addToast,
  deleteGroup,
  fetchHomeGroups,
  postGroup,
  putGroup,
} from 'appState';
import { PaginationState } from 'appTypes';
import { ADMIN_GROUPS, qsToString } from 'utils/routes';

const PAYLOAD_STATUS = 'payload.status';

export function* redirectOnPost() {
  yield takeLatest(
    [postGroup.fulfilled.type, putGroup.fulfilled.type],
    function* execute(action: AnyAction) {
      if (action.meta.arg.disableRedirect) {
        return;
      }

      const search: string = yield select(getSearch);
      const from = qsToString(parse(search).from);
      yield put(push(from || ADMIN_GROUPS));
    }
  );
}

export function* redirectOnDuplicate() {
  yield takeLatest(
    postGroup.fulfilled.type,
    function* execute(action: AnyAction) {
      const { id } = action.payload;
      const { disableRedirect, duplicateId } = action.meta.arg;
      if (disableRedirect || !duplicateId) {
        return;
      }

      const search: string = yield select(getSearch);
      const from = qsToString(parse(search).from);
      yield put(push(`${ADMIN_GROUPS}/${id}${from ? '?from' + from : ''}`));
    }
  );
}

export function* redirectOnDelete() {
  yield takeLatest(
    [deleteGroup.fulfilled.type],
    function* execute(action: AnyAction) {
      const { disableRedirect, groupId } = action.meta.arg;
      const pathname: string = yield select(
        (state) => getLocation(state).pathname
      );
      if (!disableRedirect && pathname === `${ADMIN_GROUPS}/${groupId}`) {
        yield put(replace(ADMIN_GROUPS));
      }
    }
  );
}

export function* fetchNextGroupOnGroupDeletion() {
  yield takeEvery([deleteGroup.fulfilled.type], function* execute() {
    const state: PaginationState['homeGroups'] = yield select(
      (state) => state.pagination.homeGroups
    );
    const { sort, sortOrder } = state.sort;
    const offset = state.itemList.length;
    if (state.numberOfItems === offset) {
      return;
    }
    yield put(fetchHomeGroups({ offset, sort, sortOrder, size: 1 }));
  });
}

export function* watchGroupPostSuccesses() {
  yield takeLatest(
    postGroup.fulfilled.type,
    function* toastGroupPostSuccess(
      action: ReturnType<typeof postGroup.fulfilled>
    ) {
      const messageId = action.meta.arg.duplicateId
        ? 'DuplicateGroupSuccess'
        : 'PostGroupSuccess';

      yield put(addToast({ messageId }));
    }
  );
}

export function* watchGroupPostFailures() {
  yield takeLatest(
    postGroup.rejected.type,
    function* toastGroupPostFailure(
      action: ReturnType<typeof postGroup.rejected>
    ) {
      let messageId = 'PostGroupFailure';
      if (action.meta.arg.duplicateId) {
        messageId =
          action.payload?.status === 404
            ? 'GroupFailure404'
            : 'DuplicateGroupFailure';
      }

      yield put(addToast({ messageId }));
    }
  );
}

export function* watchGroupPutSuccesses() {
  yield takeLatest(putGroup.fulfilled.type, function* toastGroupPutSuccess() {
    yield put(addToast({ messageId: 'PutGroupSuccess' }));
  });
}

export function* watchGroupPutFailures() {
  yield takeLatest(
    putGroup.rejected.type,
    function* toastGroupPutFailure(action) {
      if (get(action, PAYLOAD_STATUS) === 404) {
        yield put(addToast({ messageId: 'GroupFailure404' }));
      } else {
        yield put(addToast({ messageId: 'PutGroupFailure' }));
      }
    }
  );
}

export function* watchGroupDeleteSuccesses() {
  yield takeLatest(
    deleteGroup.fulfilled.type,
    function* toastGroupDeleteSuccess() {
      yield put(addToast({ messageId: 'DeleteGroupSuccess' }));
    }
  );
}

export function* watchGroupDeleteFailures() {
  yield takeLatest(
    deleteGroup.rejected.type,
    function* toastGroupDeleteFailure(action) {
      if (get(action, PAYLOAD_STATUS) === 404) {
        yield put(addToast({ messageId: 'GroupFailure404' }));
      } else {
        yield put(addToast({ messageId: 'DeleteGroupFailure' }));
      }
    }
  );
}

export function* watchGroupDuplicateSuccesses() {
  yield takeLatest(
    postGroup.fulfilled.type,
    function* toastGroupDuplicateSuccess(action: AnyAction) {
      if (!action.meta.arg.duplicateId) {
        return;
      }
      yield put(addToast({ messageId: 'DuplicateGroupSuccess' }));
    }
  );
}

export function* watchGroupDuplicateFailures() {
  yield takeLatest(
    postGroup.rejected.type,
    function* toastGroupDuplicateFailures(action: AnyAction) {
      if (!action.meta.arg.duplicateId) {
        return;
      }
      if (get(action, PAYLOAD_STATUS) === 404) {
        yield put(addToast({ messageId: 'GroupFailure404' }));
      } else {
        yield put(addToast({ messageId: 'DuplicateGroupFailure' }));
      }
    }
  );
}

export function* groupsSagas() {
  yield fork(redirectOnPost);
  yield fork(redirectOnDuplicate);
  yield fork(redirectOnDelete);
  yield fork(fetchNextGroupOnGroupDeletion);
  yield fork(watchGroupPostSuccesses);
  yield fork(watchGroupPostFailures);
  yield fork(watchGroupPutSuccesses);
  yield fork(watchGroupPutFailures);
  yield fork(watchGroupDeleteSuccesses);
  yield fork(watchGroupDeleteFailures);
}
