import { createReducer } from '@reduxjs/toolkit';

import { ChannelShareQuotaState } from 'appTypes';

import { fetchChannelShareUrlQuota } from './api';

export const INITIAL_CHANNEL_SHARE_QUOTA_STATE: ChannelShareQuotaState = {
  loading: false,
  used: 0,
  quota: 0,
};

export default createReducer(INITIAL_CHANNEL_SHARE_QUOTA_STATE, (builder) =>
  builder
    .addCase(fetchChannelShareUrlQuota.pending, (state) => {
      state.loading = true;
    })

    .addCase(fetchChannelShareUrlQuota.fulfilled, (state, action) => {
      const { used, quota } = action.payload;
      state.loading = false;
      state.used = used;
      state.quota = quota;
    })

    .addCase(fetchChannelShareUrlQuota.rejected, (state) => {
      state.loading = false;
    })
);
