import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { LicenseType } from 'fwi-fe-types';

import { deleteUser, postUser } from 'appState/users/api';
import { LicenseQuotasState, LicenseTypeWithQuota } from 'appTypes';
import { AUTHOR, CONTRIBUTOR, VIEWER } from 'constants/licenseTypes';

import { fetchLicenseQuotas } from './api';

export const INITIAL_LICENSE_QUOTAS_STATE: LicenseQuotasState = {
  loading: false,
  [AUTHOR]: {
    quotaCap: 0,
    quotaUsed: 0,
    quotaRemaining: 0,
  },
  [CONTRIBUTOR]: {
    quotaCap: 0,
    quotaUsed: 0,
    quotaRemaining: 0,
  },
  [VIEWER]: {
    quotaCap: 0,
    quotaUsed: 0,
    quotaRemaining: 0,
  },
};

const getLicenseType = (licenseType: LicenseType): LicenseTypeWithQuota =>
  licenseType === LicenseType.SUPERUSER ? LicenseType.AUTHOR : licenseType;

export interface SwapLicenseQuotaPayload {
  isInternal: boolean;
  prevLicenseType: LicenseType;
  nextLicenseType: LicenseType;
}

const { actions, reducer } = createSlice({
  name: 'licenseQuotas',
  initialState: INITIAL_LICENSE_QUOTAS_STATE,
  reducers: {
    swapLicenseQuota: (
      state,
      action: PayloadAction<SwapLicenseQuotaPayload>
    ) => {
      const { isInternal } = action.payload;
      const prevLicenseType = getLicenseType(action.payload.prevLicenseType);
      const nextLicenseType = getLicenseType(action.payload.nextLicenseType);
      // - internal employees do not count towards the license quota
      // - the license won't change if a user changes between an author -> admin and admin -> author
      if (isInternal || prevLicenseType === nextLicenseType) {
        return;
      }

      const prevQuota = state[prevLicenseType];
      const nextQuota = state[nextLicenseType];
      if (prevQuota.quotaCap !== -1) {
        prevQuota.quotaRemaining += 1;
      }
      prevQuota.quotaUsed -= 1;

      nextQuota.quotaUsed += 1;
      if (nextQuota.quotaCap !== -1) {
        nextQuota.quotaRemaining -= 1;
      }
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchLicenseQuotas.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLicenseQuotas.fulfilled, (state, action) => {
        state.loading = false;

        state[AUTHOR] = action.payload[AUTHOR];
        state[CONTRIBUTOR] = action.payload[CONTRIBUTOR];
        state[VIEWER] = action.payload[VIEWER];
      })
      .addCase(fetchLicenseQuotas.rejected, (state) => {
        state.loading = false;
      })
      .addCase(postUser.fulfilled, (state, action) => {
        const { isInternal, licenseType } = action.payload;
        if (isInternal) {
          return;
        }

        const quota = state[getLicenseType(licenseType)];
        quota.quotaUsed += 1;
        if (quota.quotaCap !== -1) {
          quota.quotaRemaining -= 1;
        }
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        const { isInternal, licenseType } = action.payload;
        if (isInternal) {
          return;
        }

        const quota = state[getLicenseType(licenseType)];
        quota.quotaUsed -= 1;
        if (quota.quotaCap !== -1) {
          quota.quotaRemaining += 1;
        }
      }),
});

export const { swapLicenseQuota } = actions;

export default reducer;
