import cn from 'classnames';
import { ReactElement, ReactNode, lazy } from 'react';
import { useIntl } from 'react-intl';

import { MenuHamburgerIcon, NavigationDrawer } from 'fwi-fe-components';

import AppDrawerContent from 'components/AppDrawerContent';
import { ProgressSuspense } from 'components/LazyLoading';
import SecureRoute from 'components/SecureRoute';

import Header from './Header';
import HeaderActions from './HeaderActions';
import styles from './Layout.module.scss';
import { AppLayout } from './useAppLayout';

const UnifiedLayout = lazy(() => import('components/UnifiedDashboard'));
const { TEMPORARY, CLIPPED } = NavigationDrawer.DrawerTypes;

// prevent the Layout from cloning `inherit: true` in
const TemporaryIcon = (): ReactElement => <MenuHamburgerIcon theme="inherit" />;
const noop = (): void => {};

export interface LayoutProps extends Omit<AppLayout, 'setHtmlClassName'> {
  children: ReactNode;
}

export function Layout({
  authenticated,
  children,
  title,
  mobile,
  drawer,
  errored,
  stacked,
  visible,
  eulaGrid,
  contentGrid,
  maintenance,
  unifiedDashboard,
  onVisibilityChange,
}: LayoutProps): ReactElement {
  const intl = useIntl();
  if (unifiedDashboard && !maintenance) {
    return (
      <ProgressSuspense id="loading-unified-dashboard">
        <SecureRoute>
          <UnifiedLayout />
        </SecureRoute>
      </ProgressSuspense>
    );
  }

  return (
    <NavigationDrawer
      drawerId="main-drawer"
      toolbarId="main-toolbar"
      toolbarZDepth={0}
      toolbarProminent={stacked}
      toolbarChildren={
        <Header
          title={title}
          errored={errored}
          stacked={stacked}
          authenticated={authenticated}
        />
      }
      toolbarClassName={cn(styles.toolbar, {
        [styles.stacked]: !mobile && stacked,
        [styles.inline]: (!authenticated || !mobile || errored) && !stacked,
        [styles.hideMenu]: !drawer,
        [styles.charcoal]: !authenticated || errored || !mobile,
        [styles.white]: !authenticated,
        [styles.hidden]: !authenticated,
      })}
      visible={visible}
      onVisibilityChange={onVisibilityChange}
      onMediaTypeChange={noop}
      drawerType={mobile ? TEMPORARY : CLIPPED}
      drawerClassName={cn(styles.drawer, {
        [styles.hidden]: !drawer,
      })}
      drawerHeaderChildren={mobile && <HeaderActions />}
      drawerChildren={<AppDrawerContent />}
      contentClassName={cn(styles.content, {
        [styles.noMarginLeft]: !drawer,
        [styles.grid]: contentGrid,
        [styles.eulaGrid]: eulaGrid,
        [styles.maintenance]: maintenance,
        [styles.noToolbar]: !authenticated,
      })}
      temporaryIcon={<TemporaryIcon />}
      miniNavLabel={intl.formatMessage({ id: 'Navigation' })}
    >
      {children}
    </NavigationDrawer>
  );
}
