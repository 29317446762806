import { AppState, Toast, ToastState } from 'appTypes';

/**
 * @returns all the toasts that exist in state.
 */
export const getToasts = (state: AppState): Readonly<ToastState> =>
  state.toasts;

/**
 * @returns the active toast or undefined
 */
export const getCurrentToast = (state: AppState): Readonly<Toast> | undefined =>
  getToasts(state)[0];
