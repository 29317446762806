import { Permission } from 'fwi-fe-types';

export const ALLOW = 'allow';
export const DENY = 'deny';
export const LIMITED = 'limited';

export const ALL_ITEMS = '*';

export const ALLOW_PERMISSIONS: Permission = {
  effect: ALLOW,
  actions: [ALL_ITEMS],
  resources: [ALL_ITEMS],
};

export const DENY_PERMISSIONS: Permission = {
  effect: DENY,
  actions: [ALL_ITEMS],
  resources: [ALL_ITEMS],
};

export const SELECTED_PERMISSIONS: Permission = {
  effect: ALLOW,
  actions: [ALL_ITEMS],
  resources: [],
};
