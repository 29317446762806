import { AnchorHTMLAttributes, ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { FE_ORIGIN } from 'constants/env';
import { isExternalRoute, isInternalRoute, isReporting } from 'utils/routes';

export type CustomLinkProps = AnchorHTMLAttributes<HTMLAnchorElement>;

export default function CustomLink({
  href,
  children,
  ...props
}: CustomLinkProps): ReactElement | null {
  if (!href) {
    return null;
  }
  const openInNewTab = props.target === '_blank';
  const rel = (props.rel ?? openInNewTab) ? 'noopener noreferrer' : undefined;

  if (isInternalRoute(href) && !isReporting(href)) {
    return (
      <Link {...props} to={href}>
        {children}
      </Link>
    );
  }

  const updatedHref =
    isExternalRoute(href) || isReporting(href) ? `${FE_ORIGIN}${href}` : href;
  return (
    <a {...props} href={updatedHref} rel={rel}>
      {children}
    </a>
  );
}
