import type { FormEventHandler, ReactElement, ReactNode } from 'react';

import { CircularProgress, FormattedMessage } from 'fwi-fe-components';

import logo from 'logos/poppulo-colored.svg';

import styles from './UnifiedForm.module.scss';

export interface UnifiedFormProps {
  name: string;
  heading: ReactNode;
  loading: boolean;
  children: ReactNode;
  onSubmit: FormEventHandler<HTMLFormElement>;
}

export function UnifiedForm({
  name,
  heading,
  loading,
  onSubmit,
  children,
}: UnifiedFormProps): ReactElement {
  return (
    <form name={name} onSubmit={onSubmit} className={styles.form}>
      <img src={logo} alt="" className={styles.logo} />
      <h2 className={styles.heading}>{heading}</h2>
      {children}
      <button type="submit" className={styles.button} disabled={loading}>
        {loading && (
          <span className={styles.loading}>
            <CircularProgress id="loading-idp" className={styles.progress} />
          </span>
        )}
        <FormattedMessage messageId="Login.Next" />
      </button>
    </form>
  );
}
