import { analyticsTrackEvent, toDateString } from 'fwi-fe-utils';

const category = 'Settings';

/**
 * The analytics event to trigger when the Add Domain modal is
 * displayed.
 */
export const approveSiteModalDisplayed = analyticsTrackEvent.bind(null, {
  eventName: 'Approve Site Modal Displayed',
  properties: { category, label: 'From Admin Center' },
});

/**
 * The analytics event to trigger with the cancel button is clicked from the
 * Add Domain modal.
 */
export const approveSiteModalCancelled = analyticsTrackEvent.bind(null, {
  eventName: 'Approve Site Modal Cancelled',
  properties: { category },
});

/**
 * The analytics event to trigger when the "Add" button is clicked in the Add Domain modal.
 */
export const approvedSiteDomainAddedFromAdminCenter = analyticsTrackEvent.bind(
  null,
  {
    eventName: 'Approved Site Domain Added',
    properties: { category, label: 'From Admin Center' },
  }
);

/**
 * The analytics event to trigger when the 'Save' button has been clicked in
 * the Connected Domains setting.
 */
export const approvedSiteConfigurationSaved = analyticsTrackEvent.bind(null, {
  eventName: 'Approved Site Configuration Saved',
  properties: { category },
});

/**
 * The analytics event to trigger when the 'Cancel' button has been clicked in
 * the Connected Domains setting.
 */
export const approvedSiteConfigurationCancelled = analyticsTrackEvent.bind(
  null,
  {
    eventName: 'Approved Site Configuration Cancelled',
    properties: { category },
  }
);

/**
 * The analytics event to trigger when the Reporting Module is
 * enabled.
 */
export const playbackReportingLoggingEnabled = analyticsTrackEvent.bind(null, {
  eventName: 'User Modified Reports Settings',
  properties: {
    category,
    label: `User Enabled Playback Logging on ${toDateString(new Date())}`,
  },
});

/**
 * The analytics event to trigger when the Reporting Module is
 * disabled.
 */
export const playbackReportingLoggingDisabled = analyticsTrackEvent.bind(null, {
  eventName: 'User Modified Reports Settings',
  properties: {
    category,
    label: `User Disabled Playback Logging on ${toDateString(new Date())}`,
  },
});
