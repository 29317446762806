import { ReactElement } from 'react';

import { SYS_NAME_KEYS } from 'fwi-constants';
import {
  AdminCenterIcon,
  CampaignManagerIcon,
  ChannelsIcon,
  CommunityIcon,
  ContentManagerWebIcon,
  CustomerManagementIcon,
  DeviceManagementIcon,
  LibraryIcon,
  ReportingSettingsIcon,
} from 'fwi-fe-components';

export const USER_LINK_ICONS: Record<string, ReactElement> = {
  [SYS_NAME_KEYS.AdminCenter]: <AdminCenterIcon />,
  [SYS_NAME_KEYS.CampaignManagement]: <CampaignManagerIcon />,
  [SYS_NAME_KEYS.Library]: <LibraryIcon />,
  [SYS_NAME_KEYS.CustomerManagement]: <CustomerManagementIcon />,
  [SYS_NAME_KEYS.DeviceManagement]: <DeviceManagementIcon />,
  [SYS_NAME_KEYS.Channels]: <ChannelsIcon />,
  [SYS_NAME_KEYS.Reporting]: <ReportingSettingsIcon />,
  [SYS_NAME_KEYS.ContentManagerWeb]: <ContentManagerWebIcon />,
  [SYS_NAME_KEYS.Community]: <CommunityIcon />,
};
