import cn from 'classnames';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { dashboardSelectedFromLogo, useAppDispatch } from 'appState';
import CustomLink from 'components/CustomLink';
import { useDashboardUrl } from 'hooks/useDashboardUrl';
import poppuloLogo from 'logos/poppulo-colored.svg';
import poppuloLogoWhite from 'logos/poppulo-white.svg';
import { logout } from 'utils/logout';
import { isEula } from 'utils/routes';

import styles from './FWICloud.module.scss';

export interface FWICloudProps {
  theme?: 'light' | 'dark';
  unifiedLogoColor?: 'black' | 'white';
}

export default function FWICloud({
  theme = 'dark',
  unifiedLogoColor = 'black',
}: FWICloudProps): ReactElement {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const href = useDashboardUrl();

  const src = unifiedLogoColor === 'black' ? poppuloLogo : poppuloLogoWhite;

  return (
    <CustomLink
      aria-label={intl.formatMessage({ id: 'Home' })}
      className={cn(styles.link, {
        [styles.linkDarken]: theme === 'light',
      })}
      href={href}
      onClick={(event) => {
        if (isEula(pathname)) {
          event.preventDefault();
          logout('SignInFailed');
          return;
        }

        dispatch(dashboardSelectedFromLogo());
      }}
    >
      <img alt="" src={src} className={styles.logo} />
    </CustomLink>
  );
}
