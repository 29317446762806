import { createSlice } from '@reduxjs/toolkit';

import { SearchFiltersState } from 'appTypes';

export const INITIAL_SEARCH_FILTERS_STATE: SearchFiltersState = {
  visible: false,
};

const { actions, reducer } = createSlice({
  name: 'searchFilters',
  initialState: INITIAL_SEARCH_FILTERS_STATE,
  reducers: {
    toggleSearchFilters(state) {
      state.visible = !state.visible;
    },
  },
});

export const { toggleSearchFilters } = actions;

export default reducer;
