import { ComponentType, ElementType, ReactElement } from 'react';
import { FormattedMessage as IntlFormattedMessage } from 'react-intl';

import { MessageValues } from 'appTypes';

export interface FormattedMessageProps {
  messageId: string;
  messageValues?: MessageValues;
  defaultMessage?: string;
  component?: ComponentType | ElementType;
  className?: string;

  [key: string]: unknown;
}

/**
 * This is a wrapper for the `FormattedMessage` from `react-intl` that allows
 * you to provide styles or a custom component to render the translated message
 * in.
 *
 * This is basically the `FormattedMessage` from `fwi-fe-components` but also
 * supports the `defaultMessage` fallback.
 */
export default function FormattedMessage({
  messageId,
  messageValues,
  defaultMessage,
  component: Component = 'span',
  ...props
}: FormattedMessageProps): ReactElement {
  return (
    <IntlFormattedMessage
      id={messageId}
      values={messageValues}
      defaultMessage={defaultMessage}
    >
      {(...translations) => <Component {...props}>{translations}</Component>}
    </IntlFormattedMessage>
  );
}
