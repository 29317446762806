import { SYS_NAME_KEYS } from 'fwi-constants';
import { COMMUNITY_MAIN_PAGE } from 'fwi-fe-components';
import { CurrentUserEntity, ModulePermission, UserCompany } from 'fwi-fe-types';
import { getCompanyId } from 'fwi-fe-utils';

import {
  AppModuleId,
  AppState,
  AuthState,
  AuthenticationStatus,
  ReadonlyEntityList,
  UserModuleOrLink,
} from 'appTypes';
import { ALERTS_SCOPES } from 'constants/auth';
import { ALLOW } from 'constants/permissions';
import {
  DigitalSignageGroupCode,
  DigitalSignageModuleCode,
} from 'hooks/useDashboardGroupLinks';
import {
  DashboardGroup,
  DashboardMenuItem,
} from 'hooks/useDashboardOverviewQuery';
import {
  ADMIN_GROUPS,
  ADMIN_LABELS,
  ADMIN_SETTINGS,
  ADMIN_USERS,
  CAMPAIGNS,
  CHANNELS,
  CUSTOMERS_ACTIVE,
  DEVICES,
  FWI_MODULES,
  LIBRARY,
  REPORTING,
} from 'utils/routes';

export const getAuth = (state: AppState): AuthState => state.auth;

/**
 * @returns the current user entity or undefined when the user hasn't been
 * authenticated yet
 */
export const getCurrentUser = (
  state: AppState
): Readonly<CurrentUserEntity> | undefined => getAuth(state).user;

/**
 * A selector to see if the logged in user has access to a specific FWI module.
 *
 * @param state The top-level store state
 * @param moduleId The moduleId to check against.
 * @return true if the user has access to the specific module
 */
export const isModuleAuthorized = (
  state: AppState,
  moduleId: AppModuleId
): boolean => {
  return (
    getCurrentUser(state)?.permissions.find(
      (perm) => perm.moduleId === moduleId
    )?.effect === 'allow'
  );
};

/**
 * A selector to see if the logged in user does not have access to any of the
 * provided `moduleIds` by checking the {@link UserEntity.permissions} array.
 *
 * @param state The top-level store state
 * @param moduleIds A list of modules to check against
 * @returns true if the user does not have access to one of the provided module
 * ids
 */
export const isMissingAuthorization = (
  state: AppState,
  moduleIds: readonly AppModuleId[]
): boolean => {
  return moduleIds.some((scope) => !isModuleAuthorized(state, scope));
};

/**
 * @returns the current user's email address or the empty string
 */
export const getCurrentUserEmail = (state: AppState): string =>
  getCurrentUser(state)?.email ?? '';

/**
 * @returns true if the identity provider id is loading for the user's email.
 */
export const isLoadingIdp = (state: AppState): boolean =>
  getAuth(state).loadingIdp;

/**
 * @returns true if the current user info is loading.
 */
export const isLoadingCurrentUser = (state: AppState): boolean =>
  getAuth(state).loadingUser;

/**
 * @returns the current identity provider id for the user's email if one exists
 */
export const getIdpId = (state: AppState): string => getAuth(state).idpId;

/**
 * @returns the current auth status for the user.
 */
export const getAuthStatus = (state: AppState): AuthenticationStatus =>
  getAuth(state).status;

/**
 * @param state - The top level store state.
 * @param complete - Boolean if the user should be fully authenticated with both
 * an access token and company id.
 * @returns true if the user has been authenticated.
 */
export const isAuthenticated = (
  state: AppState,
  complete: boolean
): boolean => {
  const status = getAuthStatus(state);
  return status === 'done' || (!complete && status === 'token');
};

/**
 * @returns a list of the user's companies or an empty list if the user hasn't
 * been authenticated yet.
 */
export const getUserCompanies = (
  state: AppState
): ReadonlyEntityList<UserCompany> => {
  return getCurrentUser(state)?.companies || [];
};

/**
 * Gets the current company information for the user based on the `companyId`
 * cookie.
 */
export const getCurrentCompany = (
  state: AppState
): Readonly<UserCompany> | undefined => {
  const companyId = getCompanyId();

  return getUserCompanies(state).find((company) => company.id === companyId);
};

/**
 * @returns true if the user is an administrator.
 */
export const isCurrentUserAdmin = (state: AppState): boolean =>
  isModuleAuthorized(state, SYS_NAME_KEYS.AdminCenter);

/**
 * @returns true if the current user is able to view the alerts module
 */
export const isAlertsAccessible = (state: AppState): boolean =>
  ALERTS_SCOPES.every((scope) => isModuleAuthorized(state, scope));

interface AvailableModulesAndLinks {
  links: UserModuleOrLink[];
  modules: UserModuleOrLink[];
}

/**
 * Gets all the valid links to other FWI modules or links set in customer
 * management.
 *
 * @returns {@link AvailableModulesAndLinks}
 */
export const getAvailableModulesAndLinks = (
  state: AppState
): AvailableModulesAndLinks => {
  const permissions = getCurrentUser(state)?.permissions;
  const result: AvailableModulesAndLinks = {
    links: [],
    modules: [],
  };
  if (!permissions || !permissions.length) {
    return result;
  }

  return permissions.reduce(reducePermissions({ state }), result);
};

const reducePermissions =
  ({ state }: { state: AppState }) =>
  (
    result: AvailableModulesAndLinks,
    perm: ModulePermission
  ): AvailableModulesAndLinks => {
    const { moduleId, effect } = perm;
    if (effect !== ALLOW) {
      return result;
    }

    const cmwUrl = getCurrentCompany(state)?.cmwUrl;
    if (moduleId === SYS_NAME_KEYS.ContentManagerWeb && cmwUrl) {
      result.links.push({
        moduleId,
        href: cmwUrl,
        target: '_blank',
      });
    } else if (moduleId === SYS_NAME_KEYS.Community) {
      result.links.push({
        moduleId,
        href: COMMUNITY_MAIN_PAGE,
        target: '_blank',
      });
    } else {
      const href = FWI_MODULES[moduleId];
      if (href) {
        result.modules.push({
          moduleId,
          href,
        });
      }
    }

    const LINK_SORT_ORDER: string[] = [
      SYS_NAME_KEYS.ContentManagerWeb,
      SYS_NAME_KEYS.Community,
    ];

    result.links.sort(
      (a, b) =>
        LINK_SORT_ORDER.indexOf(a.moduleId) -
        LINK_SORT_ORDER.indexOf(b.moduleId)
    );
    result.modules.sort((a, b) => (a.moduleId > b.moduleId ? 1 : -1));

    return result;
  };

// TODO: Remove once dashboard becomes unified
const COMMUNICATIONS_SORT_ORDER: readonly string[] = [
  DigitalSignageModuleCode.Channels,
  DigitalSignageModuleCode.Campaigns,
  DigitalSignageModuleCode.ContentManagerWeb,
];

const forEachPermissions =
  ({
    cmwUrl,
    reporting,
    adminCenter,
    content,
    receivers,
    communications,
    configure,
  }: {
    reporting: DashboardMenuItem[];
    adminCenter: DashboardMenuItem[];
    content: DashboardMenuItem[];
    receivers: DashboardMenuItem[];
    communications: DashboardMenuItem[];
    configure: DashboardMenuItem[];
    cmwUrl?: string;
  }) =>
  ({ moduleId, effect }: ModulePermission): void => {
    if (effect !== ALLOW) {
      return;
    }

    switch (moduleId) {
      case 'Reporting':
        reporting.push({
          code: DigitalSignageModuleCode.Reporting,
          url: REPORTING,
        });
        break;
      case 'AdminCenter':
        adminCenter.push(
          {
            code: DigitalSignageModuleCode.Users,
            url: ADMIN_USERS,
          },
          {
            code: DigitalSignageModuleCode.Groups,
            url: ADMIN_GROUPS,
          },
          {
            code: DigitalSignageModuleCode.Labels,
            url: ADMIN_LABELS,
          },
          {
            code: DigitalSignageModuleCode.Settings,
            url: ADMIN_SETTINGS,
          }
        );
        break;
      case 'Library':
        content.push({
          code: DigitalSignageModuleCode.Library,
          url: LIBRARY,
        });
        break;
      case 'DeviceManagement':
        receivers.push({
          code: DigitalSignageModuleCode.Devices,
          url: DEVICES,
        });
        break;
      case 'Channels':
        communications.push({
          code: DigitalSignageModuleCode.Channels,
          url: CHANNELS,
        });
        break;
      case 'CustomerManagement':
        configure.unshift({
          code: DigitalSignageModuleCode.Customers,
          url: CUSTOMERS_ACTIVE,
        });
        break;
      case 'Alerts':
        break;
      case 'CampaignManagement':
        communications.push({
          code: DigitalSignageModuleCode.Campaigns,
          url: CAMPAIGNS,
        });
        break;
      case 'ContentManagerWeb':
        if (cmwUrl) {
          communications.push({
            code: DigitalSignageModuleCode.ContentManagerWeb,
            url: cmwUrl,
          });
        }
        break;
    }
  };

// TODO: Remove once dashboard becomes unified
export const getUnifiedModuleLinks = (
  state: AppState,
  isReportsEnabled: boolean
): readonly DashboardGroup[] => {
  const permissions = getCurrentUser(state)?.permissions;
  if (!permissions) {
    return [];
  }

  const cmwUrl = getCurrentCompany(state)?.cmwUrl;

  const content: DashboardMenuItem[] = [];
  const receivers: DashboardMenuItem[] = [];
  const communications: DashboardMenuItem[] = [];
  const configure: DashboardMenuItem[] = [];
  const adminCenter: DashboardMenuItem[] = [];
  const reporting: DashboardMenuItem[] = [];

  permissions.forEach(
    forEachPermissions({
      content,
      receivers,
      communications,
      configure,
      adminCenter,
      reporting,
      cmwUrl,
    })
  );

  const links: DashboardGroup[] = [];
  if (content.length) {
    links.push({
      code: DigitalSignageGroupCode.Content,
      menuItems: content,
    });
  }

  if (receivers.length) {
    links.push({
      code: DigitalSignageGroupCode.Receivers,
      menuItems: receivers,
    });
  }

  if (communications.length) {
    links.push({
      code: DigitalSignageGroupCode.Communications,
      menuItems: communications.sort(
        (a, b) =>
          COMMUNICATIONS_SORT_ORDER.indexOf(a.code) -
          COMMUNICATIONS_SORT_ORDER.indexOf(b.code)
      ),
    });
  }

  if (configure.length) {
    links.push({
      code: DigitalSignageGroupCode.Configure,
      menuItems: configure,
    });
  }

  if (isReportsEnabled && reporting.length) {
    links.push({
      code: DigitalSignageGroupCode.Reporting,
      menuItems: reporting,
    });
  }

  if (adminCenter.length) {
    links.push({
      code: DigitalSignageGroupCode.AdminCenter,
      menuItems: adminCenter,
    });
  }

  return links;
};
