import { LicenseType as InclusiveLicenseType } from 'fwi-fe-types';

import { LicenseType } from 'appTypes';

export const AUTHOR = InclusiveLicenseType.AUTHOR;
export const CONTRIBUTOR = InclusiveLicenseType.CONTRIBUTOR;
export const VIEWER = InclusiveLicenseType.VIEWER;
export const SUPERUSER = InclusiveLicenseType.SUPERUSER;
export const ADMIN = 'ADMIN'; // not really a license type but we need to treat it like one.

export const BASIC_LICENSE_TYPES: LicenseType[] = [
  LicenseType.AUTHOR,
  LicenseType.CONTRIBUTOR,
  LicenseType.VIEWER,
];
