import { ReactElement, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { Dialog, DialogHeader } from 'fwi-fe-components';

import FWICloud from 'components/App/FWICloud';

const noop = (): void => {};

export interface ErrorPageConditionalModalProps {
  id: string;
  modal: boolean;
  children: ReactNode;
}

export default function ErrorPageConditionalModal({
  id,
  modal,
  children,
}: ErrorPageConditionalModalProps): ReactElement {
  const intl = useIntl();
  if (!modal) {
    return <>{children}</>;
  }

  return (
    <Dialog
      id={id}
      aria-label={intl.formatMessage({ id: 'ErrorPage.PageNotFound' })}
      aria-describedby="error-page-heading error-page-description"
      type={Dialog.Type.FULL_PAGE}
      appear={false}
      enter={false}
      exit={false}
      visible
      onRequestClose={noop}
      defaultFocus="button"
    >
      <DialogHeader>
        <FWICloud unifiedLogoColor="white" />
      </DialogHeader>
      {children}
    </Dialog>
  );
}
