import { createEntityAdapter } from '@reduxjs/toolkit';
import { schema } from 'normalizr';

import { LabelValueEntity } from 'appTypes';

export const adapter = createEntityAdapter<LabelValueEntity>();

/**
 * @see {@link NormalizedLabels} for more info around the `labelValues`
 * identifier
 */
export const LABEL_VALUE_ENTITY = new schema.Entity<LabelValueEntity>(
  'labelValues'
);
export const LABEL_VALUES_SCHEMA = new schema.Array<LabelValueEntity[]>(
  LABEL_VALUE_ENTITY
);
