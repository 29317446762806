import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

import { getCurrentUser, isCurrentUserAdmin, useAppSelector } from 'appState';
import {
  isAdmin as isAdminRoute,
  isAdminSettings,
  isAlerts,
  isCustomers,
  isLoginOrLogout,
  isReporting,
} from 'utils/routes';

import AddAlertForMe from './AddAlertForMe';
import AddAlertForSomeoneElse from './AddAlertForSomeoneElse';
import AdminActions from './AdminActions';
import CustomersActions from './CustomersActions';
import NewContentButton, { NewContentButtonProps } from './NewContentButton';

export interface AppSpecificActionsProps
  extends Pick<NewContentButtonProps, 'theme' | 'mobileText'> {
  idPrefix?: string;
}

/**
 * TODO: Refactor this component a bit since we're starting to have more apps
 * display filters and sort view toggle in the header.
 */
export default function AppSpecificActions({
  idPrefix = '',
  mobileText,
  theme,
}: AppSpecificActionsProps): ReactElement | null {
  const { pathname } = useLocation();
  const user = useAppSelector(getCurrentUser);
  const isAdmin = useAppSelector(isCurrentUserAdmin);

  if (!user || isLoginOrLogout(pathname) || isAdminSettings(pathname)) {
    return null;
  }

  const isAlertsRoute = isAlerts(pathname);
  if (isAlertsRoute && !isAdmin) {
    return <AddAlertForMe idPrefix={idPrefix} as="button" />;
  }

  const buttonId = `${idPrefix}new-content`;
  if (isCustomers(pathname)) {
    return <CustomersActions id={buttonId} isNoContent={!!idPrefix} />;
  }

  if (isAdminRoute(pathname)) {
    return <AdminActions id={buttonId} filterId={`${idPrefix}filter-button`} />;
  }

  if (isReporting(pathname)) {
    return null;
  }

  return (
    <NewContentButton id={buttonId} theme={theme} mobileText={mobileText}>
      {isAlertsRoute && <AddAlertForMe />}
      {isAlertsRoute && <AddAlertForSomeoneElse />}
    </NewContentButton>
  );
}
