import { AppState, ChannelSchedulesState } from 'appTypes';

import { adapter } from './schema';

const getChannelSchedulesState = (state: AppState): ChannelSchedulesState =>
  state.channelSchedules;

export const {
  selectById: getChannelScheduleById,
  selectAll: getChannelSchedules,
  selectEntities: getChannelScheduleEntities,
  selectIds: getChannelScheduleIds,
  selectTotal: getTotalChannelSchedules,
} = adapter.getSelectors(getChannelSchedulesState);
