import { createReducer } from '@reduxjs/toolkit';

import { MarketSolutionsState } from 'appTypes';

import { fetchMarketSolutions } from './api';
import { adapter } from './schema';

export const INITIAL_MARKET_SOLUTIONS_STATE: MarketSolutionsState =
  adapter.getInitialState({
    loading: false,
  });

export default createReducer(INITIAL_MARKET_SOLUTIONS_STATE, (builder) =>
  builder
    .addCase(fetchMarketSolutions.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchMarketSolutions.fulfilled, (state, { payload }) => {
      state.loading = false;
      adapter.upsertMany(state, payload);
    })
    .addCase(fetchMarketSolutions.rejected, (state) => {
      state.loading = false;
    })
);
