import { createReducer } from '@reduxjs/toolkit';

import { ChannelsState } from 'appTypes';

import { fetchChannel, fetchChannels } from './api';
import { adapter } from './schema';

export const INITIAL_CHANNELS_STATE: ChannelsState = adapter.getInitialState();

export default createReducer(INITIAL_CHANNELS_STATE, (builder) =>
  builder
    .addCase(fetchChannels.fulfilled, (state, action) => {
      adapter.upsertMany(state, action.payload.channels);
    })
    .addCase(fetchChannel.fulfilled, (state, action) => {
      adapter.upsertMany(state, action.payload.channels);
    })
);
