import { Component, ReactNode } from 'react';

import ErrorPage from 'components/ErrorPage';

interface Props {
  children: ReactNode;
}

interface State {
  error: Error | null;
}

export default class ErrorBoundary extends Component<Props, State> {
  public static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  constructor(props: Props) {
    super(props);

    this.state = { error: null };
  }

  public render(): ReactNode {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return <ErrorPage error={error} />;
    }

    return children;
  }
}
