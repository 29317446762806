import { createContext, useContext } from 'react';

export type SetHtmlClassName = (className: string) => void;

const context = createContext<SetHtmlClassName>(() => {});

/**
 * This is used to update the base `html` class if you need to change the
 * background color.
 *
 * @example
 * Simple Example
 * ```ts
 * const setHtmlClassName = useSetHtmlClassName();
 *
 * useEffect(() => {
 *   setHtmlClassName(styles.customBackground);
 *
 *   return () => {
 *     setHtmlClassName('');
 *   }:
 * }, []);
 * ```
 */
export function useSetHtmlClassName(): SetHtmlClassName {
  return useContext(context);
}

export const { Provider: SetHtmlClassNameProvider } = context;
