import { ReactElement, ReactNode } from 'react';

import { LoadingOrContent } from 'fwi-fe-components';

import FWICloud from 'components/App/FWICloud';
import FormattedMessage from 'components/FormattedMessage';
import { useSplit, useSplitEnabled } from 'hooks/useSplit';

import styles from './SupportedBrowserCheck.module.scss';
import chrome from './chrome.png';
import edge from './edge.png';
import safari from './safari.png';

const isIE = (): boolean => navigator.userAgent.includes('Trident');
const isFirefox = (): boolean => navigator.userAgent.includes('Firefox');

export interface SupportedBrowser {
  name: string;
  href: string;
  image: string;
}

const supported: readonly SupportedBrowser[] = [
  {
    name: 'Chrome',
    href: 'https://www.google.com/chrome/',
    image: chrome,
  },
  {
    name: 'Safari',
    href: 'https://support.apple.com/downloads/safari',
    image: safari,
  },
  {
    name: 'Edge',
    href: 'https://www.microsoft.com/windows/microsoft-edge',
    image: edge,
  },
];

export interface SupportedBrowserCheckProps {
  children: ReactNode;
}

/**
 * This component will check if the user is using an unsupported browser. If it
 * is, it will render an error page instead of the children.
 */
export default function SupportedBrowserCheck({
  children,
}: SupportedBrowserCheckProps): ReactElement | null {
  const { client, isReady } = useSplit();
  const loading = !client && !isReady;
  const firefoxEnabled = useSplitEnabled('DS_FIREFOX');

  const ie = isIE();
  const isBrowserSupported = !ie && (firefoxEnabled || !isFirefox());
  if (loading || isBrowserSupported) {
    return <LoadingOrContent loading={loading}>{children}</LoadingOrContent>;
  }

  return (
    <main className={styles.container}>
      <header className={styles.header}>
        <FWICloud theme="light" />
      </header>
      <div className={styles.card}>
        <FormattedMessage
          messageId={`UnsupportedBrowser.${ie ? 'IE' : 'Firefox'}`}
          component="h2"
        />
        <FormattedMessage
          messageId="UnsupportedBrowser.Description"
          component="p"
          className={styles.description}
        />
        <ul className={styles.list}>
          {supported.map(({ name, href, image }) => (
            <li key={name}>
              <a href={href} className={styles.link}>
                <img src={image} alt="" className={styles.browser} />
                <FormattedMessage
                  messageId={`UnsupportedBrowser.Browser${name}`}
                />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </main>
  );
}
