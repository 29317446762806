import { fork, spawn } from 'redux-saga/effects';

import { DISABLE_SEGMENT, SEGMENT_CODE } from 'constants/env';

import { loadAnalytics } from './analytics/sagas';
import { waitForAuthentication, watchForSettingChanges } from './auth/sagas';
import { companySettingsSagas } from './companySettings/sagas';
import { customersSagas } from './customers/sagas';
import { groupsSagas } from './groups/sagas';
import { labelsSagas } from './labels/sagas';
import { selectionsSagas } from './selections/sagas';
import { toastQueue } from './toasts/sagas';
import { usersSagas } from './users/sagas';

export function* allSagas() {
  yield fork(loadAnalytics, {
    segmentCode: SEGMENT_CODE,
    disableSegment: DISABLE_SEGMENT,
  });
  yield fork(toastQueue);
  yield waitForAuthentication();

  yield spawn(watchForSettingChanges);
  yield spawn(customersSagas);
  yield spawn(usersSagas);
  yield spawn(labelsSagas);
  yield spawn(groupsSagas);
  yield spawn(selectionsSagas);
  yield spawn(companySettingsSagas);
}
