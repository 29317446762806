import {
  MODULES as FWI_MODULES,
  MODULE_KEYS as FWI_MODULE_KEYS,
  SYS_NAME_KEYS,
} from 'fwi-constants';

const { CUSTOMER_MANAGEMENT, DEVICE_SERVICE } = FWI_MODULE_KEYS;

export const MODULES = Object.keys(FWI_MODULES).filter(
  (key) => key !== CUSTOMER_MANAGEMENT && key !== DEVICE_SERVICE
);

// Since people keep randomly adding duplicates and stupid fricken weird
// services we don't care about for the typical user, we'll just need to manage
// update this list as needed.
export const COMPLETE_PERMISSIONS_MODULE_IDS = [
  SYS_NAME_KEYS.AdminCenter,
  SYS_NAME_KEYS.Alerts,
  SYS_NAME_KEYS.Reporting,
  SYS_NAME_KEYS.AnalyticsService,
  SYS_NAME_KEYS.AuthenticationService,
  SYS_NAME_KEYS.AuthorizationService,
  SYS_NAME_KEYS.Library,
  SYS_NAME_KEYS.CampaignManagement,
  SYS_NAME_KEYS.Community,
  SYS_NAME_KEYS.ContentManagerWeb,
  SYS_NAME_KEYS.DeviceManagement,
  SYS_NAME_KEYS.LabelService,
  SYS_NAME_KEYS.CloudSearch,
  SYS_NAME_KEYS.Channels,
  SYS_NAME_KEYS.Applications,
];

export const AUTHOR_PERMISSIONS = [
  SYS_NAME_KEYS.AdminCenter,
  SYS_NAME_KEYS.AnalyticsService,
  SYS_NAME_KEYS.Alerts,
  SYS_NAME_KEYS.Reporting,
  SYS_NAME_KEYS.Community,
  SYS_NAME_KEYS.Library,
  SYS_NAME_KEYS.ContentManagerWeb,
  SYS_NAME_KEYS.DeviceManagement,
  SYS_NAME_KEYS.Channels,
  SYS_NAME_KEYS.LabelService,
  SYS_NAME_KEYS.CloudSearch,
];

export const CONTRIBUTOR_PERMISSIONS = [
  SYS_NAME_KEYS.AnalyticsService,
  SYS_NAME_KEYS.Alerts,
  SYS_NAME_KEYS.Reporting,
  SYS_NAME_KEYS.Community,
  SYS_NAME_KEYS.Library,
  SYS_NAME_KEYS.ContentManagerWeb,
  SYS_NAME_KEYS.DeviceManagement,
  SYS_NAME_KEYS.Channels,
  SYS_NAME_KEYS.CloudSearch,
];

export const SUPERUSER_PERMISSIONS = [
  SYS_NAME_KEYS.AdminCenter,
  SYS_NAME_KEYS.AnalyticsService,
  SYS_NAME_KEYS.Alerts,
  SYS_NAME_KEYS.Reporting,
  SYS_NAME_KEYS.CampaignManagement,
  SYS_NAME_KEYS.Community,
  SYS_NAME_KEYS.Library,
  SYS_NAME_KEYS.ContentManagerWeb,
  SYS_NAME_KEYS.CustomerManagement,
  SYS_NAME_KEYS.DeviceManagement,
  SYS_NAME_KEYS.LabelService,
  SYS_NAME_KEYS.Channels,
  SYS_NAME_KEYS.CloudSearch,
];

export const VIEWER_PERMISSIONS = [SYS_NAME_KEYS.Community];
