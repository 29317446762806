import { useEffect, useState } from 'react';

import { GroupEntity, PaginationRequestOptions, SortOrder } from 'fwi-fe-types';

import { baseApi } from 'appState/baseApi';
import { ELASTIC_SEARCH_MAX_WINDOW_SIZE } from 'constants/constraints';
import { GROUPS_ENDPOINT } from 'constants/endpoints';
import { createSortQuerystring, format } from 'utils/api';

import { GroupsResponseData } from './api';

export const groupsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    groupsSearch: builder.query<
      GroupsResponseData,
      Partial<PaginationRequestOptions> | void
    >({
      providesTags: ['Groups'],
      query: ({
        size = 24,
        offset = 0,
        sort = 'name',
        sortOrder = 'asc',
      } = {}) => {
        const qs = createSortQuerystring({
          size,
          offset,
          sort,
          sortOrder,
        });

        return format(`${GROUPS_ENDPOINT}${qs}`);
      },
    }),
  }),
});

export const { useGroupsSearchQuery, useLazyGroupsSearchQuery } = groupsApi;

export interface GetAllGroupsQueryOptions {
  skip?: boolean;
  size?: number;
  sort?: string;
  sortOrder?: SortOrder;
}

/**
 * This hook uses `@reduxjs/toolkit/query` to fetch **all** groups within the
 * company sorted by name.
 */
// eslint-disable-next-line
export function useGetAllGroupsQuery({
  skip = false,
  size = -1,
  sort = 'name',
  sortOrder = 'asc',
}: GetAllGroupsQueryOptions) {
  interface State {
    groups: readonly GroupEntity[];
    offset: number;
  }

  const [{ groups, offset }, setState] = useState<State>({
    groups: [],
    offset: 0,
  });
  const [trigger, { data, ...result }] = useLazyGroupsSearchQuery();
  useEffect(() => {
    if (skip || offset >= ELASTIC_SEARCH_MAX_WINDOW_SIZE) {
      return;
    }

    trigger({ size, offset, sort, sortOrder }, true);
  }, [offset, size, skip, sort, sortOrder, trigger]);

  useEffect(() => {
    if (!data) {
      return;
    }

    setState(({ groups, offset }) => {
      const nextGroups = [...groups, ...data.items];
      let nextOffset = offset;
      if (data.numberOfItems > nextGroups.length) {
        nextOffset += data.items.length;
      }

      return {
        groups: nextGroups,
        offset: nextOffset,
      };
    });
  }, [data]);

  return {
    ...result,
    data: groups,
  };
}
