import { type ReactElement, type ReactNode } from 'react';
import { useLocation } from 'react-router';

import { EmailAndFeedsRedirectProvider } from 'fwi-fe-components';

import {
  EMAIL_AND_FEEDS_AP_AUTHENTICATE_ENDPOINT,
  EMAIL_AND_FEEDS_AUTHENTICATE_ENDPOINT,
  EMAIL_AND_FEEDS_EU_AUTHENTICATE_ENDPOINT,
  EMAIL_AND_FEEDS_US_AUTHENTICATE_ENDPOINT,
} from 'constants/env';
import { useSplitEnabled } from 'hooks/useSplit';
import { isLoginOrLogout } from 'utils/routes';
import { emailAndFeedsTokenController } from 'utils/tokens';

export interface UnifiedProviderProps {
  children: ReactNode;
}

export function UnifiedProvider({
  children,
}: Readonly<UnifiedProviderProps>): ReactElement {
  const enabled = useSplitEnabled('UNIFIED_LOGIN_AND_LANDING');
  const { pathname } = useLocation();

  return (
    <EmailAndFeedsRedirectProvider
      action={EMAIL_AND_FEEDS_AUTHENTICATE_ENDPOINT}
      usAction={EMAIL_AND_FEEDS_US_AUTHENTICATE_ENDPOINT}
      apAction={EMAIL_AND_FEEDS_AP_AUTHENTICATE_ENDPOINT}
      euAction={EMAIL_AND_FEEDS_EU_AUTHENTICATE_ENDPOINT}
      enabled={enabled && !isLoginOrLogout(pathname)}
      controller={emailAndFeedsTokenController}
    >
      {children}
    </EmailAndFeedsRedirectProvider>
  );
}
