import { createReducer } from '@reduxjs/toolkit';

import { FoldersState } from 'appTypes';

import {
  fetchChannelFolders,
  fetchDeviceFolders,
  fetchLibraryFolders,
} from './api';
import { adapter } from './schema';

export const INITIAL_FOLDERS_STATE: FoldersState = adapter.getInitialState({
  loadingDevices: false,
  loadingChannels: false,
  loadingLibrary: false,
});

export default createReducer(INITIAL_FOLDERS_STATE, (builder) =>
  builder
    .addCase(fetchDeviceFolders.pending, (state) => {
      state.loadingDevices = true;
    })
    .addCase(fetchDeviceFolders.fulfilled, (state, action) => {
      state.loadingDevices = false;
      adapter.upsertMany(state, action.payload.folders);
    })
    .addCase(fetchDeviceFolders.rejected, (state) => {
      state.loadingDevices = false;
    })
    .addCase(fetchChannelFolders.pending, (state) => {
      state.loadingChannels = true;
    })
    .addCase(fetchChannelFolders.fulfilled, (state, action) => {
      state.loadingChannels = false;
      adapter.upsertMany(state, action.payload.folders);
    })
    .addCase(fetchChannelFolders.rejected, (state) => {
      state.loadingChannels = false;
    })
    .addCase(fetchLibraryFolders.pending, (state) => {
      state.loadingLibrary = true;
    })
    .addCase(fetchLibraryFolders.fulfilled, (state, action) => {
      state.loadingLibrary = false;
      adapter.upsertMany(state, action.payload.folders);
    })
    .addCase(fetchLibraryFolders.rejected, (state) => {
      state.loadingLibrary = false;
    })
);
