import { ReactElement } from 'react';

import { CircularProgress } from 'fwi-fe-components';

import { getCurrentCompany, isAuthenticated, useAppSelector } from 'appState';
import ScopedRoute, { ScopedRouteProps } from 'components/ScopedRoute';

import { PidsOrLoginRedirect } from './PidsOrLoginRedirect';

export interface SecureRouteProps extends ScopedRouteProps {
  /**
   * Should this not redirect to the login page if the access token is set, but
   * not the company id.
   */
  allowToken?: boolean;
}

export default function SecureRoute({
  allowToken = false,
  ...props
}: SecureRouteProps): ReactElement {
  const authenticated = useAppSelector((state) =>
    isAuthenticated(state, !allowToken)
  );
  const company = useAppSelector(getCurrentCompany);

  if (!authenticated) {
    return <PidsOrLoginRedirect />;
  }

  if (!company && !allowToken) {
    return <CircularProgress id="loading-company" />;
  }

  return <ScopedRoute {...props} />;
}
