import cn from 'classnames';
import { MouseEvent, ReactElement } from 'react';

import { CheckCircleFilledIcon, MenuItem } from 'fwi-fe-components';
import { EntityId } from 'fwi-fe-types';

import styles from './CompanyMenuItem.module.scss';

export interface CompanyMenuItemProps {
  id: string;
  companyId: EntityId;
  name: string;
  checked: boolean;
  onCompanyChange(companyId: EntityId, companyName: string): void;
}

export default function CompanyMenuItem({
  id,
  companyId,
  name,
  checked,
  onCompanyChange,
}: CompanyMenuItemProps): ReactElement {
  return (
    <MenuItem
      id={id}
      className={cn(styles.container, {
        [styles.containerChecked]: checked,
      })}
      role="menuitemradio"
      aria-checked={checked}
      onClick={(event: MouseEvent<HTMLLIElement>) => {
        if (!checked) {
          event.stopPropagation();
        }

        onCompanyChange(companyId, name);
      }}
    >
      {/* TODO: Switch to EllipsedText once tooltip supports portals */}
      <span className={styles.text}>{name}</span>
      {checked && <CheckCircleFilledIcon />}
    </MenuItem>
  );
}
