import { omit } from 'lodash';
import shallowEqual from 'shallowequal';

import { UserEntity } from 'fwi-fe-types';

import { EditableUserWithProvider } from 'appTypes';

import { isArrayEqual } from './arrays';
import { isPermissionsEqual } from './permissions';

/**
 * Formats a user's name to include both the first and last name.
 *
 * @param user - The user to format
 * @returns a string with both the first and last name.
 */
export function formatUserName(
  user: Pick<UserEntity, 'firstName' | 'lastName'>
): string {
  const { firstName, lastName } = user;
  return `${firstName} ${lastName}`;
}

/**
 *  Compares two users and returns true if they are identical.
 *
 * @param firstuser the first user to compare
 * @param  seconduser the second user to compare
 * @returns true if users are equal
 */
export function isUserEqual(
  user1: EditableUserWithProvider,
  user2: EditableUserWithProvider
): boolean {
  const omitKeys = ['groups', 'permissions'];
  if (!shallowEqual(omit(user1, omitKeys), omit(user2, omitKeys))) {
    return false;
  }

  if (!isArrayEqual(user1.groups, user2.groups)) {
    return false;
  }

  return isPermissionsEqual(user1.permissions, user2.permissions);
}
