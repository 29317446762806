import { createAsyncThunk } from '@reduxjs/toolkit';

import { EntityId } from 'fwi-fe-types';
import { StatusCodes } from 'fwi-fe-utils';

import {
  APIRejectionThunkConfig,
  APIStatusRejectionThunkConfig,
  Alert,
  NewAlert,
} from 'appTypes';
import { ALERTS_ENDPOINT, ALERT_ENDPOINT } from 'constants/endpoints';
import { api } from 'utils/api';

/**
 * Gets all the alerts for the company.
 */
export const fetchAlerts = createAsyncThunk<
  Alert[],
  void,
  APIRejectionThunkConfig
>('alerts/fetch', async (_, { rejectWithValue }) => {
  const response = await api(ALERTS_ENDPOINT);

  if (!response.ok) {
    const { status } = response;
    return rejectWithValue({
      status,
      unmodified: status === StatusCodes.NOT_MODIFIED,
    });
  }

  const json: Alert[] = await response.json();
  return json;
});

export type UpsertableAlert = NewAlert | (NewAlert & { id: EntityId });

/**
 * Creates or modifies an alert.
 *
 * @param alert - the alert to modify or a new alert to create
 */
export const upsertAlert = createAsyncThunk<
  Alert,
  UpsertableAlert,
  APIStatusRejectionThunkConfig
>('alert/upsert', async (alert, { rejectWithValue }) => {
  const response = await api(ALERTS_ENDPOINT, {
    method: 'PUT',
    body: JSON.stringify(alert),
  });

  if (!response.ok) {
    const { status, statusText } = response;
    return rejectWithValue({ status, statusText });
  }

  const json: Alert = await response.json();
  return json;
});

/**
 * This API calls is really used to "delete" alerts.
 *
 * @param patch - The alert patch information.
 */
export const patchAlert = createAsyncThunk<
  void,
  Pick<Alert, 'id' | 'isAlertActive'>,
  APIStatusRejectionThunkConfig
>('alert/patch', async ({ id, isAlertActive }, { rejectWithValue }) => {
  const response = await api(ALERT_ENDPOINT, {
    params: { alertId: id },
    method: 'PATCH',
    body: JSON.stringify({ isAlertActive }),
  });

  if (!response.ok) {
    const { status, statusText } = response;
    return rejectWithValue({ status, statusText });
  }
});
