import {
  AppState,
  ReadonlyEntityList,
  UploadStatus,
  UserUpload,
} from 'appTypes';

/**
 *
 * @param state The top-level store state.
 * @returns The bulk upload modal visibility.
 */
export const isBulkUploadVisible = (state: AppState): boolean =>
  state.bulkUserOps.visible;

/**
 *
 * @param state The top-level store state.
 * @returns The bulk user ops uploads in a list.
 */
export const getBulkUserOpsUploads = (
  state: AppState
): ReadonlyEntityList<UserUpload> => {
  const { uploads } = state.bulkUserOps;
  return (
    Object.keys(uploads).map((key) => ({ name: key, ...uploads[key] })) || []
  );
};

/**
 *
 * @param state The top-level store state.
 * @returns The first error in bulk user ops uploads. Null if one does not exist.
 */
export const getBulkUserOpsUploadsError = (
  state: AppState
): UserUpload | null => {
  const { uploads } = state.bulkUserOps;
  const errorUpload = Object.values(uploads).find(
    ({ status, error }) => status === UploadStatus.FAILED && !!error
  );
  return errorUpload || null;
};
