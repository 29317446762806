import { createAsyncThunk } from '@reduxjs/toolkit';

import { addToast } from 'appState/toasts';
import { APIStatusRejection, AppThunkConfig, UserUploadError } from 'appTypes';
import { USERS_CSV_ENDPOINT } from 'constants/endpoints';
import { api, apiDownload, format } from 'utils/api';

/**
 * Downloads the bulk user upload template CSV.
 */
export const downloadBulkUserUploadTemplateCSV = createAsyncThunk(
  'bulkUserOps/fetch',
  async (_, { dispatch }) => {
    await apiDownload(USERS_CSV_ENDPOINT, 'UsersTemplate.csv').catch(() =>
      dispatch(addToast({ messageId: 'CsvDownloadFailure' }))
    );
  }
);

/**
 * Starts the bulk upload process with the provided CSV.
 *
 * @param arg - The bulk user upload csv to use
 */
export const postBulkUserUploadCsv = createAsyncThunk<
  void,
  File,
  AppThunkConfig<APIStatusRejection & { error: UserUploadError }>
>('bulkUserOps/post', async (file: File, { rejectWithValue }) => {
  const endpoint = format(USERS_CSV_ENDPOINT);
  const data = new FormData();
  data.append('file', file, file.name);

  const response = await api(endpoint, {
    method: 'POST',
    body: data,
    inferType: true,
  });
  const json = await response.json();

  if (!response.ok) {
    const { status, statusText } = response;
    return rejectWithValue({ status, statusText, error: json });
  }
});
