import { createAction, createReducer } from '@reduxjs/toolkit';

import { GroupEntity } from 'fwi-fe-types';

import { deleteUser, fetchUsersByGroupId } from 'appState/users/api';
import { GroupsState } from 'appTypes';

import {
  deleteGroup,
  fetchDefaultGroups,
  fetchGroup,
  fetchGroups,
  fetchGroupsByUserId,
  postGroup,
  putGroup,
} from './api';
import { adapter } from './schema';

export const INITIAL_GROUPS_STATE: GroupsState = adapter.getInitialState({
  loading: false,
  loadingIds: [],
  loadingDefault: false,
  loadingByUserIds: [],
  loadingDeleteIds: [],
  loadingDuplicateIds: [],
});
export const addGroupsFromReduxQuery = createAction<readonly GroupEntity[]>(
  'groups/addFromQuery'
);

export default createReducer(INITIAL_GROUPS_STATE, (builder) =>
  builder
    .addCase(addGroupsFromReduxQuery, (state, action) => {
      adapter.upsertMany(state, action.payload);
    })
    .addCase(fetchGroups.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchGroups.fulfilled, (state, action) => {
      state.loading = false;
      adapter.upsertMany(state, action.payload.items);
    })
    .addCase(fetchGroups.rejected, (state) => {
      state.loading = false;
    })
    .addCase(fetchDefaultGroups.pending, (state) => {
      state.loadingDefault = true;
    })
    .addCase(fetchDefaultGroups.fulfilled, (state, action) => {
      state.loadingDefault = false;
      adapter.upsertMany(state, action.payload.items);
    })
    .addCase(fetchDefaultGroups.rejected, (state) => {
      state.loadingDefault = false;
    })
    .addCase(fetchGroup.pending, (state, action) => {
      state.loadingIds.push(action.meta.arg);
    })
    .addCase(fetchGroup.fulfilled, (state, action) => {
      state.loadingIds = state.loadingIds.filter(
        (id) => id !== action.meta.arg
      );
      adapter.upsertOne(state, action.payload);
    })
    .addCase(fetchGroup.rejected, (state, action) => {
      state.loadingIds = state.loadingIds.filter(
        (id) => id !== action.meta.arg
      );
    })
    .addCase(postGroup.pending, (state, action) => {
      const { duplicateId } = action.meta.arg;
      if (duplicateId && !state.loadingDuplicateIds.includes(duplicateId)) {
        state.loadingDuplicateIds.push(duplicateId);
      }
    })
    .addCase(postGroup.fulfilled, (state, action) => {
      const { duplicateId } = action.meta.arg;
      if (duplicateId && state.loadingDuplicateIds.includes(duplicateId)) {
        state.loadingDuplicateIds.splice(
          state.loadingDuplicateIds.indexOf(duplicateId),
          1
        );
      }

      adapter.upsertOne(state, action.payload);
    })
    .addCase(postGroup.rejected, (state, action) => {
      const { duplicateId } = action.meta.arg;
      if (duplicateId && state.loadingDuplicateIds.includes(duplicateId)) {
        state.loadingDuplicateIds.splice(
          state.loadingDuplicateIds.indexOf(duplicateId),
          1
        );
      }
    })
    .addCase(putGroup.fulfilled, (state, action) => {
      adapter.upsertOne(state, action.payload);
    })
    .addCase(deleteGroup.pending, (state, action) => {
      state.loadingDeleteIds.push(action.meta.arg.groupId);
    })
    .addCase(deleteGroup.fulfilled, (state, action) => {
      adapter.removeOne(state, action.meta.arg.groupId);
      state.loadingDeleteIds = state.loadingDeleteIds.filter(
        (groupId) => groupId !== action.meta.arg.groupId
      );
    })
    .addCase(deleteGroup.rejected, (state, action) => {
      state.loadingDeleteIds = state.loadingDeleteIds.filter(
        (groupId) => groupId !== action.meta.arg.groupId
      );
    })
    .addCase(deleteUser.fulfilled, (state, action) => {
      const { userId } = action.meta.arg;
      Object.values(state.entities).forEach((group) => {
        if (!group?.users.includes(userId)) {
          return;
        }

        group.users = group.users.filter((id) => id !== userId);
      });
    })
    .addCase(fetchGroupsByUserId.pending, (state, action) => {
      const userId = action.meta.arg;
      if (!state.loadingByUserIds.includes(userId)) {
        state.loadingByUserIds.push(userId);
      }
    })
    .addCase(fetchGroupsByUserId.fulfilled, (state, action) => {
      const userId = action.meta.arg;
      if (state.loadingByUserIds.includes(userId)) {
        state.loadingByUserIds.splice(
          state.loadingByUserIds.indexOf(userId),
          1
        );
      }

      adapter.upsertMany(state, action.payload);
    })
    .addCase(fetchGroupsByUserId.rejected, (state, action) => {
      const userId = action.meta.arg;
      if (state.loadingByUserIds.includes(userId)) {
        state.loadingByUserIds.splice(
          state.loadingByUserIds.indexOf(userId),
          1
        );
      }
    })
    .addCase(fetchUsersByGroupId.fulfilled, (state, action) => {
      adapter.updateOne(state, {
        id: action.meta.arg,
        changes: {
          users: action.payload.map(({ id }) => id),
        },
      });
    })
);
