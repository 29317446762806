import { useLayoutEffect, useRef } from 'react';

type Callback = () => Promise<unknown> | unknown | void;

/**
 * This function is used to call a function when the component unmounts.
 */
export function useOnUnmount(callback: Callback): void {
  const ref = useRef(callback);
  useLayoutEffect(() => {
    ref.current = callback;
  });

  useLayoutEffect(
    () => () => {
      ref.current();
    },
    []
  );
}
