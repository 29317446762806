import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

import { MenuItem, PlusCircleOutlineIcon } from 'fwi-fe-components';

import FormattedMessage from 'components/FormattedMessage';
import { ADMIN_USERS_NEW } from 'utils/routes';

export default function AddUser(): ReactElement {
  const history = useHistory();
  return (
    <MenuItem
      id="admin-options-add-user"
      icon={<PlusCircleOutlineIcon />}
      onClick={() => {
        history.push(ADMIN_USERS_NEW);
      }}
    >
      <FormattedMessage messageId="AddUser" />
    </MenuItem>
  );
}
