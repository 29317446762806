import { ReactElement, useEffect } from 'react';

import { CircularProgress } from 'fwi-fe-components';

import { logout } from 'utils/logout';

export default function Logout(): ReactElement {
  useEffect(() => {
    logout();
  }, []);

  return <CircularProgress id="logging-out" />;
}
