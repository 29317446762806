import { getCurrentUser, isCurrentUserAdmin } from 'appState/auth/selectors';
import {
  AlertModalState,
  AlertsState,
  AppState,
  CompanyAlert,
  ReadonlyEntityList,
  UserAlert,
} from 'appTypes';

import { adapter } from './schema';

const getAlertsState = (state: AppState): AlertsState => state.alerts;

export const {
  selectById: getAlertById,
  selectAll: getAlerts,
  selectEntities: getAlertEntities,
  selectIds: getAlertIds,
  selectTotal: getTotalAlerts,
} = adapter.getSelectors(getAlertsState);

/**
 * @returns true if fetching all the alerts for the company.
 */
export const isLoadingAlerts = (state: AppState): boolean =>
  getAlertsState(state).loading;

/**
 *
 * @returns a list of the current user's assigned alerts.
 */
export const getUserAlerts = (
  state: AppState
): ReadonlyEntityList<UserAlert> => {
  const userId = getCurrentUser(state)?.id;
  if (!userId) {
    return [];
  }

  return getAlerts(state).filter(
    (alert): alert is UserAlert =>
      !alert.isCompanyAlert && alert.isAlertActive && alert.userId === userId
  );
};

/**
 *
 * @returns a list of all the company alerts if the user is an administrator.
 */
export const getCompanyAlerts = (
  state: AppState
): ReadonlyEntityList<CompanyAlert> => {
  if (!isCurrentUserAdmin(state)) {
    return [];
  }

  return getAlerts(state).filter(
    (alert): alert is CompanyAlert =>
      alert.isAlertActive && alert.isCompanyAlert
  );
};

/**
 * Gets the parts required for displaying the alert modal.
 *
 * @returns {@link AlertModalState}
 */
export const getAlertModalState = (
  state: AppState
): Readonly<AlertModalState> => getAlertsState(state).modal;
