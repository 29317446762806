import { AppState, ChannelsState } from 'appTypes';

import { adapter } from './schema';

const getChannelsState = (state: AppState): ChannelsState => state.channels;

export const {
  selectById: getChannelById,
  selectAll: getChannels,
  selectEntities: getChannelEntities,
  selectIds: getChannelIds,
  selectTotal: getTotalChannels,
} = adapter.getSelectors(getChannelsState);
