import { AppSizeState, AppState } from 'appTypes';

export const getAppSize = (state: AppState): AppSizeState => state.appSize;

/**
 * @returns true if the app is in mobile mode
 */
export const isMobile = (state: AppState): boolean => getAppSize(state).mobile;

/**
 * @returns true if the app is in desktop mode
 */
export const isDesktop = (state: AppState): boolean =>
  getAppSize(state).desktop;
