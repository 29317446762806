import cn from 'classnames';
import type { ReactElement } from 'react';

import {
  FormattedMessage,
  MenuArrowDownIcon,
  UnifiedUserProfileIcon,
} from 'fwi-fe-components';

import styles from './MyHarmony.module.scss';

interface MyHarmonyProps {
  mobile: boolean;
}

export function MyHarmony({ mobile }: MyHarmonyProps): ReactElement {
  return (
    <>
      <UnifiedUserProfileIcon className={styles.userIcon} />
      <FormattedMessage messageId="MyHarmony" />
      <MenuArrowDownIcon
        className={cn(styles.dropdownIcon, {
          [styles.dropdownIcon__mobile]: mobile,
        })}
      />
    </>
  );
}
