import { EntityId, ValuesOf } from 'fwi-fe-types';

export const EntityAction = {
  // customers specific
  Add: 'add',
  Edit: 'edit',

  // users/groups
  ViewPermissions: 'permissions',
  ViewGroups: 'groups',
  ViewUsers: 'users',

  // others
  New: 'new',
  Duplicate: 'duplicate',
} as const;
export type EntityAction = ValuesOf<typeof EntityAction>;

/**
 * This is a simple helper that creates a lookup for entities by specific id.
 * This should normally be used when creating the type information for different
 * entity states or just the {@link EntitiesState} type.
 */
export type EntityRecord<E> = Record<EntityId, E>;

/**
 * A simple helper that creats a deep-readonly list mostly used for entities.
 * This should normally be used within selector files.
 */
export type ReadonlyEntityList<E> = readonly Readonly<E>[];

/**
 * A simple helper that creates a deep-readonly record of entities. This should
 * normally be used within selector files.
 */
export type ReadonlyEntityRecord<E> = Readonly<EntityRecord<Readonly<E>>>;

/**
 * This is a helper type for creating a normalized version of an entity.
 *
 * @example
 * Simple Example
 * ```ts
 * type NormalizedLabelEntity = NormalizeEntity<LabelEntity, 'values'>;
 * type NormalizedTableEntity = NormalizeEntity<TableEntity, 'columns' | 'rows'>;
 * ```
 *
 * @typeParam E - The entity to normalize
 * @typeParam K - A union of keys that are references to another entity
 */
export type NormalizeEntity<E, K extends keyof E> = Omit<E, K> & {
  [key in K]: EntityId[];
};

/**
 * A utility type to add strict type-checking to props for components when a
 * group of props must be provided together to work correctly, but the props are
 * optional.
 *
 * @example
 * All Sortable Props Required
 * ```ts
 * interface SortableProps extends SortState {
 *   onSortChange(sort: string, sortOrder: SortOrder): void
 * }
 *
 * type ExampleProps = HTMLAttributes<HTMLDivElement>
 *   & AllOfOrNever<SortableProps>
 * function Example(props: ExampleProps) {
 *   // implementation
 * }
 *
 *
 * <Example />
 * // Okay! No sort props were provided
 *
 * <Example sort="name" />
 * // ^ Type Error! Missing `sortOrder`, `isSorted`, and `onSortChange`
 *
 * <Example sort="name" sortOrder={SortOrder.ASCENDING} />
 * // ^ Type Error! Missing `isSorted` and `onSortChange`
 * ```
 */
export type AllOfOrNever<T> = T | { [key in keyof T]?: never };

export interface IdNameReference {
  id: EntityId;
  name: string;
}
