import { ReactElement, useCallback } from 'react';

import { SortOrder } from 'fwi-fe-types';

import { fetchCustomers, useAppDispatch } from 'appState';
import { useCustomerStatus } from 'hooks';

import AddCustomer, { AddCustomerProps } from './AddCustomer';
import CustomersSearch from './CustomersSearch';
import SortViewToggle from './SortViewToggle';

export default function CustomersActions({
  id,
  isNoContent,
}: AddCustomerProps): ReactElement | null {
  const dispatch = useAppDispatch();
  const status = useCustomerStatus(true);
  const onSortChange = useCallback(
    (sortBy: string, sortOrder: SortOrder) => {
      /* istanbul ignore next: unreachable because of the return statement */
      if (!status) {
        return;
      }

      dispatch(fetchCustomers({ status, sortBy, sortOrder }));
    },
    [dispatch, status]
  );

  if (!status) {
    return null;
  }

  if (isNoContent) {
    return <AddCustomer id={id} isNoContent={isNoContent} />;
  }

  return (
    <>
      <SortViewToggle onSortChange={onSortChange} />
      <CustomersSearch />
      <AddCustomer id={id} isNoContent={isNoContent} />
    </>
  );
}
