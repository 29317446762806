import { AnyAction } from '@reduxjs/toolkit';
import { getLocation, replace } from 'connected-react-router';
import { fork, put, select, takeLatest } from 'redux-saga/effects';

import { addToast, deleteLabels } from 'appState';
import { ADMIN_LABELS } from 'utils/routes';

export function* watchLabelDeleteSuccesses() {
  yield takeLatest(
    [deleteLabels.fulfilled.type, deleteLabels.rejected.type],
    function* toastLabelDeleteSuccess(
      action: ReturnType<
        typeof deleteLabels.fulfilled | typeof deleteLabels.rejected
      >
    ) {
      if (
        deleteLabels.fulfilled.match(action) &&
        action.payload.removedLabelIds.length > 0
      ) {
        const count = action.payload.removedLabelIds.length;
        yield put(
          addToast({
            messageId: 'DeleteLabelsSuccess',
            messageValues: { count },
          })
        );
      } else {
        yield put(addToast({ messageId: 'DeleteLabelsFailure' }));
      }
    }
  );
}

export function* redirectOnDelete() {
  yield takeLatest(
    [deleteLabels.fulfilled.type],
    function* execute(action: AnyAction) {
      const labelIds = action.meta.arg;
      const pathname: string = yield select(
        (state) => getLocation(state).pathname
      );
      if (pathname === `${ADMIN_LABELS}/${labelIds[0]}`) {
        yield put(replace(ADMIN_LABELS));
      }
    }
  );
}

export function* labelsSagas() {
  yield fork(watchLabelDeleteSuccesses);
  yield fork(redirectOnDelete);
}
