import { difference } from 'lodash';

type Item = string | number | boolean | symbol | null | bigint | undefined;
/**
 * Compares two arrays and returns if they contain the same values
 * Order insensitive. Does not work for arrays of objects. Only arrays of primitives.
 */
export function isArrayEqual(
  firstArray: Item[] = [],
  secondArray: Item[] = []
): boolean {
  const differences =
    difference(firstArray, secondArray).length ||
    difference(secondArray, firstArray).length;
  return !differences;
}
