import { UserEntity } from 'fwi-fe-types';

import { PaginationState } from 'appTypes';
import { INITIAL_OFFSET_STATE } from 'constants/pagination';

export const resolveItemList = (
  { itemList, offset }: PaginationState['homeUsers'],
  items: UserEntity[]
): string[] => {
  // itemList changes for a general fetch
  if (offset === INITIAL_OFFSET_STATE) {
    return items.map((user: UserEntity) => user.id);
  }

  const itemsToAdd = items.reduce((list: string[], user: UserEntity) => {
    const { id } = user;
    if (!itemList.includes(id)) {
      list.push(id);
    }
    return list;
  }, []);

  return [...itemList, ...itemsToAdd];
};
