import {
  PayloadAction,
  createAction,
  createSlice,
  nanoid,
} from '@reduxjs/toolkit';

import { BaseToast, Toast, ToastState } from 'appTypes';

export const INITIAL_TOASTS_STATE: ToastState = [];

/**
 * Attempts to add a new toast to the queue. This is really the only toast
 * action creator that should be used throughout the app.
 *
 * @param toast - {@link BaseToast} data to provide
 */
export const addToast = createAction<BaseToast>('toasts/add');

/**
 * Action creator to reset the toast timer. This should not be used outside of
 * the sagas.
 */
export const resetToastTimer = createAction('toasts/reset');

const { actions, reducer } = createSlice({
  name: 'toasts',
  initialState: INITIAL_TOASTS_STATE,
  reducers: {
    queueToast: {
      reducer: (state, action: PayloadAction<Toast>) => {
        state.push(action.payload);
      },
      prepare: (toast: BaseToast) => {
        return { payload: { ...toast, key: nanoid() } };
      },
    },
    popToast: (state) => {
      state.shift();
    },
  },
});

export const { popToast, queueToast } = actions;

export default reducer;
