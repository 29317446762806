import { createReducer } from '@reduxjs/toolkit';

import { fetchChannel, fetchChannels } from 'appState/channels/api';
import { ChannelSchedulesState } from 'appTypes';

import { adapter } from './schema';

export const INITIAL_CHANNEL_SCHEDULES_STATE: ChannelSchedulesState =
  adapter.getInitialState();

export default createReducer(INITIAL_CHANNEL_SCHEDULES_STATE, (builder) =>
  builder
    .addCase(fetchChannels.fulfilled, (state, action) => {
      adapter.upsertMany(state, action.payload.channelSchedules);
    })
    .addCase(fetchChannel.fulfilled, (state, action) => {
      adapter.upsertMany(state, action.payload.channelSchedules);
    })
);
