import { ReactElement, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { SortOrder } from 'fwi-fe-types';

import {
  fetchHomeGroups,
  fetchHomeUsers,
  useAppDispatch,
  useAppSelector,
} from 'appState';
import { sortLabels } from 'appState/pagination/homeLabels';
import { INITIAL_OFFSET_STATE } from 'constants/pagination';
import { useSplitEnabled } from 'hooks/useSplit';
import { getHomeLibraryData } from 'selectors/pagination';
import { isAdminGroups, isAdminLabels } from 'utils/routes';

import AdminFilterButton from './AdminFilterButton';
import AdminNewButton from './AdminNewButton';
import AdminSearchButton from './AdminSearchButton';
import SortViewToggle from './SortViewToggle';

export interface AdminActionsProps {
  id: string;
  filterId: string;
}

export default function AdminActions({
  id,
  filterId,
}: AdminActionsProps): ReactElement {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const isGroupsRoute = isAdminGroups(pathname);
  const isLabelsRoute = isAdminLabels(pathname);

  const isBulkLabelsEnabled = useSplitEnabled('DS_ADMIN_BULK_LABELS');

  const filters = useAppSelector((state) => {
    if (isGroupsRoute) {
      return getHomeLibraryData(state, 'groups').filters;
    }
    if (isLabelsRoute) {
      return getHomeLibraryData(state, 'labels').filters;
    }
    return getHomeLibraryData(state, 'users').filters;
  });
  const onSortChange = useCallback(
    (sort: string, sortOrder: SortOrder) => {
      const payload = {
        ...filters,
        offset: INITIAL_OFFSET_STATE,
        sort,
        sortOrder,
      };
      if (isGroupsRoute) {
        dispatch(fetchHomeGroups(payload));
      } else if (isLabelsRoute) {
        dispatch(sortLabels(sort, sortOrder));
      } else {
        dispatch(fetchHomeUsers(payload));
      }
    },
    [dispatch, filters, isGroupsRoute, isLabelsRoute]
  );

  return (
    <>
      <SortViewToggle onSortChange={onSortChange} />
      <AdminFilterButton id={filterId} />
      {isBulkLabelsEnabled && <AdminSearchButton id={id} />}
      <AdminNewButton id={id} />
    </>
  );
}
