import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { getGraphQlEndpoint } from 'utils/auth';
import { emailAndFeedsTokenController } from 'utils/tokens';

import {
  GRAPHQL_AP_ENDPOINT,
  GRAPHQL_ENDPOINT,
  GRAPHQL_EU_ENDPOINT,
  GRAPHQL_US_ENDPOINT,
} from './env';

const httpLink = createHttpLink({
  uri: () =>
    getGraphQlEndpoint({
      region: emailAndFeedsTokenController.getFromStorage()?.token?.pop_region,
      action: GRAPHQL_ENDPOINT,
      usAction: GRAPHQL_US_ENDPOINT,
      apAction: GRAPHQL_AP_ENDPOINT,
      euAction: GRAPHQL_EU_ENDPOINT,
    }),
});

const authLink = setContext(async (_, { headers }) => {
  const { access_token } = await emailAndFeedsTokenController.getApiData();

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${access_token}`,
    },
  };
});

const links = [authLink, httpLink];
if (process.env.NODE_ENV !== 'production') {
  // only log errors in dev mode. we might want different error reporting
  // behavior for prod in the future
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      // eslint-disable-next-line no-console
      console.log('graphQLErrors:', graphQLErrors);
    }

    if (networkError) {
      // eslint-disable-next-line no-console
      console.log('networkError:', networkError);
    }
  });

  links.splice(1, 0, errorLink);
}

export const apolloClient = new ApolloClient({
  link: from(links),
  cache: new InMemoryCache(),
});
