import { ReactElement, ReactNode } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

import { messages } from 'messages';

const locale = 'en';

export interface IntlProviderProps {
  children: ReactNode;
}

export function IntlProvider({ children }: IntlProviderProps): ReactElement {
  return (
    <ReactIntlProvider
      locale={locale}
      messages={messages[locale]}
      textComponent="span"
    >
      {children}
    </ReactIntlProvider>
  );
}
