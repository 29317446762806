import { ReactElement } from 'react';

import AccountOptions from './AccountOptions';
import AlertsLink from './AlertsLink';
import ModuleNavigation from './ModuleNavigation';

export default function HeaderActions(): ReactElement {
  return (
    <>
      <AlertsLink />
      <ModuleNavigation />
      <AccountOptions />
    </>
  );
}
