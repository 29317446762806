import { AppState, ChannelShareQuotaState } from 'appTypes';

/**
 *
 * @param state - The top-level store state
 * @returns the {@link ChannelShareQuotaState}
 */

export const getChannelShareQuota = (
  state: AppState
): Readonly<ChannelShareQuotaState> => state.channelShareQuota;

/**
 *
 * @param state - The top-level store state
 * @returns `true` if the current quota information is loading
 */
export const isLoadingChannelShareQuota = (state: AppState): boolean =>
  getChannelShareQuota(state).loading;

/**
 *
 * @param state - The top-level store state
 * @returns the current number of shareable urls used.
 */
export const getChannelShareUrlsUsed = (state: AppState): number =>
  getChannelShareQuota(state).used;

/**
 *
 * @param state - The top-level store state
 * @returns the total number of shareable urls allowed as configured in
 * customer management.
 */
export const getChannelShareUrlsTotal = (state: AppState): number =>
  getChannelShareQuota(state).quota;

/**
 *
 * @param state - The top-level store state
 * @returns `true` if the current customer is paying for this feature by having
 * at least one url allowed in customer management.
 */
export const isChannelShareUrlCustomer = (state: AppState): boolean =>
  getChannelShareUrlsTotal(state) > 0;
