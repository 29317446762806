import cn from 'classnames';
import type { ReactElement } from 'react';

import { FormattedMessage } from 'fwi-fe-components';

import styles from './UnifiedMarketing.module.scss';
import marketingIllustration from './marketing-illustration.png';

export function UnifiedMarketing(): ReactElement {
  return (
    <div className={styles.container}>
      <h6 className={cn(styles.subtitle, styles.message)}>
        <FormattedMessage messageId="Login.MarketingSubtitle" />
      </h6>
      <h4 className={cn(styles.title, styles.message)}>
        <FormattedMessage messageId="Login.MarketingTitle" />
      </h4>
      <img src={marketingIllustration} alt="" className={styles.image} />
    </div>
  );
}
