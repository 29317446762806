import { isEqual } from 'lodash';
import {
  CallEffect,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';

import { Toast, ToastState } from 'appTypes';

import { addToast, popToast, queueToast, resetToastTimer } from './reducer';
import { getCurrentToast, getToasts } from './selectors';

const FIVE_SECONDS = 5 * 1000;

/**
 * This saga will dynamically add toasts to the queue or reset the toast timer
 * if the same type of toast is already being displayed.
 */
function* queueToasts() {
  yield takeEvery(
    addToast.type,
    function* handler({ payload }: ReturnType<typeof addToast>) {
      const toasts: ToastState = yield select(getToasts);

      // remove the `key` since it'll always be unique
      const index = toasts.findIndex(({ key, ...toast }) =>
        isEqual(toast, payload)
      );

      if (index === -1) {
        yield put(queueToast(payload));
        return;
      }

      if (index > 0) {
        return;
      }

      yield put(resetToastTimer());
    }
  );
}

/**
 * This function will prevent additional sagas from being called until a toast
 * has been removed from the queue.
 */
function* takeUntilRemoved(disableAutoHide = false) {
  interface RaceResult {
    timedout: CallEffect<boolean> | undefined;
    popped: ReturnType<typeof popToast>;
    reset: ReturnType<typeof resetToastTimer>;
  }

  while (true) {
    const result: RaceResult = yield race({
      timedout: disableAutoHide ? undefined : delay(FIVE_SECONDS),
      popped: take(popToast.type),
      reset: take(resetToastTimer.type),
    });

    if (result.timedout) {
      yield put(popToast());
      break;
    } else if (result.popped) {
      break;
    }
  }
}

/**
 * This saga will prevent additional sagas from being called until at least one
 * toast exists in the queue.
 */
function* takeUntilToastExists() {
  while (true) {
    const toast: Toast | undefined = yield select(getCurrentToast);
    if (toast) {
      return toast;
    }

    yield take(queueToast.type);
  }
}

function* dequeueToasts() {
  while (true) {
    const toast: Toast = yield takeUntilToastExists();
    yield takeUntilRemoved(toast.disableAutoHide);
  }
}

/**
 * The main toast queue saga that handles adding and removing toasts when
 * needed.
 */
export function* toastQueue() {
  yield fork(queueToasts);
  yield fork(dequeueToasts);
}
