import { ReactElement } from 'react';

import { Switch } from 'fwi-fe-components';

import FormattedMessage from 'components/FormattedMessage';

import styles from './UserAnalytics.module.scss';

export interface UserAnalyticsProps {
  checked: boolean;
  onChange(checked: boolean): void;
}

export default function UserAnalytics({
  checked,
  onChange,
}: UserAnalyticsProps): ReactElement {
  return (
    <>
      <Switch
        id="user-settings-analytics"
        name="analytics"
        label={
          <FormattedMessage
            messageId="AnalyticsUsageTitle"
            className={styles.title}
          />
        }
        labelBefore
        checked={checked}
        onChange={onChange}
      />
      <FormattedMessage
        id="user-settings-analytics-description"
        component="p"
        messageId="AnalyticsUsageDescription"
        className={styles.description}
      />
    </>
  );
}
