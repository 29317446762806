import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIStatusRejectionThunkConfig, EulaData, EulaS3Urls } from 'appTypes';
import {
  EULA_ACCEPTANCE_ENDPOINT,
  EULA_AGREEMENT_ENDPOINT,
} from 'constants/endpoints';
import { api } from 'utils/api';

/**
 * This API call is actually a combination of two requests:
 *
 * 1. fetch the eula urls for the environment
 * 2. fetch the `contentUrl` from S3 which displays the eula html page
 *
 * Once both requests have been successful, the `htmlContent` and `downloadUrl`
 * will be returned.
 */
export const fetchEulaContent = createAsyncThunk<
  EulaData,
  void,
  APIStatusRejectionThunkConfig
>('eula/content', async (_, { rejectWithValue }) => {
  const urlsResponse = await api(EULA_AGREEMENT_ENDPOINT, {
    checkStatus: false,
  });

  if (!urlsResponse.ok) {
    const { status, statusText } = urlsResponse;
    return rejectWithValue({ status, statusText });
  }

  const { contentUrl, downloadUrl }: EulaS3Urls = await urlsResponse.json();
  const contentResponse = await fetch(contentUrl);
  if (!contentResponse.ok) {
    const { status, statusText } = contentResponse;
    return rejectWithValue({ status, statusText });
  }

  const htmlContent = await contentResponse.text();

  return {
    htmlContent,
    downloadUrl,
  };
});

interface AcceptanceJson {
  signed: boolean;
  // Note: this will be omitted if the environment is misconfigured.
  version: number;
}

/**
 * API call to allow the current user to accept the EULA. Will return `true` on
 * success.
 */
export const acceptEula = createAsyncThunk<
  boolean,
  void,
  APIStatusRejectionThunkConfig
>('eula/accept', async (_, { rejectWithValue }) => {
  const response = await api(EULA_ACCEPTANCE_ENDPOINT, {
    method: 'POST',
  });

  if (!response.ok) {
    const { status, statusText } = response;
    return rejectWithValue({ status, statusText });
  }

  const json: AcceptanceJson = await response.json();
  return json.signed;
});
