import { createAsyncThunk } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import { stringify } from 'qs';

import { EntityId, GroupEntity, PaginationRequestOptions } from 'fwi-fe-types';
import {
  APIConstants,
  StatusCodes,
  createPaginationQueryString,
} from 'fwi-fe-utils';

import {
  APIRejectionThunkConfig,
  APIStatusRejection,
  APIStatusRejectionThunkConfig,
  AppDispatch,
  AppState,
  AppThunkConfig,
  EntityAction,
  SharedPaginationState,
  SharedSortable,
} from 'appTypes';
import {
  GROUPS_ENDPOINT,
  GROUPS_ID_ENDPOINT,
  USERS_GROUPS_ENDPOINT,
} from 'constants/endpoints';
import {
  GROUPS_INITIAL_STATE as DEFAULT_STATE,
  FILTER,
  HOME,
  MODAL,
  PaginationLocation,
} from 'constants/pagination';
import { api } from 'utils/api';

import { isLoadingGroup } from './selectors';
import { invalidateTags } from './utils';

const { POST, PUT, DELETE } = APIConstants;

export interface FetchGroupsArgs extends Partial<PaginationRequestOptions> {
  paginationState?: SharedPaginationState;
  paginationLocation?: PaginationLocation;
}

export interface GroupsResponseData {
  items: GroupEntity[];
  numberOfItems: number;
}

export interface FullGroupsResponse
  extends Partial<FetchGroupsArgs>,
    GroupsResponseData {}

export const getGroupsFetchOptions = (
  paginationState?:
    | SharedPaginationState
    | (SharedPaginationState & SharedSortable),
  fetchOptions?: FetchGroupsArgs
): Partial<PaginationRequestOptions> => {
  const cleanedOptions = {
    ...omit(fetchOptions, ['paginationState', 'paginationLocation']),
  };
  const { itemList, sort: stateCombinedSort } = {
    ...DEFAULT_STATE,
    ...paginationState,
  };
  const { sort: stateSort, sortOrder: stateSortOrder } = stateCombinedSort;

  const stateOptions = {
    offset: itemList.length,
    sort: stateSort,
    sortOrder: stateSortOrder,
    size: 22,
  };

  if (!fetchOptions) {
    return stateOptions;
  }

  return {
    ...stateOptions,
    ...cleanedOptions,
  };
};

export const fetchGroups = createAsyncThunk<
  FullGroupsResponse,
  FetchGroupsArgs
>('groups/fetch', async (options) => {
  const { paginationState } = options;
  const fetchOptions = getGroupsFetchOptions(paginationState, options);
  const fetchQuery = createPaginationQueryString(fetchOptions);

  const response = await api(`${GROUPS_ENDPOINT}?${fetchQuery}`);

  const json: GroupsResponseData = await response.json();
  const fullResponse: FullGroupsResponse = {
    ...fetchOptions,
    ...json,
  };

  return fullResponse;
});

export function fetchFilterGroups(options?: Partial<PaginationRequestOptions>) {
  return (dispatch: AppDispatch, getState: () => AppState) => {
    const { filterGroups } = getState().pagination;
    return dispatch(
      fetchGroups({
        ...options,
        paginationState: filterGroups,
        paginationLocation: FILTER,
      })
    );
  };
}

export function fetchHomeGroups(options?: Partial<PaginationRequestOptions>) {
  return (dispatch: AppDispatch, getState: () => AppState) => {
    const { homeGroups } = getState().pagination;
    return dispatch(
      fetchGroups({
        ...options,
        paginationState: homeGroups,
        paginationLocation: HOME,
      })
    );
  };
}

export function fetchModalGroups(options?: Partial<PaginationRequestOptions>) {
  return (dispatch: AppDispatch, getState: () => AppState) => {
    const { userModalGroups } = getState().pagination;
    return dispatch(
      fetchGroups({
        ...options,
        paginationState: userModalGroups,
        paginationLocation: MODAL,
      })
    );
  };
}

export const fetchGroupsByUserId = createAsyncThunk<
  GroupEntity[],
  EntityId,
  APIRejectionThunkConfig
>(
  'groups/byUserId',
  async (userId, { rejectWithValue }) => {
    const response = await api(USERS_GROUPS_ENDPOINT, {
      params: { userId },
    });
    if (!response.ok) {
      const { status } = response;
      return rejectWithValue({
        status,
        unmodified: status === StatusCodes.NOT_MODIFIED,
      });
    }

    const json: GroupEntity[] = await response.json();
    return json;
  },
  {
    condition(arg) {
      return arg !== EntityAction.New;
    },
  }
);

export interface GroupAPIRejection extends APIStatusRejection {
  message: string;
}

/**
 * Fetches a group by id
 *
 * @param arg - The group id to fetch
 * @returns a promise to the {@link GroupEntity}
 */
export const fetchGroup = createAsyncThunk<
  GroupEntity,
  EntityId,
  APIRejectionThunkConfig
>(
  'group/fetchById',
  async (groupId, { rejectWithValue }) => {
    const response = await api(GROUPS_ID_ENDPOINT, {
      params: { groupId },
    });

    if (!response.ok) {
      const { status } = response;
      return rejectWithValue({
        status,
        unmodified: status === StatusCodes.NOT_MODIFIED,
      });
    }

    return await response.json();
  },
  {
    condition(arg, { getState }) {
      return arg !== EntityAction.New && !isLoadingGroup(getState(), arg);
    },
  }
);

export interface PostGroupArgs {
  group: Partial<GroupEntity>;
  duplicateId?: EntityId;
  disableRedirect?: boolean;
}

export const postGroup = createAsyncThunk<
  GroupEntity,
  PostGroupArgs,
  AppThunkConfig<GroupAPIRejection>
>('group/post', async ({ group }, { dispatch, rejectWithValue }) => {
  const response = await api(GROUPS_ENDPOINT, {
    method: POST,
    body: JSON.stringify(group),
  });

  if (!response.ok) {
    const json = await response.json();
    const { status, statusText } = response;
    return rejectWithValue({ status, statusText, message: json.message });
  }

  invalidateTags({ dispatch, tags: ['Groups'] });
  return await response.json();
});

export interface PutGroupArgs {
  groupId: EntityId;
  group: Partial<GroupEntity>;
  disableRedirect?: boolean;
}

export const putGroup = createAsyncThunk<
  GroupEntity,
  PutGroupArgs,
  AppThunkConfig<GroupAPIRejection>
>('group/put', async ({ groupId, group }, { dispatch, rejectWithValue }) => {
  const response = await api(GROUPS_ID_ENDPOINT, {
    method: PUT,
    body: JSON.stringify(group),
    params: { groupId },
  });

  if (!response.ok) {
    const json = await response.json();
    const { status, statusText } = response;
    return rejectWithValue({ status, statusText, message: json.message });
  }

  invalidateTags({ dispatch, tags: ['Groups'] });
  return await response.json();
});

export interface DuplicateGroupArgs {
  groupId: string;
  original: GroupEntity;
  disableRedirect?: boolean;
}

export function duplicateGroup({
  groupId,
  original,
  disableRedirect = false,
}: DuplicateGroupArgs) {
  return (dispatch: AppDispatch) => {
    const { name, description, isDefault, users, permissions } = original;
    const body = {
      name,
      description,
      isDefault,
      users,
      permissions,
    };

    return dispatch(
      postGroup({ group: body, duplicateId: groupId, disableRedirect })
    );
  };
}

export interface DeleteGroupArgs {
  groupId: EntityId;
  disableRedirect?: boolean;
}

export const deleteGroup = createAsyncThunk<
  void,
  DeleteGroupArgs,
  AppThunkConfig
>('group/delete', async ({ groupId }, { dispatch, rejectWithValue }) => {
  const response = await api(GROUPS_ID_ENDPOINT, {
    method: DELETE,
    params: { groupId },
  });

  if (!response.ok) {
    const { status, statusText } = response;
    return rejectWithValue({ status, statusText });
  }

  invalidateTags({ dispatch, tags: ['Groups'] });
});

export const fetchDefaultGroups = createAsyncThunk<
  GroupsResponseData,
  void,
  APIStatusRejectionThunkConfig
>('groups/default/fetch', async (_, { rejectWithValue }) => {
  const query = stringify({
    isDefault: true,
    size: -1,
  });

  const response = await api(`${GROUPS_ENDPOINT}?${query}`);

  if (!response.ok) {
    const { status, statusText } = response;
    return rejectWithValue({ status, statusText });
  }

  return await response.json();
});
