import { ReactElement } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { isMissingAuthorization, useAppSelector } from 'appState';
import { AppModuleId } from 'appTypes';

import ForbiddenPage from './ForbiddenPage';

const NO_RESTRICTIONS = [] as const;

export interface ScopedRouteProps extends RouteProps {
  /**
   * Any additional scopes that must be applied before the user can navigate to
   * this route for their current company.
   */
  scopes?: readonly AppModuleId[];

  /**
   * Boolean if the `<ForbiddenPage />` should not be returned when the user is
   * forbidden to view this route. This is useful if the route is acting more as
   * a code splitter for some sort of functionally (like mqtt connections)
   *
   * @defaultValue `false`
   */
  disableForbiddenPage?: boolean;
}

export default function ScopedRoute({
  scopes = NO_RESTRICTIONS,
  disableForbiddenPage = false,
  ...props
}: ScopedRouteProps): ReactElement | null {
  const isAuthorized = useAppSelector(
    (state) => !isMissingAuthorization(state, scopes)
  );

  if (!isAuthorized) {
    return disableForbiddenPage ? null : <ForbiddenPage />;
  }

  return <Route {...props} />;
}
