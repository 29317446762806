import { ReactElement, ReactNode, Suspense } from 'react';

export interface NullSuspenseProps {
  children: ReactNode;
}

/**
 * A simple `Suspense` wrapper that defaults to rendering nothing while lazy
 * loading the children.
 */
export function NullSuspense({ children }: NullSuspenseProps): ReactElement {
  return <Suspense fallback={null}>{children}</Suspense>;
}
