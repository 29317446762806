import { ReactElement } from 'react';

import { getCurrentUser, useAppSelector } from 'appState';
import FormattedMessage from 'components/FormattedMessage';

import RightFixedDialog from './RightFixedDialog';
import UserSettingsContent from './UserSettingsContent';

const id = 'user-settings-dialog';

export interface UserSettingsDialogProps {
  visible: boolean;
  className?: string;
  onRequestClose(): void;
}

export default function UserSettingsDialog({
  visible,
  className,
  onRequestClose,
}: UserSettingsDialogProps): ReactElement {
  const checked = useAppSelector(
    (state) => getCurrentUser(state)?.isTrackAnalyticsAllowed
  );

  return (
    <RightFixedDialog
      id={id}
      visible={visible}
      onRequestClose={onRequestClose}
      title={<FormattedMessage messageId="MySettings" />}
      className={className}
    >
      {typeof checked === 'boolean' && (
        <UserSettingsContent
          dialogId={id}
          defaultChecked={checked}
          onRequestClose={onRequestClose}
        />
      )}
    </RightFixedDialog>
  );
}
