import { ReactElement } from 'react';

import LoadingMenuItem from 'components/LoadingMenuItem';
import { useChangeCompany } from 'hooks/useChangeCompany';

import CompanyMenuItem from './CompanyMenuItem';

export interface CompaniesProps {
  loadingId: string;
  accountIdPrefix: string;
}

export default function Companies({
  loadingId,
  accountIdPrefix,
}: CompaniesProps): ReactElement {
  const { loading, companies, companyId, handleCompanyChange } =
    useChangeCompany();

  return (
    <>
      {loading && <LoadingMenuItem id={loadingId} />}
      {!loading &&
        companies.map(({ id, name }, index) => (
          <CompanyMenuItem
            id={`${accountIdPrefix}-${index + 1}`}
            key={id}
            companyId={id}
            name={name}
            checked={companyId === id}
            onCompanyChange={handleCompanyChange}
          />
        ))}
    </>
  );
}
