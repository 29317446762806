import { createReducer } from '@reduxjs/toolkit';

import { GroupEntity } from 'fwi-fe-types';

import {
  GROUPS_INITIAL_STATE,
  HOME,
  INITIAL_LOADING_STATE,
  INITIAL_OFFSET_STATE,
} from 'constants/pagination';

import { deleteGroup, fetchGroups, postGroup } from '../groups';

export default createReducer(GROUPS_INITIAL_STATE, (builder) => {
  builder.addCase(fetchGroups.pending, (state, action) => {
    const { paginationLocation, offset } = action.meta.arg;

    if (paginationLocation !== HOME) {
      return;
    }
    state.loading = !INITIAL_LOADING_STATE;

    if (offset === INITIAL_OFFSET_STATE) {
      state.offset = INITIAL_OFFSET_STATE;
      state.itemList = [];
    }
  });

  builder.addCase(fetchGroups.fulfilled, (state, action) => {
    if (action.meta.arg.paginationLocation !== HOME) {
      return;
    }

    const { payload } = action;
    const { numberOfItems, items, offset, sort, sortOrder } = payload;

    state.offset = offset ?? INITIAL_OFFSET_STATE;
    state.loading = INITIAL_LOADING_STATE;

    state.numberOfItems = numberOfItems;

    // itemList changes for a general fetch
    if (state.offset === INITIAL_OFFSET_STATE) {
      state.itemList = items.map((user: GroupEntity) => user.id);
    } else {
      const itemsToAdd = items.reduce((list: string[], user: GroupEntity) => {
        const { id } = user;
        if (!state.itemList.includes(id)) {
          list.push(id);
        }
        return list;
      }, []);

      state.itemList = [...state.itemList, ...itemsToAdd];
    }

    const sortState = {
      sort: sort || state.sort.sort,
      sortOrder: sortOrder || state.sort.sortOrder,
      isSorted: offset === 0 || state.sort.isSorted,
    };
    state.sort = sortState;
  });

  builder.addCase(fetchGroups.rejected, (state, action) => {
    if (action.meta.arg.paginationLocation !== HOME) {
      return;
    }
    state.loading = INITIAL_LOADING_STATE;
  });

  builder.addCase(postGroup.fulfilled, (state, action) => {
    const { payload } = action;
    const { id } = payload || {};

    if (id) {
      state.itemList = [id, ...state.itemList];
      state.sort.isSorted = false;
      state.numberOfItems += 1;
    }
  });

  builder.addCase(
    deleteGroup.fulfilled,
    (
      state,
      {
        meta: {
          arg: { groupId },
        },
      }
    ) => {
      if (groupId && state.itemList.includes(groupId)) {
        state.itemList = state.itemList.filter((item) => item !== groupId);
        state.numberOfItems -= 1;
      }
    }
  );
});
