import { put, select, take } from 'redux-saga/effects';

import { CurrentUserEntity } from 'fwi-fe-types';
import {
  analytics,
  getCompanyId,
  identifyCustomer,
  identifyUser,
} from 'fwi-fe-utils';

import { fetchCurrentUser, patchCurrentUser } from 'appState';
import { authenticated, getCurrentUser } from 'appState/auth';

/**
 * This saga will block starting other sagas until the user allows analytics.
 */
export function* waitForAnalyticsAllowed() {
  type TakeResult = ReturnType<
    | typeof fetchCurrentUser.fulfilled
    | typeof patchCurrentUser.fulfilled
    | typeof authenticated
  >;

  while (true) {
    const action: TakeResult = yield take([
      fetchCurrentUser.fulfilled.type,
      patchCurrentUser.fulfilled.type,
      authenticated.type,
    ]);
    let isTrackAnalyticsAllowed = false;
    if (fetchCurrentUser.fulfilled.match(action)) {
      ({ isTrackAnalyticsAllowed } = action.payload);
    } else if (patchCurrentUser.fulfilled.match(action)) {
      ({ isTrackAnalyticsAllowed } = action.meta.arg);
    } else if (authenticated.match(action) && action.payload === 'done') {
      const user: CurrentUserEntity | undefined = yield select(getCurrentUser);
      isTrackAnalyticsAllowed = user?.isTrackAnalyticsAllowed ?? false;
    }

    // a `companyId` must be set before loading analytics so make sure we don't
    // try to load on the `/login/company` page
    if (getCompanyId() && isTrackAnalyticsAllowed) {
      break;
    }
  }
}

interface LoadAnalyticsOptions {
  segmentCode?: string;
  disableSegment?: boolean;
}

/**
 * This saga will load our analytics only if the user has specified analytics
 * are allowed.
 */
export function* loadAnalytics({
  segmentCode,
  disableSegment,
}: LoadAnalyticsOptions = {}) {
  if (disableSegment || !segmentCode) {
    return;
  }

  yield waitForAnalyticsAllowed();
  analytics.load({ writeKey: segmentCode });

  const user: CurrentUserEntity = yield select(getCurrentUser);
  const {
    email,
    licenseType,
    permissions,
    companies,
    firstName,
    lastName,
    companyId,
  } = user;
  yield put(
    identifyUser({
      email,
      licenseType,
      permissions,
      firstName,
      lastName,
    })
  );

  const salesforceAccountNumber = companies.find(
    (company) => company.id === companyId
  )?.sfAccountId;
  if (salesforceAccountNumber) {
    yield put(identifyCustomer(salesforceAccountNumber));
  }
}
