import type { EntityId } from 'fwi-fe-types';

import type {
  AppState,
  LabelValueEntity,
  LabelValuesState,
  ReadonlyEntityList,
} from 'appTypes';

import { adapter } from './schema';

const getLabelValuesState = (state: AppState): LabelValuesState =>
  state.labelValues;

export const {
  selectById: getLabelValueById,
  selectAll: getLabelValues,
  selectEntities: getLabelValueEntities,
  selectIds: getLabelValuesIds,
  selectTotal: getTotalLabelValues,
} = adapter.getSelectors(getLabelValuesState);

/**
 *
 * @param state - The top-level store state
 * @param labelValueIds - The list of label value ids to get names for
 * @returns a list of label values for each value found in state
 */
export const getLabelValuesByLabelIds = (
  state: AppState,
  labelValueIds: readonly EntityId[]
): ReadonlyEntityList<LabelValueEntity> =>
  labelValueIds.reduce<LabelValueEntity[]>((list, valueId) => {
    const value = getLabelValueById(state, valueId);
    if (value) {
      list.push(value);
    }

    return list;
  }, []);

/**
 *
 * @param state - The top-level store state
 * @param labelValueIds - The list of label value ids to get names for
 * @returns a list of label value names for each value found in state
 */
export const getLabelValueNames = (
  state: AppState,
  labelValueIds: readonly EntityId[]
): readonly string[] =>
  labelValueIds.reduce<string[]>((result, id) => {
    const name = getLabelValueById(state, id)?.name;
    if (name) {
      result.push(name);
    }

    return result;
  }, []);

/**
 *
 * @param state - The top-level store state
 * @param labelValueIds - The list of label value ids to get entities for
 * @returns a list sorted by description of label value entities for each value found in state
 */
export const getLabelValuesByIds = (
  state: AppState,
  labelValueIds: readonly EntityId[]
): readonly LabelValueEntity[] => {
  return labelValueIds.reduce<LabelValueEntity[]>((result, id) => {
    const entity = getLabelValueById(state, id);
    if (entity) {
      result.push(entity);
    }

    return result;
  }, []);
};
