import { AnyAction, Dispatch, ThunkDispatch } from '@reduxjs/toolkit';
import { RouterState } from 'connected-react-router';
import { LocationState } from 'history';

import { EntityId, ValuesOf } from 'fwi-fe-types';

import {
  CompanySettings,
  LicenseQuotasState,
  PaginationState,
  SearchFiltersState,
} from './admin';
import { AlertsState } from './alerts';
import { AppSizeState } from './appSize';
import { AuthState } from './auth';
import { BulkUserOpsState } from './bulkUserOps';
import {
  ChannelContentItemsState,
  ChannelInterruptsState,
  ChannelSchedulesState,
  ChannelShareQuotaState,
  ChannelsState,
} from './channels';
import { CustomersState } from './customers';
import { DriveState } from './drive';
import { ErrorState } from './errorCode';
import { EulaState } from './eula';
import { FoldersState } from './folders';
import { GroupsState } from './groups';
import { InfoModalState } from './infoModal';
import { LabelValuesState, LabelsState } from './labels';
import { MarketSolutionsState } from './marketSolutions';
import { PropertiesState } from './properties';
import { ToastState } from './toasts';
import { UsersState } from './users';

export interface SelectionsState {
  entities: EntityId[];
}

export const ReportingModuleStage = {
  NotActivatedScreen: 0,
  CongratsScreen: 1,
  Unavailable: 2,
} as const;
export type ReportingModuleStage = ValuesOf<typeof ReportingModuleStage>;

export interface CompanySettingsState {
  loading: boolean;
  settings: CompanySettings | null;
  reportingModuleStage: ReportingModuleStage;
}

export interface AppState {
  // This should eventually be inferred by the `createStore` call since it is generated
  // by RTK Query.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  api: any;

  alerts: AlertsState;
  appSize: AppSizeState;
  auth: AuthState;
  bulkUserOps: BulkUserOpsState;
  channelContentItems: ChannelContentItemsState;
  channelInterrupts: ChannelInterruptsState;
  channelSchedules: ChannelSchedulesState;
  channels: ChannelsState;
  channelShareQuota: ChannelShareQuotaState;
  companySettings: CompanySettingsState;
  customers: CustomersState;
  drive: DriveState;
  errorCode: ErrorState;
  eula: EulaState;
  folders: FoldersState;
  groups: GroupsState;
  infoModal: InfoModalState;
  labelValues: LabelValuesState;
  labels: LabelsState;
  licenseQuotas: LicenseQuotasState;
  properties: PropertiesState;
  marketSolutions: MarketSolutionsState;
  router: RouterState<LocationState>;
  searchFilters: SearchFiltersState;
  selections: SelectionsState;
  toasts: ToastState;
  users: UsersState;

  // admin
  pagination: PaginationState;
}

export type AppDispatch = ThunkDispatch<AppState, {}, AnyAction> &
  Dispatch<AnyAction>;

/**
 * This type should be used with the `createAsyncThunk` action from
 * `@reduxjs/toolkit` for the `ThunkApiConfig` typeparam.
 *
 * @example
 * Simple Example
 * ```ts
 * export const myApi = createAsyncThunk<
 *   Returned,
 *   ArgType,
 *   AppThunkConfig
 * >('my/api', async (arg, { dispatch, getState }) => {
 *   // do async stuff with strongly typed `dispatch` and `getState`
 * });
 * ```
 */
export interface AppThunkConfig<RejectValue = unknown> {
  state: AppState;
  dispatch: AppDispatch;
  rejectValue: RejectValue;
}
