import { MouseEvent, ReactElement } from 'react';

import { MenuItem, UserProfileIcon } from 'fwi-fe-components';

import FormattedMessage from 'components/FormattedMessage';

export interface UserSettingsMenuItemProps {
  id: string;
  onClick(event: MouseEvent<HTMLLIElement>): void;
}

export default function UserSettingsMenuItem({
  id,
  onClick,
}: UserSettingsMenuItemProps): ReactElement {
  return (
    <MenuItem id={id} onClick={onClick} icon={<UserProfileIcon />}>
      <FormattedMessage messageId="MySettings" />
    </MenuItem>
  );
}
