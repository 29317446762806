import { getLocation, getSearch, push, replace } from 'connected-react-router';
import { parse } from 'qs';
import { fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  addToast,
  deleteUser,
  fetchGroupsByUserId,
  fetchHomeUsers,
  postBulkUserUploadCsv,
  postUser,
  putUser,
  resendActivationLink,
  sendPasswordResetLink,
  showInfoModal,
  unlockUserAccount,
} from 'appState';
import { PaginationState } from 'appTypes';
import { AUTHOR, CONTRIBUTOR, VIEWER } from 'constants/licenseTypes';
import { ADMIN_USERS, qsToString } from 'utils/routes';
import { capitalizeFirst } from 'utils/strings';

export function* redirectOnPost() {
  yield takeLatest(
    [postUser.fulfilled.type, putUser.fulfilled.type],
    function* execute(
      action: ReturnType<typeof postUser.fulfilled | typeof putUser.fulfilled>
    ) {
      if (action.meta.arg.disableRedirect) {
        return;
      }

      const search: string = yield select(getSearch);
      const from = qsToString(parse(search).from);
      yield put(push(from || ADMIN_USERS));
    }
  );
}

export function* redirectOnDelete() {
  yield takeLatest(
    deleteUser.fulfilled.type,
    function* execute(action: ReturnType<typeof deleteUser.fulfilled>) {
      const { disableRedirect, userId } = action.meta.arg;
      const pathname: string = yield select(
        (state) => getLocation(state).pathname
      );
      if (!disableRedirect && pathname === `${ADMIN_USERS}/${userId}`) {
        yield put(replace(ADMIN_USERS));
      }
    }
  );
}

export function* getGroupsOnPut() {
  yield takeLatest(
    putUser.fulfilled.type,
    function* execute(action: ReturnType<typeof putUser.fulfilled>) {
      yield put(fetchGroupsByUserId(action.meta.arg.userId));
    }
  );
}

export function* fetchNextUserOnUserDeletion() {
  yield takeEvery(
    deleteUser.fulfilled.type,
    function* execute(action: ReturnType<typeof deleteUser.fulfilled>) {
      if (!action.meta.arg.userId) {
        return;
      }

      const state: PaginationState['homeUsers'] = yield select(
        (state) => state.pagination.homeUsers
      );
      const { sort, sortOrder } = state.sort;
      const offset = state.itemList.length;
      if (state.numberOfItems === offset) {
        return;
      }
      yield put(fetchHomeUsers({ offset, sort, sortOrder, size: 1 }));
    }
  );
}

export function* showUploadUsersSuccess() {
  yield takeEvery(postBulkUserUploadCsv.fulfilled.type, function* execute() {
    yield put(
      showInfoModal({
        titleId: 'BulkUploadSuccessTitle',
        content: { id: 'BulkUploadSuccess' },
      })
    );
  });
}

// TODO: These helpers should eventually be replaced by backend sending us error codes that map to messages along with
// data to parameterize the messages.
function getLicenseTypeForDisplay(message: string): string {
  let licenseType;
  if (message.includes(AUTHOR)) {
    licenseType = AUTHOR;
  } else if (message.includes(CONTRIBUTOR)) {
    licenseType = CONTRIBUTOR;
  } else {
    licenseType = VIEWER;
  }
  return capitalizeFirst(licenseType.toLowerCase());
}

function isLicenseQuotaFailure(message: string): boolean {
  return message.toLowerCase().includes('license');
}

export function* watchUserPostSuccesses() {
  yield takeLatest(postUser.fulfilled.type, function* toastUserPostSuccess() {
    yield put(addToast({ messageId: 'PostUserSuccess' }));
  });
}

export function* watchUserPostFailures() {
  yield takeLatest(
    postUser.rejected.type,
    function* toastUserPostFailure(
      action: ReturnType<typeof postUser.rejected>
    ) {
      const status = action.payload?.status;
      const message = action.payload?.message ?? '';
      if (status === 409) {
        yield put(addToast({ messageId: 'UserFailure409' }));
      } else if (isLicenseQuotaFailure(message)) {
        yield put(
          showInfoModal({
            titleId: 'CreateNewUserErrorTitle',
            content: {
              id: 'LicenseQuotaMet',
              values: {
                licenseType: getLicenseTypeForDisplay(message),
              },
            },
          })
        );
      } else {
        yield put(addToast({ messageId: 'PostUserFailure' }));
      }
    }
  );
}

export function* watchUserPutSuccesses() {
  yield takeLatest(putUser.fulfilled.type, function* toastUserPutSuccess() {
    yield put(addToast({ messageId: 'PutUserSuccess' }));
  });
}

export function* watchUserPutFailures() {
  yield takeLatest(
    putUser.rejected.type,
    function* toastUserPutFailure(action: ReturnType<typeof putUser.rejected>) {
      const status = action.payload?.status;
      const message = action.payload?.message ?? '';
      if (status === 404) {
        yield put(addToast({ messageId: 'UserFailure404' }));
      } else if (status === 409) {
        yield put(addToast({ messageId: 'UserFailure409' }));
      } else if (isLicenseQuotaFailure(message)) {
        yield put(
          showInfoModal({
            titleId: 'UpdateUserErrorTitle',
            content: {
              id: 'LicenseQuotaMet',
              values: {
                licenseType: getLicenseTypeForDisplay(message),
              },
            },
          })
        );
      } else {
        yield put(addToast({ messageId: 'PutUserFailure' }));
      }
    }
  );
}

export function* watchUserDeleteSuccesses() {
  yield takeLatest(
    deleteUser.fulfilled.type,
    function* toastUserDeleteSuccess() {
      yield put(addToast({ messageId: 'DeleteUserSuccess' }));
    }
  );
}

export function* watchUserDeleteFailures() {
  yield takeLatest(
    deleteUser.rejected.type,
    function* toastUserDeleteFailure(
      action: ReturnType<typeof deleteUser.rejected>
    ) {
      if (action.payload?.status === 404) {
        yield put(addToast({ messageId: 'UserFailure404' }));
      } else {
        yield put(addToast({ messageId: 'DeleteUserFailure' }));
      }
    }
  );
}

export function* watchResendActivationLinkSuccesses() {
  yield takeLatest(
    resendActivationLink.fulfilled.type,
    function* toastResendActivationLinkSuccess() {
      yield put(addToast({ messageId: 'ResendActivationLinkSuccess' }));
    }
  );
}

export function* watchResendActivationLinkFailures() {
  yield takeLatest(
    resendActivationLink.rejected.type,
    function* toastResendActivationLinkFailure(
      action: ReturnType<typeof resendActivationLink.rejected>
    ) {
      if (action.payload?.status === 404) {
        yield put(addToast({ messageId: 'UserFailure404' }));
      } else {
        yield put(addToast({ messageId: 'ResendActivationLinkFailure' }));
      }
    }
  );
}

export function* watchSendPasswordResetLinkSuccesses() {
  yield takeLatest(
    sendPasswordResetLink.fulfilled.type,
    function* toastSendPasswordResetLinkSuccess() {
      yield put(addToast({ messageId: 'SendPasswordResetLinkSuccess' }));
    }
  );
}

export function* watchSendPasswordResetLinkFailures() {
  yield takeLatest(
    sendPasswordResetLink.rejected.type,
    function* toastSendPasswordResetLinkFailure(
      action: ReturnType<typeof sendPasswordResetLink.rejected>
    ) {
      if (action.payload?.status === 404) {
        yield put(addToast({ messageId: 'UserFailure404' }));
      } else {
        yield put(addToast({ messageId: 'SendPasswordResetLinkFailure' }));
      }
    }
  );
}

export function* watchUnlockUserAccountSuccesses() {
  yield takeLatest(
    unlockUserAccount.fulfilled.type,
    function* toastUnlockUserAccountSuccess() {
      yield put(addToast({ messageId: 'UnlockUserAccountSuccess' }));
    }
  );
}

export function* watchUnlockUserAccountFailures() {
  yield takeLatest(
    unlockUserAccount.rejected.type,
    function* toastUnlockUserAccountFailure(
      action: ReturnType<typeof unlockUserAccount.rejected>
    ) {
      if (action.payload?.status === 404) {
        yield put(addToast({ messageId: 'UserFailure404' }));
      } else {
        yield put(addToast({ messageId: 'UnlockUserAccountFailure' }));
      }
    }
  );
}

export function* usersSagas() {
  yield fork(redirectOnPost);
  yield fork(redirectOnDelete);
  yield fork(getGroupsOnPut);
  yield fork(fetchNextUserOnUserDeletion);
  yield fork(showUploadUsersSuccess);
  yield fork(watchUserPostSuccesses);
  yield fork(watchUserPostFailures);
  yield fork(watchUserPutSuccesses);
  yield fork(watchUserPutFailures);
  yield fork(watchUserDeleteSuccesses);
  yield fork(watchUserDeleteFailures);
  yield fork(watchResendActivationLinkSuccesses);
  yield fork(watchResendActivationLinkFailures);
  yield fork(watchSendPasswordResetLinkSuccesses);
  yield fork(watchSendPasswordResetLinkFailures);
  yield fork(watchUnlockUserAccountSuccesses);
  yield fork(watchUnlockUserAccountFailures);
}
