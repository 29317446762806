import { ParsedQs, parse } from 'qs';

import { SYS_NAME_KEYS } from 'fwi-constants';
import { EntityId } from 'fwi-fe-types';

import { CustomerStatus } from 'appTypes';
import { FE_ORIGIN, REPORTS_IFRAME_BASE_URL } from 'constants/env';

// Internal App Routes
export const HOME = '/';
export const LOGIN = '/login';
export const EULA = `${LOGIN}/eula`;
export const COMPANY = `${LOGIN}/company`;
export const OKTA_LOGIN = `${LOGIN}/okta`;
export const LOGIN_CALLBACK = `${LOGIN}/callback`;
export const LOGOUT = '/logout';
export const LOGOUT_OKTA = '/logout-okta';
export const DASHBOARD = '/dashboard';

export const ADMIN = '/admin';
export const ADMIN_USERS = '/admin/users';
export const ADMIN_USERS_ID = `${ADMIN_USERS}/:userId`;
export const ADMIN_USERS_NEW = `${ADMIN_USERS}/new`;
export const ADMIN_GROUPS = '/admin/groups';
export const ADMIN_GROUPS_NEW = `${ADMIN_GROUPS}/new`;
export const ADMIN_GROUPS_ID = `${ADMIN_GROUPS}/:groupId`;
export const ADMIN_LABELS = '/admin/labels';
export const ADMIN_LABELS_NEW = `${ADMIN_LABELS}/new`;
export const ADMIN_LABELS_ID = `${ADMIN_LABELS}/:labelId`;
export const ADMIN_SETTINGS = '/admin/settings';
export const ADMIN_SUPER_MODAL =
  '/admin/:entityType(user|group|label)s/:entityId';

export const ALERTS = '/alerts';

export const REPORTING = '/reporting';
export const REPORTING_DEVICES_DASHBOARD = 'devices';
export const REPORTING_PLAYLISTS_DASHBOARD = 'playlists';
export const REPORTING_CONTENT_ITEMS_DASHBOARD = 'content-items';
export const REPORTING_APPS_DASHBOARD = 'apps';
export const REPORTING_CHANNELS_DASHBOARD = 'channels';
export const REPORTING_DEFAULT_DASHBOARD = REPORTING_DEVICES_DASHBOARD;
export const REPORTING_PATH = `${REPORTING}/:dashboard(${REPORTING_DEVICES_DASHBOARD}|${REPORTING_PLAYLISTS_DASHBOARD}|${REPORTING_CONTENT_ITEMS_DASHBOARD}|${REPORTING_APPS_DASHBOARD}|${REPORTING_CHANNELS_DASHBOARD})?`;
export const REPORTING_DEVICES = `${REPORTING}/${REPORTING_DEVICES_DASHBOARD}`;
export const REPORTING_PLAYLISTS = `${REPORTING}/${REPORTING_PLAYLISTS_DASHBOARD}`;
export const REPORTING_CONTENT_ITEMS = `${REPORTING}/${REPORTING_CONTENT_ITEMS_DASHBOARD}`;
export const REPORTING_APPS = `${REPORTING}/${REPORTING_APPS_DASHBOARD}`;
export const REPORTING_CHANNELS = `${REPORTING}/${REPORTING_CHANNELS_DASHBOARD}`;
export const REPORTING_IFRAME_DEVICES_SRC = `${REPORTS_IFRAME_BASE_URL}/signage/${REPORTING_DEVICES_DASHBOARD}-overview`;
export const REPORTING_IFRAME_PLAYLISTS_SRC = `${REPORTS_IFRAME_BASE_URL}/signage/playlist-overview`;
export const REPORTING_IFRAME_CONTENT_ITEMS_SRC = `${REPORTS_IFRAME_BASE_URL}/signage/content-item-overview`;
export const REPORTING_IFRAME_CHANNELS_SRC = `${REPORTS_IFRAME_BASE_URL}/signage/channel-overview`;
export const REPORTING_IFRAME_APPS_SRC = `${REPORTS_IFRAME_BASE_URL}/signage/app-overview`;

const CUSTOMER_STATUSES = Object.values(CustomerStatus).join('|');
export const CUSTOMERS = '/customers';
export const CUSTOMERS_ACTIVE = '/customers/active';
export const CUSTOMERS_PENDING = '/customers/pending';
export const CUSTOMERS_FAILED = '/customers/failed';
export const CUSTOMERS_TRIAL = '/customers/trial';
export const CUSTOMERS_SUSPENDED = '/customers/suspended';
export const CUSTOMERS_DEACTIVATED = '/customers/deactivated';
export const CUSTOMERS_INTERNAL = '/customers/internal';
export const CUSTOMERS_VIEW_PATH = `/customers/:mode(${CUSTOMER_STATUSES})`;
export const CUSTOMERS_FORM_PATH = `${CUSTOMERS_VIEW_PATH}/:operation(add|edit)/:customerId?`;

// External App Routes
export const CAMPAIGNS = '/campaigns';
export const CHANNELS = '/channels';
export const DEVICES = '/devices';
export const LIBRARY = '/library';
export const LIBRARY_READY_TO_USE = `${LIBRARY}/ready-to-use`;

export const FWI_MODULES: Record<string, string> = {
  [SYS_NAME_KEYS.AdminCenter]: ADMIN_USERS,
  [SYS_NAME_KEYS.CampaignManagement]: CAMPAIGNS,
  [SYS_NAME_KEYS.Library]: LIBRARY,
  [SYS_NAME_KEYS.Reporting]: REPORTING,
  [SYS_NAME_KEYS.CustomerManagement]: CUSTOMERS_ACTIVE,
  [SYS_NAME_KEYS.DeviceManagement]: DEVICES,
  [SYS_NAME_KEYS.Channels]: CHANNELS,
};

// App Routes that are not part of this repo
export const EXTERNAL_ROUTES: readonly string[] = [
  CAMPAIGNS,
  CHANNELS,
  DEVICES,
  LIBRARY,
];

export const INTERNAL_ROUTES = [
  LOGIN,
  ADMIN,
  ALERTS,
  CUSTOMERS,
  DASHBOARD,
  REPORTING,
];

// Once apps have been ported to this repo, move the route from
// `EXTERNAL_ROUTES` into this list
export const VALID_ROUTES: readonly string[] = [
  ...EXTERNAL_ROUTES,
  ...INTERNAL_ROUTES,
];

/**
 * @returns true if the provided pathname starts with one of our known routes.
 */
export const isValidRoute = (pathname: string): boolean =>
  !pathname.startsWith(LOGIN) &&
  VALID_ROUTES.some((route) => pathname.startsWith(route));

/**
 * @returns true if the provided pathname is a link to one of the FWI modules
 * that have been migrated to this monorepo.
 */
export const isInternalRoute = (pathname: string): boolean =>
  INTERNAL_ROUTES.some((route) => pathname.startsWith(route));

/**
 * @returns true if the pathname is for an app not currently in our monorepo.
 * This means we'll need to do a hard redirect instead of history.push
 */
export const isExternalRoute = (pathname: string): boolean =>
  EXTERNAL_ROUTES.some((route) => pathname.startsWith(route));

const CURRENT_ORIGIN =
  typeof window === 'undefined' ? FE_ORIGIN : window.location.origin;

/**
 * This needs to be a full URI that Okta and the FWI token exchange should
 * navigate back to once the user has been authenticated.
 */
export const REDIRECT_URI = `${CURRENT_ORIGIN}${LOGIN_CALLBACK}`;

/**
 * Note: This is an encoded URI
 */
export const SLO_REDIRECT_URI = encodeURIComponent(
  `${CURRENT_ORIGIN}${LOGOUT_OKTA}`
);

export const isLogin = (pathname: string): boolean =>
  pathname.startsWith(LOGIN);

export const isLogout = (pathname: string): boolean =>
  pathname.startsWith(LOGOUT);

export const isEula = (pathname: string): boolean => pathname.startsWith(EULA);

export const isLoginOrLogout = (pathname: string): boolean =>
  isLogin(pathname) || isLogout(pathname);
export const isUnifiedDashboard = (pathname: string): boolean =>
  pathname === DASHBOARD;
export const isAdmin = (pathname: string): boolean =>
  pathname.startsWith(ADMIN);
export const isReporting = (pathname: string): boolean =>
  pathname.startsWith(REPORTING);
export const isAdminSettings = (pathname: string): boolean =>
  pathname === ADMIN_SETTINGS;
export const isAdminUsers = (pathname: string): boolean =>
  pathname.startsWith(ADMIN_USERS);
export const isAdminGroups = (pathname: string): boolean =>
  pathname.startsWith(ADMIN_GROUPS);
export const isAdminLabels = (pathname: string): boolean =>
  pathname.startsWith(ADMIN_LABELS);
export const isAlerts = (pathname: string): boolean =>
  pathname.startsWith(ALERTS);
export const isChannels = (pathname: string): boolean =>
  pathname.startsWith(CHANNELS);
export const isCustomers = (pathname: string): boolean =>
  pathname.startsWith(CUSTOMERS);
export const isDevices = (pathname: string): boolean =>
  pathname.startsWith(DEVICES);
export const isLibrary = (pathname: string): boolean =>
  pathname.startsWith(LIBRARY);

/**
 * Small util that converts a parsed query parameter into a string.
 *
 * @param parsed - the parsed part from `qs.parse()`
 * @returns the parsed string or an empty string.
 */
export function qsToString(
  parsed: undefined | string | string[] | ParsedQs | ParsedQs[]
): string {
  if (typeof parsed !== 'string') {
    return '';
  }

  return parsed;
}

const KNOWN_AUTH_ERRORS: readonly string[] = [
  'InvalidLoginLink',
  'AccountRemoved',
  'SessionExpired',
  'SessionExtendFailure',
  'SessionUnauthorized',
];

export interface PidsOnlyQueryParams {
  pids: boolean;
  companyId: string;
}

export interface KnownQueryParams extends PidsOnlyQueryParams {
  code: string;
  from: string;
  error: string;
  state: string;
}

/**
 * Parses the current search string to get the known query params within our
 * app.
 *
 * @param search - The search string to parse
 * @returns {@link KnownQueryParams}
 */
export function getKnownQueryParams(search: string): KnownQueryParams {
  const parsed = parse(search, { ignoreQueryPrefix: true });

  let from = qsToString(parsed.from);
  if (from && !isValidRoute(from)) {
    from = '';
  }

  const code = qsToString(parsed.code);
  const state = qsToString(parsed.state);
  let error = qsToString(parsed.error) || qsToString(parsed.error_description);
  if (error && !KNOWN_AUTH_ERRORS.includes(error)) {
    error = 'SignInFailed';
  }
  const pids = qsToString(parsed.pids) === 'true';
  const companyId = qsToString(parsed.companyId);

  return {
    code,
    from,
    error,
    state,
    pids,
    companyId,
  };
}

/**
 * This util generates a pathname to navigate to an entity
 *
 * @param entityId - The entity's id
 * @param type - The entity's type
 * @param status - The customer status when the `type` is set to `'customer'`
 * @returns a pathname that can be used to navigate to that entity
 */
export function toEntity(
  entityId: EntityId,
  type: 'user' | 'group' | 'label' | 'devices'
): string;
export function toEntity(
  entityId: EntityId,
  type: 'customer',
  status: CustomerStatus
): string;
export function toEntity(
  entityId: EntityId,
  type: 'user' | 'group' | 'label' | 'customer' | 'devices',
  status?: CustomerStatus
): string {
  switch (type) {
    case 'user':
      return `${ADMIN_USERS}/${entityId}`;
    case 'group':
      return `${ADMIN_GROUPS}/${entityId}`;
    case 'label':
      return `${ADMIN_LABELS}/${entityId}`;
    case 'customer':
      return `${CUSTOMERS}/${status}/edit/${entityId}`;
    case 'devices':
      return `${DEVICES}/${entityId}`;
  }
}
