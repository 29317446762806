import { BE_ORIGIN, OKTA_ISSUER_URL } from 'constants/env';

import { COMPANIES_PATH } from './constants';

export const ADMINISTRATION_ENDPOINT = `${BE_ORIGIN}/administration/v2`;
export const ADMINISTRATION_COMPANIES_ENDPOINT = `${ADMINISTRATION_ENDPOINT}/${COMPANIES_PATH}`;

export const AUTHENTICATION_ENDPOINT = `${BE_ORIGIN}/authentication/v1`;

export const OKTA_AUTHORIZE_ENDPOINT = `${OKTA_ISSUER_URL}/v1/authorize`;

export const IDP_ENDPOINT = `${AUTHENTICATION_ENDPOINT}/idps`;
export const TOKEN_EXCHANGE_ENDPOINT = `${AUTHENTICATION_ENDPOINT}/tokens/exchange`;
export const TOKEN_REFRESH_ENDPOINT = `${AUTHENTICATION_ENDPOINT}/tokens/refresh`;

export const EULA_AGREEMENT_ENDPOINT = `${ADMINISTRATION_ENDPOINT}/eula/agreement`;
export const EULA_ACCEPTANCE_ENDPOINT = `${ADMINISTRATION_ENDPOINT}/${COMPANIES_PATH}/eula/acceptance`;

export const NO_COMPANY_AUTH_ENDPOINT = `${ADMINISTRATION_ENDPOINT}/auth`;
export const COMPANY_AUTH_ENDPOINT = `${ADMINISTRATION_COMPANIES_ENDPOINT}/auth`;

export const COMPANY_SETTINGS_ENDPOINT = `${ADMINISTRATION_COMPANIES_ENDPOINT}/account/settings`;
export const COMPANY_QUOTAS_ENDPOINT = `${ADMINISTRATION_COMPANIES_ENDPOINT}/account/quotas`;

export const COMPANY_PRIVACY_POLICY_ENDPOINT = `${ADMINISTRATION_COMPANIES_ENDPOINT}/privacy-policy`;

export const SINGLE_LOGOUT_ENDPOINT = `${OKTA_ISSUER_URL}/v1/logout`;

export const DS_TOKEN_EXCHANGE_ENDPOINT = `${AUTHENTICATION_ENDPOINT}/token`;
export const LOGOUT_ENDPOINT = `${AUTHENTICATION_ENDPOINT}/logout`;
