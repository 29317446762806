import { isAdmin, isAlerts, isCustomers, isReporting } from 'utils/routes';

export type AppName =
  | 'Dashboard'
  | 'LoggedOut'
  | 'Alerts'
  | 'Customers'
  | 'Reporting'
  | 'AdminCenter';

export const getAppName = (
  authenticated: boolean,
  pathname: string
): AppName => {
  if (!authenticated) {
    return 'LoggedOut';
  }
  if (isAlerts(pathname)) {
    return 'Alerts';
  }
  if (isCustomers(pathname)) {
    return 'Customers';
  }
  if (isAdmin(pathname)) {
    return 'AdminCenter';
  }
  if (isReporting(pathname)) {
    return 'Reporting';
  }

  return 'Dashboard';
};
