import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import { LogoutIcon, MenuItem } from 'fwi-fe-components';

import { loggedOutFromDropdown, useAppDispatch } from 'appState';
import FormattedMessage from 'components/FormattedMessage';
import { LOGOUT } from 'utils/routes';

export interface LogoutMenuItemProps {
  id: string;
}

export default function LogoutMenuItem({
  id,
}: LogoutMenuItemProps): ReactElement {
  const dispatch = useAppDispatch();

  return (
    <MenuItem
      id={id}
      to={LOGOUT}
      linkComponent={Link}
      onClick={() => {
        dispatch(loggedOutFromDropdown());
      }}
      icon={<LogoutIcon />}
    >
      <FormattedMessage messageId="LogOut" />
    </MenuItem>
  );
}
