import { ADMINISTRATION_COMPANIES_ENDPOINT } from './admin';

export const USERS_ENDPOINT = `${ADMINISTRATION_COMPANIES_ENDPOINT}/users`;
export const USERS_ID_ENDPOINT = `${USERS_ENDPOINT}/:userId`;
export const USERS_SEARCH_ENDPOINT = `${USERS_ENDPOINT}/search`;
export const USERS_GROUPS_ENDPOINT = `${USERS_ID_ENDPOINT}/groups`;
export const USERS_RESEND_ACTIVATION_LINK_ENDPOINT = `${USERS_ID_ENDPOINT}/resend-activation-link`;
export const USERS_SEND_PASSWORD_RESET_LINK_ENDPOINT = `${USERS_ID_ENDPOINT}/send-password-reset-link`;
export const USERS_UNLOCK_ENDPOINT = `${USERS_ID_ENDPOINT}/unlock`;
export const USERS_CSV_ENDPOINT = `${USERS_ENDPOINT}/csv`;
export const USERS_EXPORT_ENDPOINT = `${USERS_ENDPOINT}/export`;
