import { AppState, CompanySettings, ReportingModuleStage } from 'appTypes';

/**
 *
 * @param state The top-level store state.
 * @returns true if the company's settings are loading
 */
export const isLoadingCompanySettings = (state: AppState): boolean =>
  state.companySettings.loading;

/**
 * Note: This should only return `null` if the company settings has not been
 * fetched or an error has occurred.
 *
 * @param state The top-level store state.
 * @returns the company settings or `null`
 */
export const getCompanySettings = (
  state: AppState
): Readonly<CompanySettings> | null => state.companySettings.settings;

export const getPlaybackReportingLastEnabled = (state: AppState): string =>
  getCompanySettings(state)?.playbackReportingEnabled.lastModified ?? '';

export const isPlaybackReportingEnabled = (state: AppState): boolean =>
  getCompanySettings(state)?.playbackReportingEnabled.value ?? false;

export const getReportingStage = (state: AppState): ReportingModuleStage =>
  state.companySettings.reportingModuleStage;

export const isReportsBeenEnabledForFirstTime = (state: AppState): boolean =>
  getCompanySettings(state)?.isReportsEnabledForTheFirstTime ?? false;
