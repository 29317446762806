import { ReactElement } from 'react';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';
import { TransitionGroup } from 'react-transition-group';

import { getCurrentToast, useAppSelector } from 'appState';

import Toast from './Toast';

export default function Toasts(): ReactElement {
  const intl = useIntl();
  const toast = useAppSelector(getCurrentToast);

  return createPortal(
    <TransitionGroup
      aria-label={intl.formatMessage({ id: 'Toasts.Name' })}
      id="main-toasts"
      component="div"
      role="status"
    >
      {toast && <Toast key={toast.key} toast={toast} />}
    </TransitionGroup>,
    document.body
  );
}
