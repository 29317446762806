/* eslint-disable @typescript-eslint/ban-ts-comment */
import { LICENSE_TYPES as ALL_LICENSE_TYPES } from 'fwi-constants';

export interface License {
  roleId: string;
  sysname: string;
  name: string;
  description: string;
  defaultCap: number;
  system: boolean;
}

export const LICENSE_TYPES = Object.keys(ALL_LICENSE_TYPES).reduce<
  Record<string, License>
>((result, type) => {
  // @ts-ignore
  if (!ALL_LICENSE_TYPES[type].system) {
    // @ts-ignore
    result[type] = ALL_LICENSE_TYPES[type];
  }
  return result;
}, {});

export const PRINTABLE_CHARACTERS_ONLY_REGEX =
  /^[\u0020-\u007e\u00a0-\u00ff]*$/;
export const ALPHANUMERIC_CHARACTERS_ONLY_REGEX = /^[a-zA-Z0-9]*$/;

export const CUSTOM_LOGIN_PREFIX_MIN = 3;
export const CUSTOM_LOGIN_PREFIX_MAX = 63;
/** Custom login prefix must be lowercase and alphanumeric, with the exception of hyphens. Cannot start or end with a hyphen.  */
export const CUSTOM_LOGIN_REGEXP = /^[a-z0-9][a-z0-9-]{1,63}[a-z0-9]$/;
