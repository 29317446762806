import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ErrorState } from 'appTypes';

const { actions, reducer } = createSlice({
  name: 'errorCode',
  initialState: null as ErrorState,
  reducers: {
    setErrorCode: (_state, action: PayloadAction<ErrorState>) => action.payload,
  },
});

export const { setErrorCode } = actions;

export default reducer;
