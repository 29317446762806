import { createReducer } from '@reduxjs/toolkit';

import { DriveState } from 'appTypes';

import { fetchDriveId } from './api';

export const INITIAL_DRIVE_STATE: DriveState = {
  loading: false,
  driveId: '',
};

export default createReducer(INITIAL_DRIVE_STATE, (builder) =>
  builder
    .addCase(fetchDriveId.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchDriveId.fulfilled, (state, action) => {
      state.loading = false;
      state.driveId = action.payload;
    })
    .addCase(fetchDriveId.rejected, (state) => {
      state.loading = false;
    })
);
