import {
  PlatformApiController,
  PlatformApiControllerOptions,
  TokenType,
  isDigitalSignageSelfSignedToken,
} from 'fwi-fe-utils';

import { OIDC_CLIENT_ID, OIDC_ENDPOINT, OKTA_ISSUER_URL } from 'constants/env';

export function isDigitalSignageAuth(): boolean {
  return isDigitalSignageSelfSignedToken(OKTA_ISSUER_URL);
}

const getFromTokenType = (): TokenType =>
  isDigitalSignageAuth() ? 'fwi-kms' : 'fwi';

const baseOptions: Omit<PlatformApiControllerOptions<TokenType>, 'to'> = {
  from: getFromTokenType(),
  clientId: OIDC_CLIENT_ID,
  pidsOrigin: OIDC_ENDPOINT,
};

export const emailAndFeedsTokenController = new PlatformApiController({
  ...baseOptions,
  to: 'poppulojwt',
});

export function removeTokenControllers(): void {
  emailAndFeedsTokenController.removeFromStorage();
}

export function updateTokenControllersAuth(): void {
  emailAndFeedsTokenController.updateFromTokenType(getFromTokenType());
}
