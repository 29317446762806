import cn from 'classnames';
import { debounce } from 'lodash';
import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { Button, SearchBox, SearchButton, XIcon } from 'fwi-fe-components';

import {
  clickedLabelSearchBar,
  isMobile,
  updateLabelSearch,
  useAppDispatch,
  useAppSelector,
} from 'appState';
import { HEADER_BUTTON } from 'constants/branding';
import { isAdminLabels } from 'utils/routes';

import styles from './AdminSearchButton.module.scss';

const MINIMUM_SEARCH_LENGTH = 3;
const SEARCH_QUERY_DELAY = 500;

export interface AdminSearchButtonProps {
  id: string;
}

export default function AdminSearchButton({
  id,
}: Readonly<AdminSearchButtonProps>): ReactElement | null {
  const { pathname } = useLocation();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const mobile = useAppSelector(isMobile);
  const [openSearch, setOpenSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const searchLabels = useMemo(
    () =>
      debounce((value: string) => {
        dispatch(
          updateLabelSearch(value.length >= MINIMUM_SEARCH_LENGTH ? value : '')
        );
      }, SEARCH_QUERY_DELAY),
    [dispatch]
  );

  const handleOpenSearch = useCallback(() => {
    setOpenSearch(true);
    dispatch(clickedLabelSearchBar());
  }, [dispatch]);

  const handleSearchReset = useCallback(() => {
    searchLabels.cancel();
    setSearchValue('');
    dispatch(updateLabelSearch(''));
  }, [dispatch, searchLabels]);

  const handleSearchCancel = useCallback(() => {
    handleSearchReset();
    setOpenSearch(false);
  }, [handleSearchReset]);

  useEffect(() => {
    return () => {
      searchLabels.cancel();
    };
  }, [searchLabels]);

  if (!isAdminLabels(pathname)) {
    return null;
  }

  if (openSearch) {
    return (
      <SearchBox
        id={`${id}-search-box`}
        className={cn(styles.search, { [styles.mobileSearch]: mobile })}
        mobile={mobile}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const { value } = event.currentTarget;
          setSearchValue(value);
          searchLabels(value);
        }}
        inputProps={{
          autoFocus: true,
          onKeyDown(event) {
            if (event.key !== 'Escape') {
              return;
            }

            if (searchValue) {
              handleSearchReset();
              return;
            }

            handleSearchCancel();
          },
        }}
        onCancel={handleSearchCancel}
        onReset={handleSearchReset}
        query={searchValue}
      >
        {!mobile && !searchValue && (
          <Button
            id={`${id}-search-box-close`}
            aria-label={intl.formatMessage({ id: 'Cancel' })}
            className={styles.searchClose}
            theme="metal"
            btnType="icon-circle"
            onClick={handleSearchCancel}
          >
            <XIcon theme="none" />
          </Button>
        )}
      </SearchBox>
    );
  }
  return (
    <SearchButton
      id={id}
      mobile={mobile}
      className={HEADER_BUTTON}
      onClick={handleOpenSearch}
    />
  );
}
