import cn from 'classnames';
import { ReactElement, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { setErrorCode, useAppDispatch } from 'appState';
import { ErrorCode } from 'appTypes';

import styles from './ErrorCodeHeader.module.scss';
import ghost from './ghost.png';
import lock from './lock.png';

export interface ErrorCodeHeaderProps {
  code: ErrorCode;
  modal: boolean;
  forbidden: boolean;
}

export default function ErrorCodeHeader({
  code,
  modal,
  forbidden,
}: ErrorCodeHeaderProps): ReactElement {
  const intl = useIntl();
  const [prefix, , suffix] = `${code}`.split('');
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (modal) {
      // don't set the error code for modals since setting the error code is just
      // to update the general layout
      return;
    }

    dispatch(setErrorCode(code));

    return () => {
      dispatch(setErrorCode(null));
    };
  }, [code, dispatch, modal]);

  return (
    <span
      aria-label={intl.formatMessage({ id: `ErrorPage.${code}` })}
      className={styles.header}
    >
      {prefix}
      <img
        alt=""
        src={forbidden ? lock : ghost}
        className={cn(styles.image, forbidden && styles.imageLock)}
      />
      {suffix}
    </span>
  );
}
