import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getCurrentMediaTypes } from 'fwi-fe-utils';

import { AppSizeState } from 'appTypes';

export const INITIAL_APP_SIZE_STATE: AppSizeState = {
  // "mobile first". ha ha ha
  mobile: true,
  desktop: false,
};

export function getInitialAppSizeState(): AppSizeState {
  return getCurrentMediaTypes();
}

const { actions, reducer } = createSlice({
  name: 'appSize',
  initialState: INITIAL_APP_SIZE_STATE,
  reducers: {
    update: (_, action: PayloadAction<AppSizeState>) => action.payload,
  },
});

export const { update: updateAppSize } = actions;

export default reducer;
