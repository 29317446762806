import { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { Button, PlusCircleOutlineIcon } from 'fwi-fe-components';

import { isMobile, useAppSelector } from 'appState';
import { EntityAction } from 'appTypes';
import { HEADER_BUTTON } from 'constants/branding';
import { useCustomerStatus } from 'hooks';
import { CUSTOMERS } from 'utils/routes';

const icon = <PlusCircleOutlineIcon />;

export interface AddCustomerProps {
  id: string;
  isNoContent: boolean;
}

export default function AddCustomer({
  id,
  isNoContent,
}: AddCustomerProps): ReactElement | null {
  const intl = useIntl();
  const history = useHistory();
  const mobile = useAppSelector(isMobile);
  const status = useCustomerStatus(true);
  const label = intl.formatMessage({
    id: isNoContent ? 'AddNewCustomer' : 'New',
  });

  if (!status) {
    return null;
  }

  return (
    <Button
      aria-label={mobile ? label : undefined}
      id={id}
      icon={mobile || isNoContent ? null : icon}
      btnType={mobile ? 'icon' : 'text'}
      onClick={() => {
        history.push(`${CUSTOMERS}/${status}/${EntityAction.Add}`);
      }}
      className={HEADER_BUTTON}
    >
      {!mobile ? label : icon}
    </Button>
  );
}
