import { AppState, ChannelInterruptsState } from 'appTypes';

import { adapter } from './schema';

const getChannelInterruptsState = (state: AppState): ChannelInterruptsState =>
  state.channelInterrupts;

export const {
  selectById: getChannelInterruptById,
  selectAll: getChannelInterrupts,
  selectEntities: getChannelInterruptEntities,
  selectIds: getChannelInterruptIds,
  selectTotal: getTotalChannelInterrupts,
} = adapter.getSelectors(getChannelInterruptsState);
