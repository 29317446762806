import { AppState, MarketSolutionsState } from 'appTypes';

import { adapter } from './schema';

export const getMarketSolutionsState = (
  state: AppState
): MarketSolutionsState => state.marketSolutions;

export const {
  selectById: getMarketSolutionById,
  selectAll: getMarketSolutions,
  selectEntities: getMarketSolutionEntities,
  selectTotal: getTotalMarketSolutions,
} = adapter.getSelectors(getMarketSolutionsState);

export const isLoadingMarketSolutions = (state: AppState): boolean =>
  state.marketSolutions.loading;
