import { isBoolean } from 'lodash';
import moment from 'moment';

/**
 * Capitalizes the first letter of a string joined with the rest
 * of the string.
 *
 * @param {String} s - the string to capitalize
 * @return {String} the capitalized string.
 */
export function capitalizeFirst(s?: string | null | boolean): string {
  if (!s || isBoolean(s)) {
    return '';
  }

  return s.charAt(0).toUpperCase() + s.substring(1);
}

export function stripLeadingSpaces(value: string): string {
  return value.replace(/^\s+/, '');
}

export const getMonthAndYear = (isoDate: string): string | null => {
  const date = moment(isoDate);

  if (!date.isValid()) {
    return null;
  }

  return date.format('MMMM YYYY');
};
