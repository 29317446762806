import { EntityId, GroupEntity } from 'fwi-fe-types';

import {
  AppState,
  GroupsState,
  ListViewGroup,
  ReadonlyEntityList,
} from 'appTypes';

import { adapter } from './schema';

const getGroupsState = (state: AppState): GroupsState => state.groups;

export const {
  selectById: getGroupById,
  selectAll: getGroups,
  selectEntities: getGroupEntities,
  selectIds: getGroupIds,
  selectTotal: getTotalGroups,
} = adapter.getSelectors(getGroupsState);

/**
 * Gets a group by id for the main list view and properties panel.
 *
 * @param state - The top-level store state.
 * @param entityId - The group's id
 * @returns the {@link ListViewGroup} or undefined.
 */
export const getListViewGroupById = (
  state: AppState,
  entityId: EntityId
): Readonly<ListViewGroup> | undefined => {
  const group = getGroupById(state, entityId);
  if (!group) {
    return;
  }

  const { name, users, description = '', createdOn, modifiedOn } = group;

  return {
    entityId,
    name,
    type: 'group',
    users,
    description,
    createdOn,
    modifiedOn,
  };
};

/**
 *
 * @param state The top-level store state.
 * @param groupIds The list ids of the group to find
 * @returns a list of all groups that exist in state with the provided ids.
 */
export const getGroupsByIds = (
  state: AppState,
  groupIds: readonly EntityId[]
): ReadonlyEntityList<GroupEntity> =>
  groupIds.reduce<GroupEntity[]>((list, groupId) => {
    const group = getGroupById(state, groupId);
    if (group) {
      list.push(group);
    }

    return list;
  }, []);

/**
 *
 * @param state The top-level store state.
 * @returns A list containing all default group ids
 */
export const getDefaultGroupIds = (state: AppState): readonly EntityId[] =>
  getGroups(state).reduce<string[]>((list, group) => {
    if (group.isDefault) {
      list.push(group.id);
    }

    return list;
  }, []);
/**
 *
 * @param state The top-level store state.
 * @returns true or false if userGroups are loading.
 */
export const isLoadingDefaultGroups = (state: AppState): boolean =>
  getGroupsState(state).loadingDefault;

/**
 *
 * @param state The top-level store state.
 * @param groupId The groupId to find if it is loading.
 */
export const isLoadingGroup = (state: AppState, groupId: EntityId): boolean =>
  getGroupsState(state).loadingIds.includes(groupId);

/**
 *
 * @param state The top-level store state.
 * @param duplicateId The duplicateId to find if it's duplicate is loading.
 */
export const isLoadingGroupDuplicate = (
  state: AppState,
  duplicateId: EntityId
): boolean => getGroupsState(state).loadingDuplicateIds.includes(duplicateId);

/**
 *
 * @param state The top-level store state.
 * @param userId The userId that was used to fetch the groups.
 */
export const isLoadingGroupsByUserId = (
  state: AppState,
  userId: EntityId
): boolean => getGroupsState(state).loadingByUserIds.includes(userId);

/**
 *
 * @param state The top-level store state.
 * @param groupId The groupId that should be checked
 * @returns `true` if the provided group id is being deleted
 */
export const isGroupDeleting = (state: AppState, groupId: EntityId): boolean =>
  getGroupsState(state).loadingDeleteIds.includes(groupId);
