import { ReactElement, ReactNode } from 'react';

import FormattedMessage from 'components/FormattedMessage';

import styles from './MaintenancePage.module.scss';
import cloudMaintenance from './cloud-maintenance.svg';

export interface MaintenancePageProps {
  children: ReactNode;
  maintenance: boolean;
}

export default function MaintenancePage({
  children,
  maintenance,
}: MaintenancePageProps): ReactElement {
  if (!maintenance) {
    return <>{children}</>;
  }
  return (
    <>
      <img alt="" src={cloudMaintenance} className={styles.image} />
      <FormattedMessage
        id="maintenance-heading"
        messageId="Maintenance.Heading"
        component="h4"
        className={styles.heading}
      />
      <FormattedMessage
        id="maintenance-downtime"
        messageId="Maintenance.Downtime"
        component="p"
        className={styles.text}
      />
      <p id="maintenance-message" className={styles.text}>
        <FormattedMessage messageId="Maintenance.ScreenBehavior" />
        <br />
        <FormattedMessage messageId="Maintenance.Thanks" />
      </p>
    </>
  );
}
