import { PayloadAction } from '@reduxjs/toolkit';

import { EntityId, LicenseType, ModulePermission } from 'fwi-fe-types';
import { AnalyticsEvent, analyticsTrackEvent } from 'fwi-fe-utils';

import {
  AppDispatch,
  ModuleAccess,
  RESTRICTABLE_MODULE_ACCESS_NAME,
  RestrictableModuleAccess,
} from 'appTypes';
import {
  buildCompletePermissions,
  isPermissionEqual,
  isPermissionLimited,
} from 'utils/permissions';

const category = 'Users';
const FROM_ACTIVE_USER_PAGE = 'From Active User Page';
const FROM_PROPERTIES_PANEL = 'From Properties Panel';

/**
 * The analytics event to trigger whenever a user is created.
 *
 * @param licenseType The new user's {@link LicenseType}
 */
export const userCreated = (
  licenseType: LicenseType
): PayloadAction<AnalyticsEvent> =>
  analyticsTrackEvent({
    eventName: 'User Created',
    properties: { category, label: licenseType },
  });

/**
 * The analytics event to trigger whenever a user is modified.
 *
 * @param userId The user's id that is being modified.
 */
export const userModified = (userId: EntityId): PayloadAction<AnalyticsEvent> =>
  analyticsTrackEvent({
    eventName: 'User Modified',
    properties: { category, label: userId },
  });

/**
 * The analytics event to trigger whenever a user is duplicated.
 *
 * @param modal Boolean if the event was triggered from the user's super modal.
 */
export const userDuplicated = (modal: boolean): PayloadAction<AnalyticsEvent> =>
  analyticsTrackEvent({
    eventName: 'User Duplicated',
    properties: {
      category,
      label: modal ? FROM_ACTIVE_USER_PAGE : FROM_PROPERTIES_PANEL,
    },
  });

/**
 * The analytics event to trigger whenever a user is deleted.
 *
 * @param modal Boolean if the event was triggered from the user's super modal.
 */
export const userDeleted = (modal: boolean): PayloadAction<AnalyticsEvent> =>
  analyticsTrackEvent({
    eventName: 'User Deleted',
    properties: {
      category,
      label: modal ? FROM_ACTIVE_USER_PAGE : FROM_PROPERTIES_PANEL,
    },
  });

/**
 * The analytics event to trigger whenever a user's access to different modules
 * is changed.
 *
 * @param prev The previous access level
 * @param next The next access level
 * @param module The module that has the restrictions changed.
 * @param restrictions The number of restrictions enforced by setting
 * specific folder ids. This _should_ be `0` if there are no restrictions.
 */
export const userAccessLevelChanged = (
  prev: ModuleAccess,
  next: ModuleAccess,
  module: RestrictableModuleAccess,
  restrictions: number
): PayloadAction<AnalyticsEvent> =>
  analyticsTrackEvent({
    eventName: `User ${RESTRICTABLE_MODULE_ACCESS_NAME[module]} Permissions Updated`,
    properties: {
      category,
      label: `Access level changed from ${prev} to ${next}`,
      value: restrictions,
    },
  });

/**
 * This is a thunked action that will compare the permissions objects to see if
 * access has changed for any of our modules.
 *
 * @param prev - The previous user permissions
 * @param next - The next user permissions
 */
export const userPermissionsChanged =
  (prev: readonly ModulePermission[], next: readonly ModulePermission[]) =>
  (dispatch: AppDispatch): void => {
    const prevPermissions = buildCompletePermissions(prev);
    const nextPermissions = buildCompletePermissions(next);
    Object.entries(RESTRICTABLE_MODULE_ACCESS_NAME).forEach(
      ([moduleKey, moduleId]) => {
        const module = moduleKey as RestrictableModuleAccess;

        const prev = prevPermissions.find((perm) => perm.moduleId === moduleId);
        const next = nextPermissions.find((perm) => perm.moduleId === moduleId);
        if (!isPermissionEqual(prev, next) && prev && next) {
          const beforeEffect = isPermissionLimited(prev)
            ? ModuleAccess.Limited
            : prev.effect;
          const afterEffect = isPermissionLimited(next)
            ? ModuleAccess.Limited
            : next.effect;
          const restrictions = next.resources.filter(
            (resource) => resource !== ModuleAccess.Everything
          ).length;

          dispatch(
            userAccessLevelChanged(
              beforeEffect,
              afterEffect,
              module,
              restrictions
            )
          );
        }
      }
    );
  };
