import { PayloadAction } from '@reduxjs/toolkit';

import { AnalyticsEvent, analyticsTrackEvent } from 'fwi-fe-utils';

const category = 'Labels';
const FROM_ADMIN_LABEL_LIST = 'From Admin Label List';
const FROM_ADMIN_LABEL_PROPERTIES = 'From Admin Label Properties';
const FROM_ADMIN_LABEL_SUPER_MODAL = 'From Admin Label Super Modal';

/**
 * The analytics event to trigger whenever the create label modal becomes
 * visible within admin center.
 */
export const createLabelModalOpened = analyticsTrackEvent.bind(null, {
  eventName: 'Create Label Modal Opened',
  properties: { category },
});

/**
 * The analytics event to trigger whenever a new label is created within admin
 * center.
 */
export const labelCreated = analyticsTrackEvent.bind(null, {
  eventName: 'New Label Created',
  properties: { category },
});

/**
 * The analytics event to trigger whenever the edit label modal becomes visible
 * within admin center.
 */
export const labelEdited = analyticsTrackEvent.bind(null, {
  eventName: 'Label Edited',
  properties: { category },
});

/**
 * The analytics event to trigger whenever a label is deleted within admin
 * center.
 */
export const labelDeleted = analyticsTrackEvent.bind(null, {
  eventName: 'Label Deleted',
  properties: { category },
});

/**
 * The analytics event to trigger whenever the label search bar is clicked
 * from the list view.
 */
export const clickedLabelSearchBar = analyticsTrackEvent.bind(null, {
  eventName: 'Clicked Label Search Bar',
  properties: { category, label: FROM_ADMIN_LABEL_LIST },
});

/**
 * The analytics event to trigger whenever the label list is sorted.
 *
 * @param sortKey - The key to sort the labels by, 'Devices' or 'Values'.
 */
export const labelsSortedBy = (
  sortKey: string
): PayloadAction<AnalyticsEvent> => {
  return analyticsTrackEvent({
    eventName: `Sorted by # of ${sortKey}`,
    properties: { category, label: FROM_ADMIN_LABEL_LIST },
  });
};

/**
 * The analytics event to trigger whenever a label's description is modified.
 *
 * @param modal - Boolean representing if the label description was modified from the modal.
 */
export const labelDescriptionModified = (
  modal: boolean
): PayloadAction<AnalyticsEvent> => {
  return analyticsTrackEvent({
    eventName: 'Label Description modified',
    properties: {
      category,
      label: modal ? FROM_ADMIN_LABEL_SUPER_MODAL : FROM_ADMIN_LABEL_LIST,
    },
  });
};

/**
 * The analytics event to trigger whenever a label's values or devices are
 * expanded or collapsed.
 *
 * @param eventName - String representing the event name, 'Values' or 'Devices'.
 * @param expanded - Boolean representing if the entity was expanded or collapsed.
 */
export const entityToggled = (
  eventName: string,
  expanded: boolean
): PayloadAction<AnalyticsEvent> => {
  return analyticsTrackEvent({
    eventName: `${eventName === 'Values' ? 'Label Values' : eventName} ${expanded ? 'Expanded' : 'Collapsed'}`,
    properties: { category, label: FROM_ADMIN_LABEL_PROPERTIES },
  });
};

/**
 * The analytics event to trigger whenever the Go To Device button is clicked
 * from the label properties panel.
 */
export const goToDeviceFromProperties = analyticsTrackEvent.bind(null, {
  eventName: 'Go to Device from Label Properties',
  properties: { category, label: FROM_ADMIN_LABEL_PROPERTIES },
});

/**
 * The analytics event to trigger whenever multiple label values are pasted in
 * the add values modal.
 */
export const multipleLabelValuesPasted = analyticsTrackEvent.bind(null, {
  eventName: 'Multiple Label Values pasted',
  properties: { category, label: FROM_ADMIN_LABEL_SUPER_MODAL },
});

/**
 * The analytics event to trigger whenever multiple label values are created in
 * the add values modal.
 */
export const multipleLabelValuesCreated = analyticsTrackEvent.bind(null, {
  eventName: 'Multiple Label Values created',
  properties: { category, label: FROM_ADMIN_LABEL_SUPER_MODAL },
});

/**
 * The analytics event to trigger whenever the label values are sorted by the
 * least devices.
 */
export const sortValuesByLeastDevices = analyticsTrackEvent.bind(null, {
  eventName: 'Sort Values by Least Devices',
  properties: { category, label: FROM_ADMIN_LABEL_SUPER_MODAL },
});

/**
 * The analytics event to trigger whenever a label value is deleted.
 */
export const valueDeleted = analyticsTrackEvent.bind(null, {
  eventName: 'Value Deleted',
  properties: { category, label: FROM_ADMIN_LABEL_SUPER_MODAL },
});

/**
 * The analytics event to trigger whenever a label value is restored.
 */
export const undoDeleteValue = analyticsTrackEvent.bind(null, {
  eventName: 'Undo Delete Value',
  properties: { category, label: FROM_ADMIN_LABEL_SUPER_MODAL },
});

/**
 * The analytics event to trigger whenever the Go To Device button is clicked
 * from the super modal label value.
 */
export const goToDeviceFromValue = analyticsTrackEvent.bind(null, {
  eventName: 'Go to Device from Label Value',
  properties: { category, label: FROM_ADMIN_LABEL_SUPER_MODAL },
});

/**
 * The analytics event to trigger whenever the value search bar is clicked
 */
export const valueSearchClicked = analyticsTrackEvent.bind(null, {
  eventName: 'Value Search Clicked',
  properties: { category, label: FROM_ADMIN_LABEL_SUPER_MODAL },
});
