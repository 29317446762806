import { createAction, createReducer } from '@reduxjs/toolkit';

import { SortOrder, SortState } from 'fwi-fe-types';
import { Sorting } from 'fwi-fe-utils';

import { fetchLabels } from '../labels';

const INITIAL_LOADING_STATE = false;
const INITIAL_SORT_STATE: SortState = {
  sort: 'name',
  sortOrder: Sorting.SORT_ASC,
  isSorted: false,
};
export const LABELS_INITIAL_STATE = {
  loading: false,
  numberOfItems: 0,
  sort: { ...INITIAL_SORT_STATE, isSorted: true },
};

export const sortLabels = createAction(
  'labels/sort',
  function prepare(sort: string, sortOrder: SortOrder) {
    return {
      payload: {
        sort,
        sortOrder,
      },
    };
  }
);

export default createReducer(LABELS_INITIAL_STATE, (builder) => {
  builder.addCase(fetchLabels.pending, (state) => {
    state.loading = true;
  });

  builder.addCase(fetchLabels.fulfilled, (state, action) => {
    const { payload } = action;
    state.loading = INITIAL_LOADING_STATE;
    state.numberOfItems = Object.keys(payload.labels).length;
  });

  builder.addCase(fetchLabels.rejected, (state) => {
    state.loading = INITIAL_LOADING_STATE;
  });

  builder.addCase(sortLabels, (state, action) => {
    const { payload } = action;
    state.sort.sortOrder = payload.sortOrder;
    state.sort.sort = payload.sort;
  });
});
