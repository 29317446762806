import { createAsyncThunk } from '@reduxjs/toolkit';

import { LibraryFolderEntity } from 'fwi-fe-types';
import { StatusCodes } from 'fwi-fe-utils';

import { APIRejectionThunkConfig, NormalizedFolders } from 'appTypes';
import {
  CHANNEL_FOLDERS_ENDPOINT,
  DEVICE_FOLDERS_ENDPOINT,
  LIBRARY_FOLDERS_ENDPOINT,
} from 'constants/endpoints';
import { api } from 'utils/api';

import { normalizeFolders } from './schema';

/**
 * Fetches all the folders from the device service.
 *
 * @returns a list of the {@link DeviceFolderEntity}
 */
export const fetchDeviceFolders = createAsyncThunk<
  NormalizedFolders,
  void,
  APIRejectionThunkConfig
>('folders/device', async (_, { rejectWithValue }) => {
  const response = await api(DEVICE_FOLDERS_ENDPOINT);

  if (!response.ok) {
    return rejectWithValue({
      status: response.status,
      unmodified: response.status === StatusCodes.NOT_MODIFIED,
    });
  }

  const json = await response.json();
  return normalizeFolders(json);
});

/**
 * Fetches all the folders from the channel service.
 *
 * @returns a list of the {@link ChannelFolderEntity}
 */
export const fetchChannelFolders = createAsyncThunk<
  NormalizedFolders,
  void,
  APIRejectionThunkConfig
>('folders/channel', async (_, { rejectWithValue }) => {
  const response = await api(`${CHANNEL_FOLDERS_ENDPOINT}/folders`);

  if (!response.ok) {
    return rejectWithValue({
      status: response.status,
      unmodified: response.status === StatusCodes.NOT_MODIFIED,
    });
  }

  const json = await response.json();
  return normalizeFolders(json);
});

interface LibraryFoldersJson {
  itemListElement: readonly LibraryFolderEntity[];
  numberOfItems: number;
}

/**
 * Fetches all the folders from the library service.
 *
 * @returns a list of the {@link LibraryFolderEntity}
 */
export const fetchLibraryFolders = createAsyncThunk<
  NormalizedFolders,
  void,
  APIRejectionThunkConfig
>('folders/library', async (_, { rejectWithValue }) => {
  const response = await api(LIBRARY_FOLDERS_ENDPOINT);

  if (!response.ok) {
    return rejectWithValue({
      status: response.status,
      unmodified: response.status === StatusCodes.NOT_MODIFIED,
    });
  }

  const json: LibraryFoldersJson = await response.json();
  return normalizeFolders(json.itemListElement);
});
