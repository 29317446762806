import { SYS_NAME_KEYS } from 'fwi-constants';

import { AppModuleId } from 'appTypes';

export const ALERTS_SCOPES: readonly AppModuleId[] = [
  SYS_NAME_KEYS.Alerts,
  SYS_NAME_KEYS.DeviceManagement,
];

export const REPORTING_SCOPES: readonly AppModuleId[] = [
  SYS_NAME_KEYS.Reporting,
];

export const CUSTOMERS_SCOPES: readonly AppModuleId[] = [
  SYS_NAME_KEYS.CustomerManagement,
];

export const ADMIN_SCOPES: readonly AppModuleId[] = [SYS_NAME_KEYS.AdminCenter];

export const FWI_EMAIL_REGEX = /@fourwindsinteractive.com|@fwi.com/;
export const FROM_AUTHENTICATION_QUERY_PARAM = 'fromAuthorize';
