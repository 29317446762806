import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIStatusRejectionThunkConfig, PrivacyPolicyS3Url } from 'appTypes';
import { COMPANY_PRIVACY_POLICY_ENDPOINT } from 'constants/endpoints';
import { api } from 'utils/api';

/**
 * Fetches the url to download the privacy policy
 */
export const fetchPrivacyPolicyContent = createAsyncThunk<
  PrivacyPolicyS3Url,
  void,
  APIStatusRejectionThunkConfig
>('privacyPolicy/content', async (_, { rejectWithValue }) => {
  const urlsResponse = await api(COMPANY_PRIVACY_POLICY_ENDPOINT, {
    checkStatus: false,
  });

  if (!urlsResponse.ok) {
    const { status, statusText } = urlsResponse;
    return rejectWithValue({ status, statusText });
  }

  const { downloadUrl }: PrivacyPolicyS3Url = await urlsResponse.json();

  return {
    downloadUrl,
  };
});
