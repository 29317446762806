import { createEntityAdapter } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';

import { FolderEntity, FolderType } from 'fwi-fe-types';

import { NormalizedFolders } from 'appTypes';
import { getFolderNameByFolderType } from 'utils/folders';

export const adapter = createEntityAdapter<FolderEntity>({
  selectId: (folder) => folder.folderId,
});

/**
 * @see {@link NormalizedFolders} for more info around the `labels` identifier
 */
export const FOLDER_ENTITY = new schema.Entity<FolderEntity>(
  'folders',
  {},
  {
    idAttribute: 'folderId',
    processStrategy(folderJson) {
      const {
        _links,
        links,
        add,
        etag,
        eTag,
        root,
        undefined: u,
        description = '',
        virtualParentFolderId,
        parentFolderId,
        ...folder
      } = folderJson;
      let { name, markedForDeletion } = folder;
      const isLibrary = folder.folderType === FolderType.LIBRARY;
      if (!name) {
        name = getFolderNameByFolderType(folder.folderType);
      }

      if (isLibrary) {
        markedForDeletion = !!markedForDeletion;
      }

      return {
        ...folder,
        name,
        description,
        type: 'folder',
        parentFolderId: virtualParentFolderId || parentFolderId,
        markedForDeletion,
      };
    },
  }
);
export const FOLDERS_SCHEMA = new schema.Array<FolderEntity[]>(FOLDER_ENTITY);

/**
 * Transforms and normalizes folders from a list of json objects.
 *
 * @param json - A list of json objects (normally from the BE) that should be
 * normalized
 * @returns the {@link NormalizedFolders} record
 */
export function normalizeFolders(json: readonly unknown[]): NormalizedFolders {
  const { folders = {} } = normalize<unknown, NormalizedFolders>(
    json,
    FOLDERS_SCHEMA
  ).entities;

  return { folders };
}

/**
 * Transforms and normalizes a folder from a json object.
 *
 * @param json - A json object (normally from the BE) that should be normalized
 * @returns the {@link NormalizedFolders} record
 */
export function normalizeFolder(json: unknown): NormalizedFolders {
  return normalizeFolders([json]);
}
