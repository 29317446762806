import { SplitSdk } from '@splitsoftware/splitio-react';

import {
  CurrentUserEntity,
  FeatureFlag,
  LicenseType,
  UserCompany,
} from 'fwi-fe-types';

import { AWS_BUCKET_REGION, BUILD_ENV, BUILD_NODE_ENV } from 'constants/env';

export interface GetSplitAttributesOptions {
  user?: { id: string; licenseType: LicenseType };
  company?: { id: string; isInternal: boolean };
}

export function getSplitAttributes({
  user,
  company,
}: GetSplitAttributesOptions): SplitIO.Attributes {
  const conditionalAttributes: SplitIO.Attributes = {};
  if (user) {
    conditionalAttributes.userId = user.id;
    conditionalAttributes.licenseType = user.licenseType;
  }

  if (company) {
    conditionalAttributes.isInternal = company.isInternal;
  }

  return {
    env: BUILD_NODE_ENV,
    region: AWS_BUCKET_REGION,
    fwiEnv: BUILD_ENV,
    ...conditionalAttributes,
  };
}

export interface SplitFeaturesForTesting {
  features?: SplitIO.MockedFeaturesMap;
  authorizationKey: string;
}

export interface IsFeatureFlagEnabledForCompanyOptions
  extends SplitFeaturesForTesting {
  user?: Readonly<CurrentUserEntity>;
  company: Readonly<UserCompany>;
  featureFlag: FeatureFlag;
}

export async function isFeatureFlagEnabledForCompany({
  user,
  company,
  features,
  featureFlag,
  authorizationKey,
}: IsFeatureFlagEnabledForCompanyOptions): Promise<boolean> {
  const nextFactory = SplitSdk({
    core: {
      authorizationKey,
      key: company.id,
    },
    features,
    storage: {
      type: 'MEMORY',
    },
  });
  const nextClient = nextFactory.client();

  await nextClient.ready();
  const treatment = nextClient.getTreatment(
    featureFlag,
    getSplitAttributes({ user, company })
  );

  return treatment === 'on';
}
