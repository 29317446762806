import { EntityId, SortOrder, SortState } from 'fwi-fe-types';

import {
  AppState,
  CustomersState,
  LegacySearchResults,
  ListViewCustomer,
  ReadonlyEntityList,
} from 'appTypes';

import { adapter } from './schema';

const getCustomersState = (state: AppState): CustomersState => state.customers;

export const {
  selectById: getCustomerById,
  selectAll: getCustomers,
  selectEntities: getCustomerEntities,
  selectIds: getCustomerIds,
  selectTotal: getTotalCustomers,
} = adapter.getSelectors(getCustomersState);

/**
 * Gets a customer by id for the list view and properites panel.
 *
 * @param state - The top-level store state.
 * @param entityId - The customer's id
 * @returns the {@link ListViewCustomer} or undefined.
 */
export const getListViewCustomerById = (
  state: AppState,
  entityId: EntityId
): ListViewCustomer | undefined => {
  const customer = getCustomerById(state, entityId);
  if (!customer) {
    return;
  }

  const {
    name,
    description,
    createdAt,
    updatedAt,
    sfAccountNo,
    sfBillingAccountNo,
  } = customer;

  return {
    entityId,
    type: 'customer',
    name,
    description,
    createdOn: createdAt,
    modifiedOn: updatedAt,
    sfAccountNo,
    sfBillingAccountNo,
  };
};

/**
 *
 * @param state - The top-level store state
 * @returns the {@link SortState} without `isSorted`
 */
export const getCustomersSort = (
  state: AppState
): Omit<SortState, 'isSorted'> => {
  const { sort, sortOrder } = getCustomersState(state);
  return { sort, sortOrder };
};

interface CustomersPagination {
  sort: string;
  sortOrder: SortOrder;
  isSorted: boolean;
  loading: boolean;
  numberOfItems: number;
}

export const getCustomersPagination = (
  state: AppState
): CustomersPagination => {
  const { sort, sortOrder, isSorted, loading, numberOfItems } =
    getCustomersState(state);

  return {
    sort,
    sortOrder,
    isSorted,
    loading,
    numberOfItems,
  };
};

export const getCustomersPaginatedEntities = (
  state: AppState
): ReadonlyEntityList<ListViewCustomer> => {
  const { itemList } = getCustomersState(state);

  return itemList.reduce<ListViewCustomer[]>((list, customerId) => {
    const customer = getListViewCustomerById(state, customerId);
    if (customer) {
      list.push(customer);
    }

    return list;
  }, []);
};

export const isLoadingCustomers = (state: AppState): boolean =>
  getCustomersState(state).loading;

/**
 * @param state - The {@link AppState}
 * @param customerId - The customer id to check
 * @returns true if the provided `customerId` is currently loading
 */
export const isLoadingCustomer = (
  state: AppState,
  customerId: EntityId
): boolean => getCustomersState(state).loadingIds.includes(customerId);

/**
 * @returns `true` if the customers count API is pending
 */
export const isLoadingCustomersCount = (state: AppState): boolean =>
  getCustomersState(state).loadingCount;

/**
 * @returns the total number of customers
 */
export const getTotalCustomerCount = (state: AppState): number =>
  getCustomersState(state).total;

/**
 * @returns the total number of active customers
 */
export const getActiveCustomerCount = (state: AppState): number =>
  getCustomersState(state).active;

/**
 * @returns the total number of pending customers
 */
export const getPendingCustomerCount = (state: AppState): number =>
  getCustomersState(state).pending;

/**
 * @returns the total number of failed customers
 */
export const getFailedCustomerCount = (state: AppState): number =>
  getCustomersState(state).failed;

/**
 * @returns the total number of suspended customers
 */
export const getSuspendedCustomerCount = (state: AppState): number =>
  getCustomersState(state).suspended;

/**
 * @returns the customer's search state
 */
export const getCustomersSearch = (state: AppState): LegacySearchResults =>
  getCustomersState(state).search;
