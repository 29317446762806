import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { getCookie, removeCookie } from 'fwi-fe-utils';

import { FWI_LOGOUT_ERROR, FWI_LOGOUT_FROM } from 'constants/cookies';
import { postLogoutUrl } from 'utils/logout';

/**
 * This component will be hit as the `post_logout-redirect_uri` from okta. The flow
 * is:
 * - trigger logout function or navigate to `/logout` route (which tirggers logout function)
 * - redirect to Okta single log out url
 * - Okta redirects back to this url
 * - redirect to login page with query params
 */
export default function LogoutOkta(): null {
  const history = useHistory();
  useEffect(() => {
    const error = getCookie(FWI_LOGOUT_ERROR);
    const from = getCookie(FWI_LOGOUT_FROM);
    removeCookie(FWI_LOGOUT_FROM);
    removeCookie(FWI_LOGOUT_ERROR);

    history.replace(postLogoutUrl(from, error));
  }, [history]);

  return null;
}
