import { getCompanyId } from 'fwi-fe-utils';

import { SPLIT_KEY } from 'constants/env';

export const SPLIT_CONFIG: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: SPLIT_KEY,
    key: getCompanyId() || 'anonymous',
  },
  storage: {
    type: 'LOCALSTORAGE',
  },
};
