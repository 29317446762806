import { ReactElement, ReactNode } from 'react';

import styles from './UnifiedLoginWrapper.module.scss';
import { UnifiedMarketing } from './UnifiedMarketing';

export interface UnifiedLoginWrapperProps {
  children: ReactNode;
}

export function UnifiedLoginWrapper({
  children,
}: UnifiedLoginWrapperProps): ReactElement {
  return (
    <div className={styles.container}>
      {children}
      <UnifiedMarketing />
    </div>
  );
}
