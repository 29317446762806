import {
  DashboardGroup,
  DashboardMenuItem,
} from 'hooks/useDashboardOverviewQuery';

export const EmailAndFeedsGroupCode = {
  Activities: 'activities',
  Communications: 'comms',
  Configure: 'configure',
  Content: 'content',
  Receivers: 'receivers',
  Reporting: 'reporting',
} as const;
export type EmailAndFeedsGroupCode =
  (typeof EmailAndFeedsGroupCode)[keyof typeof EmailAndFeedsGroupCode];

export const EmailAndFeedsModuleCode = {
  ActivityOverview: 'activityoverview',
  Calendar: 'calendar',
  Campaigns: 'campaigns',
  Comments: 'comments',
  CommunicationsOverview: 'communicationsoverview',
  Email: 'email',
  Events: 'events',
  Feed: 'feed',
  Folders: 'folders',
  MediaLibrary: 'medialibrary',
  People: 'people',
  Reviewers: 'reviewers',
  Reports: 'reports',
  SendVolumesAndQuotas: 'sendvolumequotas',
  SubAccounts: 'subaccounts',
  Surveys: 'surveys',
  Users: 'users',
  UserLogs: 'userlogs',
  UserRoles: 'userroles',
} as const;
export type EmailAndFeedsModuleCode =
  (typeof EmailAndFeedsModuleCode)[keyof typeof EmailAndFeedsModuleCode];

export const DigitalSignageGroupCode = {
  AdminCenter: 'Admin Center',
  Communications: 'Communications',
  Configure: 'Configure',
  Content: 'Content',
  Receivers: 'Receivers',
  Reporting: 'Reporting',
} as const;
export type DigitalSignageGroupCode =
  (typeof DigitalSignageGroupCode)[keyof typeof DigitalSignageGroupCode];

export type DashboardGroupCode =
  | EmailAndFeedsGroupCode
  | DigitalSignageGroupCode;

export const DigitalSignageModuleCode = {
  Campaigns: 'Campaigns',
  Channels: 'Channels',
  ContentManagerWeb: 'Content Manager Web',
  Customers: 'Customers',
  Devices: 'Devices',
  Groups: 'Groups',
  Labels: 'Labels',
  Library: 'Library',
  Settings: 'Settings',
  Users: 'Users',
  Reporting: 'Reporting',
} as const;
export type DigitalSignageModuleCode =
  (typeof DigitalSignageModuleCode)[keyof typeof DigitalSignageModuleCode];

export type DashboardModuleCode =
  | EmailAndFeedsModuleCode
  | DigitalSignageModuleCode;

const EMAIL_AND_FEEDS_GROUPS = Object.values(EmailAndFeedsGroupCode);
const DIGITAL_SIGNAGE_GROUPS = Object.values(DigitalSignageGroupCode);
const EMAIL_AND_FEEDS_MODULES = Object.values(EmailAndFeedsModuleCode);
const DIGITAL_SIGNAGE_MODULES = Object.values(DigitalSignageModuleCode);

type VisibleDashboardGroup = DashboardGroup & { code: DashboardGroupCode };

function isDashboardGroup(item: DashboardGroup): item is VisibleDashboardGroup {
  return (
    EMAIL_AND_FEEDS_GROUPS.includes(item.code as EmailAndFeedsGroupCode) ||
    DIGITAL_SIGNAGE_GROUPS.includes(item.code as DigitalSignageGroupCode)
  );
}

type VisibleDashboardModule = DashboardMenuItem & { code: DashboardModuleCode };

function isDashboardModule(
  item: DashboardMenuItem
): item is VisibleDashboardModule {
  return (
    EMAIL_AND_FEEDS_MODULES.includes(item.code as EmailAndFeedsModuleCode) ||
    DIGITAL_SIGNAGE_MODULES.includes(item.code as DigitalSignageModuleCode)
  );
}

export type VisibleDashboardLinkGroup = Omit<
  VisibleDashboardGroup,
  'menuItems'
> & {
  menuItems: VisibleDashboardModule[];
};

/**
 * Takes in the groups from the `useDashboardOverviewQuery` and converts them
 * into "type-safe" groups and menu items by enforcing only valid codes exist
 * since additional items might be returned (like community).
 */
export function useDashboardGroupLinks(
  links: readonly DashboardGroup[] | undefined
): readonly VisibleDashboardLinkGroup[] {
  if (!links) {
    return [];
  }

  // remove unknown groups and modules to enforce we have a valid
  // headings and icons which allows the API to change without
  // immediately breaking the FE
  return links.reduce<VisibleDashboardLinkGroup[]>((list, group) => {
    if (isDashboardGroup(group)) {
      const menuItems = group.menuItems.filter(isDashboardModule);
      if (menuItems.length) {
        list.push({
          ...group,
          menuItems,
        });
      }
    }

    return list;
  }, []);
}
