import { useRouteMatch } from 'react-router-dom';

import { CustomerStatus, CustomersRouteParmas } from 'appTypes';
import { CUSTOMERS_VIEW_PATH } from 'utils/routes';

export function useCustomerStatus(): CustomerStatus;
export function useCustomerStatus(nullable: false): CustomerStatus;
export function useCustomerStatus(nullable: true): CustomerStatus | null;
export function useCustomerStatus(nullable = false): CustomerStatus | null {
  const match = useRouteMatch<CustomersRouteParmas>(CUSTOMERS_VIEW_PATH);
  const fallback = nullable ? null : CustomerStatus.ACTIVE;
  return match?.params.mode ?? fallback;
}
