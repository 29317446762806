import { createReducer } from '@reduxjs/toolkit';

import { UserEntity } from 'fwi-fe-types';

import { SharedPaginationState } from 'appTypes';
import {
  INITIAL_LOADING_STATE,
  INITIAL_OFFSET_STATE,
  MODAL,
} from 'constants/pagination';

import { fetchUsers } from '../users';

export const MODAL_USERS_INITIAL_STATE: SharedPaginationState = {
  offset: INITIAL_OFFSET_STATE,
  loading: INITIAL_LOADING_STATE,
  numberOfItems: 0,
  itemList: [],
};

export default createReducer(MODAL_USERS_INITIAL_STATE, (builder) => {
  builder.addCase(fetchUsers.pending, (state, action) => {
    const { paginationLocation, offset } = action.meta.arg;

    if (paginationLocation !== MODAL) {
      return;
    }
    state.loading = !INITIAL_LOADING_STATE;

    if (offset === INITIAL_OFFSET_STATE) {
      state.offset = INITIAL_OFFSET_STATE;
      state.itemList = [];
    }
  });

  builder.addCase(fetchUsers.fulfilled, (state, action) => {
    if (action.meta.arg.paginationLocation !== MODAL) {
      return;
    }

    const { payload } = action;
    const { offset, numberOfItems, items } = payload;

    state.offset = offset ?? INITIAL_OFFSET_STATE;
    state.loading = INITIAL_LOADING_STATE;

    state.numberOfItems = numberOfItems;

    // itemList changes for a general fetch
    if (state.offset === INITIAL_OFFSET_STATE) {
      state.itemList = items.map((user: UserEntity) => user.id);
    } else {
      const itemsToAdd = items.reduce((list: string[], user: UserEntity) => {
        const { id } = user;
        if (!state.itemList.includes(id)) {
          list.push(id);
        }
        return list;
      }, []);

      state.itemList = [...state.itemList, ...itemsToAdd];
    }
  });

  builder.addCase(fetchUsers.rejected, (state, action) => {
    if (action.meta.arg.paginationLocation !== MODAL) {
      return;
    }
    state.loading = INITIAL_LOADING_STATE;
  });
});
