import {
  AppState,
  LicenseQuota,
  LicenseQuotasState,
  LicenseTypeWithQuota,
} from 'appTypes';

/**
 *
 * @param state The top-level store state.
 * @returns the full LicenseQuotasState
 */
export const getLicenseQuotasState = (state: AppState): LicenseQuotasState =>
  state.licenseQuotas;

/**
 *
 * @param state The top-level store state.
 * @param licenseType The license to get a quota for
 * @returns the specific quota for a license type
 */
export const getLicenseQuotas = (
  state: AppState,
  licenseType: LicenseTypeWithQuota
): Readonly<LicenseQuota> => getLicenseQuotasState(state)[licenseType];

/**
 * @param state The top-level store state.
 * @returns true if the license quotas are loading
 */
export const isLoadingLicenseQuotas = (state: AppState): boolean =>
  getLicenseQuotasState(state).loading;
