export const STORAGE_QUOTA_MIN = 0;
export const STORAGE_QUOTA_MAX = 20000;
export const STORAGE_QUOTA_DEFAULT = 500;
export const STORAGE_QUOTA_STEP = 5;

export const DEVICES_QUOTA_MIN = 0;
export const DEVICES_QUOTA_MAX = 20000;
export const DEVICES_QUOTA_DEFAULT = 0;

export const IFRAME_QUOTA_MIN = 0;
export const IFRAME_QUOTA_MAX = 500;
export const IFRAME_QUOTA_DEFAULT = 0;
