import { ReactElement } from 'react';

import { Button, MenuItem } from 'fwi-fe-components';

import {
  createAlertModalDisplayed,
  showAlertModal,
  useAppDispatch,
} from 'appState';
import FormattedMessage from 'components/FormattedMessage';

export interface AddAlertForMeProps {
  idPrefix?: string;
  as?: 'button' | 'menuitem';
}

export default function AddAlertForMe({
  idPrefix = '',
  as = 'menuitem',
}: AddAlertForMeProps): ReactElement {
  const dispatch = useAppDispatch();
  const onClick = (): void => {
    dispatch(createAlertModalDisplayed());
    dispatch(showAlertModal({ type: 'user' }));
  };

  if (as === 'button') {
    return (
      <Button id={`${idPrefix}add-new-alert-for-me`} onClick={onClick}>
        <FormattedMessage messageId="Alerts.Add" />
      </Button>
    );
  }

  return (
    <MenuItem id={`${idPrefix}add-new-alert-for-me`} onClick={onClick}>
      <FormattedMessage messageId="Alerts.SendToMe" />
    </MenuItem>
  );
}
