import { PayloadAction, createAction, createReducer } from '@reduxjs/toolkit';

import { SortOrder } from 'fwi-fe-types';

import { CustomerEntity, CustomersState, LegacySearchState } from 'appTypes';

import {
  activateCustomer,
  createCustomer,
  deactivateCustomer,
  deleteCustomer,
  fetchCustomer,
  fetchCustomerCount,
  fetchCustomerUsage,
  fetchCustomers,
  searchCustomers,
  suspendCustomer,
  updateCustomer,
} from './api';
import { adapter } from './schema';

export const clearCustomersSearchResults = createAction(
  'customers/clearSearchResults'
);

const INITIAL_SEARCH_STATE: LegacySearchState = {
  loading: false,
  error: false,
  hits: [],
  total: 0,
  buckets: { Customer: { total: 0, hits: [] } },
};

export const INITIAL_CUSTOMERS_STATE: CustomersState = adapter.getInitialState({
  total: 0,
  active: 0,
  failed: 0,
  pending: 0,
  suspended: 0,
  loadingCount: false,
  loading: false,
  offset: 0,
  sort: 'name',
  sortOrder: SortOrder.ASCENDING,
  isSorted: true,
  numberOfItems: 0,
  itemList: [],
  loadingIds: [],
  search: INITIAL_SEARCH_STATE,
});

export default createReducer(INITIAL_CUSTOMERS_STATE, (builder) =>
  builder
    .addCase(fetchCustomerCount.pending, (state) => {
      state.loadingCount = true;
    })
    .addCase(fetchCustomerCount.fulfilled, (state, action) => {
      const { total, active, pending, failed, suspended } = action.payload;
      state.loadingCount = false;
      state.total = total;
      state.active = active;
      state.pending = pending;
      state.failed = failed;
      state.suspended = suspended;
    })
    .addCase(fetchCustomerCount.rejected, (state) => {
      state.loadingCount = false;
    })
    .addCase(fetchCustomers.pending, (state, action) => {
      const { offset = 0, sortBy, sortOrder } = action.meta.arg;
      state.loading = true;
      if (offset === 0) {
        state.offset = 0;
        state.itemList = [];
        state.sort = sortBy;
        state.sortOrder = sortOrder;
        state.isSorted = true;
      }
    })
    .addCase(fetchCustomers.fulfilled, (state, { payload }) => {
      state.loading = false;
      adapter.upsertMany(state, payload.items);

      state.offset += payload.size;
      state.numberOfItems = payload.total;
      state.itemList.push(...payload.items.map(({ customerId }) => customerId));
    })
    .addCase(fetchCustomers.rejected, (state) => {
      state.loading = false;
    })
    .addCase(fetchCustomer.pending, (state, action) => {
      state.loadingIds.push(action.meta.arg);
    })
    .addCase(fetchCustomer.fulfilled, (state, action) => {
      const customerId = action.meta.arg;
      state.loadingIds = state.loadingIds.filter((id) => id !== customerId);
      adapter.upsertOne(state, action.payload);
    })
    .addCase(fetchCustomer.rejected, (state, action) => {
      state.loadingIds = state.loadingIds.filter(
        (id) => id !== action.meta.arg
      );
    })
    .addCase(fetchCustomerUsage.fulfilled, (state, action) => {
      adapter.updateOne(state, {
        id: action.meta.arg,
        changes: { customerUsage: action.payload },
      });
    })
    .addCase(createCustomer.fulfilled, (state, action) => {
      adapter.upsertOne(state, action.payload);
    })
    .addCase(deleteCustomer.fulfilled, (state, action) => {
      adapter.removeOne(state, action.meta.arg);
    })
    .addCase(searchCustomers.pending, (state) => {
      state.search.loading = true;
    })
    .addCase(searchCustomers.rejected, (state) => {
      state.search.loading = false;
    })
    .addCase(searchCustomers.fulfilled, (state, action) => {
      state.search = {
        loading: false,
        ...action.payload,
      };
    })
    .addCase(clearCustomersSearchResults, (state) => {
      state.search = INITIAL_SEARCH_STATE;
    })
    .addMatcher(
      (action) =>
        suspendCustomer.pending.match(action) ||
        activateCustomer.pending.match(action) ||
        deactivateCustomer.pending.match(action) ||
        deleteCustomer.pending.match(action),
      (state, action) => {
        const customerId = action.meta.arg;
        state.offset -= 1;
        state.numberOfItems -= 1;
        state.itemList = state.itemList.filter((id) => id !== customerId);
      }
    )
    .addMatcher(
      (action): action is PayloadAction<CustomerEntity> =>
        updateCustomer.fulfilled.match(action) ||
        suspendCustomer.fulfilled.match(action) ||
        activateCustomer.fulfilled.match(action) ||
        deactivateCustomer.fulfilled.match(action),
      (state, action) => {
        adapter.upsertOne(state, action.payload);
      }
    )
);
